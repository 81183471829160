import React, { useEffect, useState } from "react";
import AppLayout from "../Components/AppLayout";
import { contactUsCall } from "../Common/Repository";
import GoogleMapsEmbed from "../Components/GoogleMapsEmbed";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { clearError } from "../states/contactUs/contactUsSlice";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { token } = useSelector((state) => state.auth);

  const { success, loading, error } = useSelector((state) => state.contactUs);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateFields = () => {
    let errorFound = false;
    const updatedFields = contactFields.map((field) => {
      if (field.key === "name" && field.value.length <= 0) {
        errorFound = true;
        return {
          ...field,
          error: "Please enter a name!",
        };
      } else if (field.key === "email" && field.value.length <= 0) {
        errorFound = true;
        return {
          ...field,
          error: "Please enter your email",
        };
      } else if (
        field.key === "email" &&
        field.value.length > 0 &&
        !validateEmail(field.value)
      ) {
        errorFound = true;
        return {
          ...field,
          error: "Please enter a valid email",
        };
      } else if (field.key === "message" && field.value.length <= 0) {
        errorFound = true;
        return {
          ...field,
          error: "Please enter a message",
        };
      } else {
        return {
          ...field,
          error: "",
        };
      }
    });

    setContactFields(updatedFields);

    return !errorFound;
  };

  const handleSubmitPress = async () => {
    try {
      if (validateFields()) {
        let data = {};
        contactFields.forEach((field) => {
          data[field.key] = field.value;
        });
        const clearedFields = contactFields.map((field) => ({
          ...field,
          error: "",
        }));
        setContactFields(clearedFields);
        dispatch(contactUsCall({ token, data }));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (success) {
      setContactFields([
        {
          type: "text",
          key: "name",
          label: "Name",
          value: "",
          error: "",
          placeholder: "Name",
        },
        {
          type: "email",
          key: "email",
          label: "Email",
          value: "",
          error: "",
          placeholder: "Enter email address",
        },
        {
          type: "text",
          key: "message",
          label: " Description",
          value: "",
          error: "",
          placeholder: "Type your message here...",
        },
      ]);
    }
  }, [success]);

  const [contactFields, setContactFields] = useState([
    {
      type: "text",
      key: "name",
      label: "Name",
      value: "",
      error: "",
      placeholder: "Name",
    },
    {
      type: "email",
      key: "email",
      label: "Email",
      value: "",
      error: "",
      placeholder: "Email",
    },
    {
      type: "text",
      label: "Description",
      key: "message",
      value: "",
      error: "",
      placeholder: "Message",
    },
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const handleInputChange = (event, fieldKey) => {
    const updatedFields = contactFields.map((field) => {
      if (field.key === fieldKey) {
        dispatch(clearError());
        return {
          ...field,
          value: event.target.value,
          error: "",
        };
      }
      return field;
    });
    setContactFields(updatedFields);
  };

  useEffect(() => {
    if (error && error !== undefined) updateErrorInContactFields(error);
  }, [error]);

  const updateErrorInContactFields = (errors) => {
    const newFields = contactFields.map((field) => {
      return {
        ...field,
        error: errors[field.key]?.[0] || "",
      };
    });
    setContactFields(newFields);
  };

  return (
    <AppLayout>
      <>
        <div className="container-box-style">
          <div className="m-auto">
            <h2 className="text-center my-5">Contact Us</h2>
          </div>
          <div className="row box-bg">
            <div className="col-md-7">
              <div className="row position-relative">
                {contactFields.map((field) => (
                  <>
                    <div
                      className={
                        field.key === "message"
                          ? "col-md-12 message-input-style mt-3"
                          : "col-md-6 input-style"
                      }
                      key={field.key}
                    >
                      {field.key === "message" ? (
                        <>
                          <div className="text-muted m-3"> {field.label} </div>
                          <textarea
                            type={field.type}
                            name={field.key}
                            value={field.value}
                            className="form-control my-1"
                            placeholder={field.placeholder}
                            onChange={(event) =>
                              handleInputChange(event, field.key)
                            }
                          />
                        </>
                      ) : (
                        <>
                          <div className="text-muted m-3"> {field.label} </div>

                          <input
                            type={field.type}
                            name={field.key}
                            value={field.value}
                            className="form-control my-1"
                            placeholder={field.placeholder}
                            onChange={(event) =>
                              handleInputChange(event, field.key)
                            }
                          />
                        </>
                      )}
                      <div className="p-3 text-danger"> {field.error} </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="row">
                <div className="action-btns d-flex my-3">
                  <div
                    onClick={() => {
                      navigate(-1);
                      setContactFields([
                        {
                          type: "text",
                          key: "name",
                          label: "Name",
                          value: "",
                          error: "",
                          placeholder: "Name",
                        },
                        {
                          type: "email",
                          key: "email",
                          label: "Email",
                          value: "",
                          error: "",
                          placeholder: "Email",
                        },
                        {
                          type: "text",
                          label: "Description",
                          key: "message",
                          value: "",
                          error: "",
                          placeholder: "Message",
                        },
                      ]);
                    }}
                    className="common-button-style-outline w-25 me-5 align-center wm-100"
                  >
                    Cancel
                  </div>
                  <div
                    className="common-button-style-filled w-25 align-center wm-100"
                    onClick={() => handleSubmitPress()}
                    disabled={loading}
                  >
                    {loading ? <PuffLoader color="white" size={28} /> : "Send"}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="pb-5">
                <GoogleMapsEmbed className="wm-100" />
              </div>
            </div>
          </div>
        </div>
      </>
    </AppLayout>
  );
};

export default ContactUs;
