import axios from "axios";

// export const API_URL = "https://90da-39-62-4-22.ngrok-free.app/api/vitrine";
// export const API_URL = "https://1d21-39-62-4-22.ngrok-free.app";


export const API_URL = "https://high.vitrine.live/api/vitrine";
// export const API_URL = "http://192.168.18.19:8000/api/vitrine";
// export const API_URL = "https://dev.vitrine.live/api/vitrine";

export const axiosApi = axios.create({
  baseURL: API_URL,
});

export const axiosAuthApi = (headers) =>
  axios.create({
    baseURL: API_URL,
    headers,
  });
