import { createSlice } from "@reduxjs/toolkit";
import {
  ForgotPasswordApi,
  LoginApi,
  RegisterApi,
  ResendOtpApi,
  ResetPasswordApi,
  VerifyOtpApi,
  getNotificationsApi,
} from "../../Common/Repository";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  user: null,
  token: "",
  error: null,
  loginError: null,
  success: false,
  signUpSuccess: false,
  otpLoading: false,
  otpSuccess: false,
  notificationLoading: false,
  forgotPassLoading: false,
  forgotPassSuccess: false,
  forgotPassError: null,
  updatePassLoading: false,
  updatePassSuccess: false,
  updatePassError: null,
  // Resend OTP
  resendOtpLoading: false,
  resendOtpSuccess: false,
  resendOtpError: null,
  notifications: [],
  otpTime: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUpdateUser: (state, action) => {
      let tempUser = { ...state.user };
      const payload = action?.payload;
      if (payload) {
        tempUser.instagram_biography = payload.instagram_biography ?? tempUser.instagram_biography;
        tempUser.instagram_followers_count = payload.instagram_followers_count ?? tempUser.instagram_followers_count;
        tempUser.instagram_follows_count = payload.instagram_follows_count ?? tempUser.instagram_follows_count;
        tempUser.instagram_id = payload.instagram_id ?? tempUser.instagram_id;
        tempUser.instagram_media_count = payload.instagram_media_count ?? tempUser.instagram_media_count;
        tempUser.instagram_profile_name = payload.instagram_profile_name ?? tempUser.instagram_profile_name;
        tempUser.instagram_profile_picture_url = payload.instagram_profile_picture_url ?? tempUser.instagram_profile_picture_url;
        tempUser.instagram_username = payload.instagram_username ?? tempUser.instagram_username;
        tempUser.instagram_verified_at = payload.instagram_verified_at ?? tempUser.instagram_verified_at;
        tempUser.instagram_website = payload.instagram_website ?? tempUser.instagram_website;
        tempUser.page_connected_to_instagram_category = payload.page_connected_to_instagram_category ?? tempUser.page_connected_to_instagram_category;
        tempUser.page_connected_to_instagram_id = payload.page_connected_to_instagram_id ?? tempUser.page_connected_to_instagram_id;
      }
      state.user = tempUser;
    },

    resetLoadSuccessStates: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.loginError = null;
      state.signUpSuccess = false;
      state.notificationLoading = false;
      state.otpLoading = false;
      state.otpSuccess = false;
      state.forgotPassLoading = false;
      state.forgotPassSuccess = false;
      state.forgotPassError = null;
      state.updatePassLoading = false;
      state.updatePassSuccess = false;
      state.updatePassError = null;
      state.resendOtpLoading = false;
      state.resendOtpSuccess = false;
      state.resendOtpError = null;
      state.otpTime = null;
    },
    setNotificationsdata: (state, action) => {
      state.notifications = action.payload;
    },
    logout: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {
    // Login User Registeration
    builder
      .addCase(LoginApi.pending, (state, action) => {
        state.signUpSuccess = false;
        state.loading = true;
        state.loginError = null;
        state.success = false;
      })
      .addCase(LoginApi.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data.user;
        state.token = action.payload.data.token;
        state.success = true;
        // localStorage.setItem("token", action.payload.data.token);
      })
      .addCase(LoginApi.rejected, (state, action) => {
        state.loading = false;
        state.loginError = action.payload;
        state.success = false;
      })
      .addCase(RegisterApi.pending, (state, action) => {
        state.loading = true;
        state.signUpSuccess = false;
        state.error = null;
      })
      .addCase(RegisterApi.fulfilled, (state, action) => {
        state.loading = false;
        state.signUpSuccess = true;
        state.user = action.payload.data.user;
        state.otpTime = action.payload?.otp_code_time;

        state.token = action.payload.data.token;
      })
      .addCase(RegisterApi.rejected, (state, action) => {
        state.loading = false;
        state.signUpSuccess = false;
        state.error = action;
      })
      .addCase(VerifyOtpApi.pending, (state, action) => {
        state.signUpSuccess = false;
        state.otpLoading = true;
        state.otpSuccess = false;
        state.error = null;
      })
      .addCase(VerifyOtpApi.fulfilled, (state, action) => {

        state.otpLoading = false;

        if (action.payload?.data?.success) {
          state.otpSuccess = true;
          state.user = null;
          state.userToken = null;
          localStorage.removeItem("user"); // Remove from local storage
          localStorage.removeItem("userToken"); // deletes token from storage
        } else {
          state.otpSuccess = false;
          state.error = action.payload?.data;
          toast.error("Failed to verify otp!");
        }
      })
      .addCase(VerifyOtpApi.rejected, (state, action) => {
        state.otpLoading = false;
        state.otpSuccess = false;
        state.error = action;
        toast.error("Failed to verify otp!");
      })
      .addCase(ResendOtpApi.pending, (state, action) => {
        state.resendOtpLoading = true;
        state.resendOtpSuccess = false;
        state.updatePassSuccess = false;
        state.forgotPassSuccess = false;
        state.otpSuccess = false;
        state.signUpSuccess = false;
        state.resendOtpError = null;
      })
      .addCase(ResendOtpApi.fulfilled, (state, action) => {
        state.resendOtpLoading = false;
        state.resendOtpSuccess = true;
        state.user.otp_code_time = action.payload?.data?.otp_code_time
        toast.success("OTP sent successfuly!");
      })
      .addCase(ResendOtpApi.rejected, (state, action) => {
        state.resendOtpLoading = false;
        state.resendOtpError = action;
        toast.error("Failed to resend otp!");
      })
      .addCase(ForgotPasswordApi.pending, (state, action) => {
        state.forgotPassSuccess = false;
        state.forgotPassLoading = true;
        state.error = null;
      })
      .addCase(ForgotPasswordApi.fulfilled, (state, action) => {
        state.forgotPassLoading = false;
        state.forgotPassSuccess = true;
        toast.success("An email with a link has been sent to your account!");
      })
      .addCase(ForgotPasswordApi.rejected, (state, action) => {
        state.forgotPassLoading = false;
        state.error = action;
        // toast.error(
        //   "Failed to send forgot password link at your provided email."
        // );
      })
      .addCase(ResetPasswordApi.pending, (state, action) => {
        state.updatePassSuccess = false;
        state.updatePassLoading = true;
        state.updatePassError = null;
      })
      .addCase(ResetPasswordApi.fulfilled, (state, action) => {
        state.updatePassLoading = false;
        state.updatePassSuccess = true;
        toast.success("Password Updated Successfully!");
      })
      .addCase(ResetPasswordApi.rejected, (state, action) => {
        state.updatePassLoading = false;
        state.updatePassError = action;
        toast.error("Failed to update your password!");
      })

      .addCase(getNotificationsApi.pending, (state, action) => {
        if (!state.notifications.length) {
          state.notificationLoading = true;
        }
      })
      .addCase(getNotificationsApi.fulfilled, (state, action) => {
        state.notificationLoading = false;
        state.notifications = action?.payload?.data?.data;
      })
      .addCase(getNotificationsApi.rejected, (state, action) => {
        state.notificationLoading = false;
      });
  },
});

export const {
  setToken,
  setUser,
  setUpdateUser,
  resetLoadSuccessStates,
  logout,
  setNotificationsdata,
} = authSlice.actions;
export const loading = (state) => state.loading;
export const notifications = (state) => state.notifications;
export const notificationLoading = (state) => state.notificationLoading;
export const signUpSuccess = (state) => state.signUpSuccess;
export const user = (state) => state.user;
export const token = (state) => state.token;
export const error = (state) => state.error;
export const loginError = (state) => state.loginError;
export const forgotPassLoading = (state) => state.forgotPassLoading;
export const forgotPassSuccess = (state) => state.forgotPassSuccess;
export const forgotPassError = (state) => state.forgotPassError;
export const updatePassLoading = (state) => state.updatePassLoading;
export const updatePassSuccess = (state) => state.updatePassSuccess;
export const updatePassError = (state) => state.updatePassError;
export const resendOtpLoading = (state) => state.resendOtpLoading;
export const resendOtpSuccess = (state) => state.resendOtpSuccess;
export const resendOtpError = (state) => state.resendOtpError;

export const success = (state) => state.success;
export const otpLoading = (state) => state.otpLoading;
export const otpSuccess = (state) => state.otpSuccess;
export default authSlice.reducer;
