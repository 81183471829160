import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { addPack, UpdatePack, getMyPacks } from "../../Common/Repository";
import { toast } from "react-toastify";
const _ = require("lodash");

const initialState = {
  loading: false,
  addPackSuccess: false,
  addPackLoading: false,
  packCatalogueData: [],
  error: null,
  success: false,
  editPackImages: [],
  editPackDeletedImages: [],
  editLoading: false,
  editPackSuccess: false,
  editError: null,
};

const packsSlice = createSlice({
  name: "packs",
  initialState,
  reducers: {
    resetPackState: (state, action) => {
      state.loading = false;
      state.addPackSuccess = false;
      state.addPackLoading = false;
      state.packCatalogueData = [];
      state.error = null;
      state.success = false;
      state.editPackImages = [];
      state.editPackDeletedImages = [];
      state.editLoading = false;
      state.editPackSuccess = false;
      state.editError = null;
    },
    setPacks: (state, action) => {
      state.packCatalogueData = action.payload;
    },
    setEditPackImages: (state, action) => {
      state.editPackImages = action.payload;
    },
    removeAnImage: (state, action) => {
      let deletedImages = _.remove(state.editPackImages, function (imageIn) {
        return imageIn.id === action.payload.id;
      });
      // state.editPackImages =
      state.editPackDeletedImages.push(action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        state.packCatalogueData = [];
      })
      .addCase(getMyPacks.pending, (state) => {
        if (!state.packCatalogueData.length) {
          state.loading = true;
        }
        state.error = null;
      })
      .addCase(getMyPacks.fulfilled, (state, action) => {
        // state.packCatalogueData = action.payload?.data?.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getMyPacks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addPack.pending, (state) => {
        state.addPackLoading = true;
        state.addPackSuccess = false;
        state.error = null;
      })
      .addCase(addPack.fulfilled, (state, action) => {
        toast.success("Package Added Successfully!");
        state.addPackLoading = false;
        state.addPackSuccess = true;
        state.error = null;
      })
      .addCase(addPack.rejected, (state, action) => {
        state.addPackSuccess = false;
        state.addPackLoading = false;
        state.error = action.payload;
      })
      .addCase(UpdatePack.pending, (state) => {
        state.editLoading = true;
        state.editPackSuccess = false;
        state.editError = null;
      })
      .addCase(UpdatePack.fulfilled, (state, action) => {
        state.editLoading = false;
        state.editPackSuccess = true;
        state.editError = null;
      })
      .addCase(UpdatePack.rejected, (state, action) => {
        state.editLoading = false;
        state.editPackSuccess = false;
        state.editError = action.payload;
      });
  },
});

export const { setPacks, resetPackState, removeAnImage, setEditPackImages } =
  packsSlice.actions;

export const editLoading = (state) => state.editLoading;
export const editPackImages = (state) => state.editPackImages;
export const editPackDeletedImages = (state) => state.editPackDeletedImages;
export const editPackSuccess = (state) => state.editPackSuccess;
export const editErrorPack = (state) => state.editError;

export default packsSlice.reducer;
