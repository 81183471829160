// import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { onBackgroundMessage } from "firebase/messaging/sw";

const firebaseConfig = {
  apiKey: "AIzaSyD7bv-x9cb4Dw90PPk8jGchL0-miIVyxSI",
  authDomain: "graphical-fort-386720.firebaseapp.com",
  projectId: "graphical-fort-386720",
  storageBucket: "graphical-fort-386720.appspot.com",
  messagingSenderId: "501754523103",
  appId: "1:501754523103:web:bb7e40aa6100383941686a",
  measurementId: "G-17PJB7W3BR"
};

const app = initializeApp(firebaseConfig);
// const auth = firebase.auth();
const messaging = getMessaging(app);

const isSupported = () =>
'Notification' in window &&
'serviceWorker' in navigator &&
'PushManager' in window

const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BPvjIsd9ZRSPG_-sg2eY-jCO6rrUlskz53Cp-ow48Y_lLSKMwPCU5sCvkpFsMe_-3iehIA-w920Yn4lyOyyVcdA",
    });
    if (currentToken) {

      return currentToken;
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (err) {

    return null;
  }
};

export const requestPermission = () => {
  // 
  if(isSupported()){
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // 
        return getToken(messaging, {
          vapidKey: `BPvjIsd9ZRSPG_-sg2eY-jCO6rrUlskz53Cp-ow48Y_lLSKMwPCU5sCvkpFsMe_-3iehIA-w920Yn4lyOyyVcdA`,
        })
          .then((currentToken) => {
            if (currentToken) {
  
            } else {
  
            }
          })
          .catch((err) => {
            console.log(
              "An error occurred when requesting to receive the token.",
              err
            );
          });
      } else {
        console.log("Notification Permission Not Given");
      }
    });
  } else {
    console.log("Notification Not Allowed on this device");
  }
};

// onBackgroundMessage(messaging, (payload) => {
//   

//   const notificationTitle = payload.notification.title;
//   const notificationOptions = {
//     body: payload.notification.body,
//   };

//   // self.registration.showNotification(notificationTitle, notificationOptions);
// });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export { app, messaging, requestForToken };
