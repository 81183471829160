import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { contactUsCall } from "../../Common/Repository";
import { toast } from "react-toastify";

const initialState = {
    loading: false,
    error: null,
    success: false,
};

const contactUsSlice = createSlice({
    name: 'contactUs',
    initialState,

    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(contactUsCall.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(contactUsCall.fulfilled, (state, action) => {
                toast.success(action.payload.data.message);
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(contactUsCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.errors
            })
            .addCase(logout, (state) => {
                return initialState;
            })
    },
});

export const { clearError } = contactUsSlice.actions;

export default contactUsSlice.reducer;
