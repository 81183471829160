import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { getSingleCollaborationDataCall } from "../../Common/Repository";

const initialState = {
    loading: false,
    singleCollaborationData: {},
    collaborationOffers: [],
    error: null,
};

const singleCollaborationSlice = createSlice({
    name: 'singleCollaborationData',
    initialState,
    reducers: {
        setSingleCollaborationData: (state, action) => {
            state.singleCollaborationData = action.payload;
        },
        setCollaborationOffers: (state, action) => {

            state.collaborationOffers = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSingleCollaborationDataCall.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(getSingleCollaborationDataCall.fulfilled, (state, action) => {
                state.singleCampaignData = action.payload?.data?.data;

                state.loading = false;
                state.error = null;
            })
            .addCase(getSingleCollaborationDataCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(logout, (state) => {
                state.singleCollaborationData = {};
                state.collaborationOffers = []
            });
    },
});

export const {
    setCollaborationOffers,
    setSingleCollaborationData,
    setLoading,
    setErrors,
} = singleCollaborationSlice.actions;
export default singleCollaborationSlice.reducer;
