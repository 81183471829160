import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../Components/AppLayout";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetChatListApi,
  GetMessagesListApi,
  GetMessagesListPaginatedApi,
  getSingleCollaborationDataCall,
} from "../Common/Repository";
import CircleImageNFound from "../Components/404_CircleImage";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader, SyncLoader } from "react-spinners";
import { setLoading } from "../states/singleCollaboration/singleCollaborationSlice";
import { resetStates, setCurrentChatId } from "../states/chat/chatSlice";
import {
  AdjustmentRequested,
  AppealInitiated,
  ExtensionMessageReceived,
  ExtensionMessageSent,
  OtherUserMessage,
  ProofRejected,
  ReceivedCustomOffer,
  ReviewGiven,
  SentCustomOffer,
  SentMessage,
  ShowOffer,
  WorkSubmitted,
} from "../Components/ChatModals";

const CollaborationDetails = () => {
  const collaborationId = useLocation()?.state?.collaborationId;

  const navigate = useNavigate();
  const divRef = useRef(null);

  const dispatch = useDispatch();
  const [isAtTop, setIsAtTop] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [collaborationStatus, setCollaborationStatus] = useState("");

  const { user, token } = useSelector((state) => state?.auth);

  const { singleCollaborationData, collaborationOffers, loading } = useSelector(
    (state) => state?.singleCollaboration
  );



  // const {
  //   approvedCollab,
  // } = useSelector((state) => state.brandDetails);

  const {
    chatList,
    messageList,
    loadingMessages,
    currentPage,
    totalMessagePages,
  } = useSelector((state) => state?.chat);

  useEffect(() => {
    let selectedChat = chatList?.find((chat) => {
      if (chat?.user?.brand) {
        return chat?.user?.brand?.id === singleCollaborationData?.brand?.id;
      }
      if (chat?.user?.creator) {
        return chat?.user?.creator?.id === singleCollaborationData?.creator?.id;
      }
      return false;
    });
    if (selectedChat) {
      getMessageListFromDb(selectedChat?.chat_id, selectedChat?.user?.id);
    }
  }, [chatList, singleCollaborationData]);

  useEffect(() => {
    if (collaborationId) {
      getSingleCollaborationData();
    }
  }, [collaborationId]);

  useEffect(() => {
    if (singleCollaborationData && singleCollaborationData.status) {
      setCollaborationStatus(singleCollaborationData.status);
    }
  }, [singleCollaborationData]);

  const getSingleCollaborationData = async () => {
    if (!collaborationId) {
      return;
    }
    try {
      if (collaborationId !== singleCollaborationData?.id) {
        dispatch(setLoading(true));
      }
      let data = {};
      data.collaboration_id = collaborationId;
      dispatch(getSingleCollaborationDataCall({ token, data }));
    } catch (error) { }
  };

  const calculateDeliveryDays = (deliveryTime, createdAt) => {
    const deliveryDate = new Date(deliveryTime);
    const createdDate = new Date(createdAt);

    // Calculate the time difference in milliseconds
    const timeDifference = deliveryDate - createdDate;

    // Calculate the number of days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  useEffect(() => {
    getChatListFromDb();
  }, [collaborationId]);

  const getChatListFromDb = async () => {
    try {
      var header = {
        Authorization: `Bearer ${token}`,
      };
      dispatch(resetStates());
      dispatch(GetChatListApi({ header }));
    } catch (error) { }
  };

  const getMessageListFromDb = async (chat_id) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
      };
      let formData = {};
      formData.chat_id = chat_id;
      dispatch(setCurrentChatId(chat_id));
      setCurrentChatId(chat_id);
      dispatch(resetStates());
      setChatId(chat_id);
      // setReceiverId(receiverId);
      dispatch(GetMessagesListApi({ header, formData, dispatch, chat_id }));
    } catch (error) { }
  };

  useEffect(() => {
    if (isAtTop && currentPage + 1 <= totalMessagePages) paginate();
  }, [isAtTop]);

  const paginate = () => {
    let header = {
      Authorization: `Bearer ${token}`,
    };
    let formData = {};
    formData.chat_id = chatId;
    dispatch(
      GetMessagesListPaginatedApi({
        header,
        formData,
        dispatch,
        chatId,
        currentPage,
      })
    ); //
  };
  const handleScroll = () => {
    const scrollTop = divRef.current.scrollTop;
    if (scrollTop === 0) {
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
    }
  };

  useEffect(() => {
    if (messageList?.length) {
      let element = document.getElementById("creator-chat-box-scroll");
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }
  }, [messageList]);

  return (
    <AppLayout>
      <>
        {loading ? (
          <>
            <div className="p-5 m-5 d-flex justify-content-center">
              <PuffLoader color="#65acae" size={42} />
            </div>
          </>
        ) : (
          <>
            <div className="categories-dashboard p-5 ">
              <div className="container container-animation">
                <img
                  className="purple-circle"
                  src="/assets/images/circle_top1.png"
                  alt="circle_top1"
                />
                <img
                  className="yellow-circle"
                  src="/assets/images/circle-03.png"
                  alt="circle_top1"
                />

                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn common-button-style-filled mb-5 ms-md-5"
                >
                  &#x2190; Back
                </div>
                <div className="campaign-details-status-pos">
                  <h4 className="px-2">
                    Collaboration #{collaborationId} - Details
                  </h4>
                  <div
                    className={`status-tag ${collaborationStatus === "approved"
                      ? "tag-approved"
                      : collaborationStatus === "completed"
                        ? "tag-completed"
                        : collaborationStatus === "ongoing"
                          ? "tag-ongoing"
                          : collaborationStatus === "rejected"
                            ? "tag-rejected"
                            : collaborationStatus === "pending"
                              ? "tag-pending"
                              : collaborationStatus === "appealed"
                                ? "tag-appealed"
                                : ""
                      }`}
                  >
                    {collaborationStatus.charAt(0).toUpperCase() +
                      collaborationStatus.slice(1)}
                  </div>
                </div>

                <div className="campaign-details-section mt-4 mt-md-3">
                  <div
                    className="mb-5 px-md-3 text-start"
                    style={{ width: "100%", wordBreak: "break-all" }}
                  >
                    {collaborationOffers[0]?.description ??
                      "No description available"}
                  </div>
                  <div className="px-md-3 view-offer-modal-bottom">
                    {collaborationOffers[0]?.price ? (
                      <div>
                        {`\$${collaborationOffers[0]?.price} per
                        ${collaborationOffers[0]?.object}`}
                      </div>
                    ) : (
                      <div>N/A</div>
                    )}
                    <div>
                      {`Delivery Time: ${calculateDeliveryDays(
                        collaborationOffers[0]?.delivery_time,
                        collaborationOffers[0]?.created_at
                      )} days`}
                    </div>
                  </div>
                </div>

                {user?.role === "2" ? (
                  <>
                    {/* FOR BRAND SIDE  */}
                    {singleCollaborationData?.creator && (
                      <div className="campaign-details-section mt-5 p-5">
                        <div className="row">
                          {singleCollaborationData.creator.profile_image !==
                            undefined &&
                            singleCollaborationData.creator.profile_image !==
                            null ? (
                            <img
                              className="profile-image-md"
                              src={
                                singleCollaborationData.creator.profile_image
                              }
                              alt="avtar-a"
                            />
                          ) : (
                            <CircleImageNFound width={90} height={90} />
                          )}

                          <div className="col-md-5 profile-name-row">
                            <span className="profile-name">
                              {singleCollaborationData.creator.profile_name ??
                                "-"}
                            </span>
                            <span className="profile-stars">
                              <img
                                src="/assets/images/reviewStar.svg"
                                width={18}
                                alt="star"
                                className="mx-2"
                              />
                              {(singleCollaborationData?.average_rating && singleCollaborationData?.average_rating !== null) ? singleCollaborationData?.average_rating.toFixed(1) : "0"}
                              (
                              {(singleCollaborationData?.total_reviews && singleCollaborationData?.total_reviews != 0) ? singleCollaborationData?.total_reviews :
                                "0"})
                              {/* 4.9 (590) */}
                            </span>
                          </div>
                          <div className="col-md-5 d-flex justify-content-end">
                            <div>
                              <div className="mb-3">
                                <img
                                  src="/assets/images/followers-icon.svg"
                                  alt="followers"
                                  className="me-4"
                                />
                                Followers -
                                <span className="fw-bolder ms-1">
                                  {singleCollaborationData.creator
                                    .followers_count ?? "N/A"}
                                </span>
                              </div>
                              <div>
                                <img
                                  src="/assets/images/engagement-icon.svg"
                                  alt="followers"
                                  className="me-3"
                                />
                                Engagement Rate -
                                <span className="fw-bolder ms-1">
                                  {singleCollaborationData.creator
                                    .engagement_ratio ?? "N/A"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {collaborationOffers[0]?.price ? (
                            <div className="price-info">
                              Price : ${collaborationOffers[0]?.price}
                            </div>
                          ) : (
                            <div className="price-info">
                              Commission Percentile :
                              {collaborationOffers[0]?.commission}%
                            </div>
                          )}
                          <div className="reject-tags">
                            Auto Reject Tags: Infomatics, Google Ads.
                          </div>
                        </div>
                      </div>
                    )}
                    {/* FOR BRAND SIDE  */}
                  </>
                ) : (
                  <>
                    {/* FOR CREATOR SIDE  */}
                    {singleCollaborationData?.brand && (
                      <div className="campaign-details-section mt-5">
                        <div className="row">
                          <div className="col-md-2 d-flex justify-content-center">
                            {singleCollaborationData.brand.profile_image !==
                              undefined &&
                              singleCollaborationData.brand.profile_image !==
                              null ? (
                              <img
                                className="brand-profile-image"
                                src={
                                  singleCollaborationData.brand.profile_image
                                }
                                alt="avtar-a"
                              />
                            ) : (
                              <CircleImageNFound width={90} height={90} />
                            )}
                          </div>
                          <div className="col-md-10">
                            <div className="row">
                              <div className="profile-name col-md-8">
                                <div className="row">
                                  <div className="col-md-8 text-truncate">
                                    {singleCollaborationData.brand.brand_name ??
                                      "-"}
                                  </div>
                                  <div className="col-md-4">
                                    <div className="d-flex sm-align-to-center">
                                      <img
                                        src="/assets/images/reviewStar.svg"
                                        width={18}
                                        alt="star"
                                        className="mx-2"
                                      />

                                      {(singleCollaborationData?.average_rating && singleCollaborationData?.average_rating !== null) ? singleCollaborationData?.average_rating.toFixed(1) : "0"}
                                      (
                                      {(singleCollaborationData?.total_reviews && singleCollaborationData?.total_reviews != 0) ? singleCollaborationData?.total_reviews :
                                        "0"}
                                      ){/* 4.9 (590) */}
                                    </div>
                                  </div>
                                  {/* <div className="col-md-4"></div> */}
                                </div>
                                <div className="my-2 py-auto d-flex sm-align-to-center">
                                  <img
                                    src="/assets/images/locationIconSearch.svg"
                                    alt="search"
                                    width={15}
                                  />
                                  <span
                                    className="ms-1 fs-6 fs-md-5 text-muted"
                                    style={{ maxWidth: "500px" }}
                                  >
                                    <p className="text-truncate my-auto">
                                      {
                                        singleCollaborationData.brand
                                          .company_address
                                      }
                                    </p>
                                  </span>
                                </div>
                              </div>
                              {/* {approvedCollab?.length ?
                                <>
                                  <div className="accepted-collabs col-md-4">
                                    {approvedCollab?.length} Accepted Collaborations
                                  </div>
                                </>
                                : <>
                                  <div className="accepted-collabs col-md-4">
                                    0 Accepted Collaborations
                                  </div>
                                </>} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* FOR CREATOR SIDE  */}
                  </>
                )}

                {collaborationStatus === "ongoing" ||
                  collaborationStatus === "completed" ||
                  collaborationStatus === "approved" ||
                  collaborationStatus === "appealed" ? (
                  <>
                    <div className="categories-dashboard p-5 ">
                      <div
                        ref={divRef}
                        onScroll={handleScroll}
                        id="creator-chat-box-scroll"
                        className="non-editable-chat-section"
                      >
                        {loadingMessages ? (
                          <SyncLoader
                            className="d-flex justify-content-center my-5 mx-auto"
                            color="#65acae"
                            size={10}
                          />
                        ) : (
                          <>
                            {isAtTop && currentPage + 1 <= totalMessagePages ? (
                              <>
                                <SyncLoader
                                  className="d-flex justify-content-center my-5 mx-auto"
                                  color="#65acae"
                                  size={10}
                                />
                              </>
                            ) : null}
                            {messageList
                              .slice()
                              .sort(
                                (a, b) =>
                                  new Date(a.id) -
                                  new Date(b.id)
                              )
                              .map((message, index) => {
                                return (
                                  <div key={`messages-${index}`}>
                                    <div>
                                      {message.sender_id ===
                                        user?.id.toString() ? (
                                        message.message ===
                                          "Work Submission Request sent" ||
                                          message.message ===
                                          "Work Submission Request Sent Again" ? (
                                          <WorkSubmitted
                                            role={user?.role}
                                            fileSubmission_id={
                                              message?.file_submission_id
                                            }
                                            link={
                                              message.task_submission !== null
                                                ? message?.task_submission
                                                  ?.file_submissions
                                                : []
                                            }
                                            linkSubmitted={false}
                                            time={message?.created_at
                                              ?.split("T")[1]
                                              ?.split(".")[0]
                                              ?.slice(0, -3)}
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                          />
                                        ) : message.message ===
                                          "Invite request sent" ||
                                          message.message ===
                                          "Invite request received" ? (
                                          <ShowOffer
                                            time={
                                              message.created_at !== null &&
                                                message.created_at !== undefined
                                                ? message.created_at
                                                  .split("T")[1]
                                                  .split(".")[0]
                                                  .slice(0, -3)
                                                : ""
                                            }
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                            isReceiver={
                                              message?.offer?.received_by ==
                                                user?.id
                                                ? true
                                                : false
                                            }
                                            offer={message.offer}
                                          />
                                        ) : message.message ===
                                          "Review added" ||
                                          message.message ===
                                          "Review Received" ? (
                                          // review sender
                                          <ReviewGiven
                                            isSender={
                                              message?.sender?.id === user?.id
                                                ? true
                                                : false
                                            }
                                            comment={
                                              message?.collaboration?.collaboration_reviews?.find(
                                                (review) =>
                                                  review.reviewer_id == user?.id
                                              )?.comment
                                            }
                                            rating={
                                              message?.collaboration?.collaboration_reviews?.find(
                                                (review) =>
                                                  review.reviewer_id == user?.id
                                              )?.rating
                                            }
                                            time={message.created_at
                                              .split("T")[1]
                                              .split(".")[0]
                                              .slice(0, -3)}
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                          />
                                        ) : message.message ===
                                          "Appeal Request" ? (
                                          <AppealInitiated
                                            isSender={
                                              message?.sender?.id === user?.id
                                                ? 1
                                                : 0
                                            }
                                            time={message.created_at
                                              .split("T")[1]
                                              .split(".")[0]
                                              .slice(0, -3)}
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                          />
                                        ) : message.message ===
                                          "Link Submitted" ||
                                          message.message ===
                                          "Link Submitted Again" ? (
                                          <WorkSubmitted
                                            role={user?.role}
                                            link={
                                              message.task_submission !== null
                                                ? message?.task_submission
                                                : null
                                            }
                                            contentLink={
                                              message.message ===
                                                "Link Submitted Again"
                                                ? message.task_submission
                                                  ?.again_content_link
                                                : message.task_submission
                                                  ?.content_link
                                            }
                                            screenShot={
                                              message.message ===
                                                "Link Submitted Again"
                                                ? message.task_submission
                                                  ?.again_screenshot
                                                : message.task_submission
                                                  ?.screenshot
                                            }
                                            linkSubmitted={true}
                                            time={message.created_at
                                              .split("T")[1]
                                              .split(".")[0]
                                              .slice(0, -3)}
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                          />
                                        ) : message?.message !== null &&
                                          message?.message !== undefined ? (
                                          message?.message?.includes(
                                            "Customized offer created"
                                          ) ? (
                                            <SentCustomOffer
                                              time={
                                                message.created_at !== null &&
                                                  message.created_at !== undefined
                                                  ? message.created_at
                                                    .split("T")[1]
                                                    .split(".")[0]
                                                    .slice(0, -3)
                                                  : ""
                                              }
                                              userImage={
                                                message.sender.creator !== null
                                                  ? message.sender.creator
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : message.sender.brand !==
                                                    null
                                                    ? message.sender.brand
                                                      .profile_image ??
                                                    "/assets/images/404_circle.png"
                                                    : "/assets/images/404_circle.png"
                                              }
                                              message={message.message}
                                              file={message.file}
                                              type={message.type}
                                              offer={message.offer}
                                            />
                                          ) : message?.message?.includes(
                                            "Extension request sent"
                                          ) ? (
                                            <ExtensionMessageSent
                                              time={
                                                message.created_at !== null &&
                                                  message.created_at !== undefined
                                                  ? message.created_at
                                                    .split("T")[1]
                                                    .split(".")[0]
                                                    .slice(0, -3)
                                                  : ""
                                              }
                                              userImage={
                                                message.sender.creator !== null
                                                  ? message.sender.creator
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : message.sender.brand !==
                                                    null
                                                    ? message.sender.brand
                                                      .profile_image ??
                                                    "/assets/images/404_circle.png"
                                                    : "/assets/images/404_circle.png"
                                              }
                                              hours={
                                                message?.task_extension_request
                                                  ?.hours
                                              }
                                            />
                                          ) : (
                                            <SentMessage
                                              user={user}
                                              time={
                                                message.created_at !== null &&
                                                  message.created_at !== undefined
                                                  ? message.created_at
                                                    .split("T")[1]
                                                    .split(".")[0]
                                                    .slice(0, -3)
                                                  : ""
                                              }
                                              userImage={
                                                message.sender.creator !== null
                                                  ? message.sender.creator
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : message.sender.brand !==
                                                    null
                                                    ? message.sender.brand
                                                      .profile_image ??
                                                    "/assets/images/404_circle.png"
                                                    : "/assets/images/404_circle.png"
                                              }
                                              message={message.message}
                                              fileName={message.original_name}
                                              file={message.file}
                                              type={message.type}
                                            />
                                          )
                                        ) : (
                                          <SentMessage
                                            time={
                                              message.created_at !== null &&
                                                message.created_at !== undefined
                                                ? message.created_at
                                                  .split("T")[1]
                                                  .split(".")[0]
                                                  .slice(0, -3)
                                                : ""
                                            }
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                            message={message.message}
                                            fileName={message.original_name}
                                            file={message.file}
                                            type={message.type}
                                          />
                                        )
                                      ) : message.message ===
                                        "Proof Rejected" ||
                                        message.message ===
                                        "Proof Rejected Again" ? (
                                        <ProofRejected
                                          time={message.created_at
                                            .split("T")[1]
                                            .split(".")[0]
                                            .slice(0, -3)}
                                          userImage={
                                            message.sender.creator !== null
                                              ? message.sender.creator
                                                .profile_image ??
                                              "/assets/images/404_circle.png"
                                              : message.sender.brand !== null
                                                ? message.sender.brand
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : "/assets/images/404_circle.png"
                                          }
                                          message={
                                            message.message ===
                                              "Proof Rejected Again"
                                              ? message?.task_submission
                                                ?.again_link_rejection_reason
                                              : message?.task_submission
                                                ?.link_rejection_reason
                                          }
                                        />
                                      ) : message.message ===
                                        "Work Submission Request Received Again" ||
                                        message.message ===
                                        "Work Submission Request received" ? (
                                        <WorkSubmitted
                                          role={user?.role}
                                          fileSubmission_id={
                                            message?.file_submission_id
                                          }
                                          link={
                                            message.task_submission !== null
                                              ? message?.task_submission
                                                ?.file_submissions
                                              : []
                                          }
                                          linkSubmitted={false}
                                          time={message.created_at
                                            .split("T")[1]
                                            .split(".")[0]
                                            .slice(0, -3)}
                                          userImage={
                                            message.sender.creator !== null
                                              ? message.sender.creator
                                                .profile_image ??
                                              "/assets/images/404_circle.png"
                                              : message.sender.brand !== null
                                                ? message.sender.brand
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : "/assets/images/404_circle.png"
                                          }
                                        />
                                      ) : message.message ===
                                        "Invite request sent" ||
                                        message.message ===
                                        "Invite request received" ? (
                                        <ShowOffer
                                          time={
                                            message.created_at !== null &&
                                              message.created_at !== undefined
                                              ? message.created_at
                                                .split("T")[1]
                                                .split(".")[0]
                                                .slice(0, -3)
                                              : ""
                                          }
                                          userImage={
                                            message.sender.creator !== null
                                              ? message.sender.creator
                                                .profile_image ??
                                              "/assets/images/404_circle.png"
                                              : message.sender.brand !== null
                                                ? message.sender.brand
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : "/assets/images/404_circle.png"
                                          }
                                          isReceiver={
                                            message?.offer?.received_by ==
                                              user?.id
                                              ? true
                                              : false
                                          }
                                          offer={message.offer}
                                        />
                                      ) : message.message === "Review added" ||
                                        message.message ===
                                        "Review Received" ? (
                                        // review receiver
                                        <ReviewGiven
                                          isSender={
                                            message?.sender?.id === user?.id
                                              ? true
                                              : false
                                          }
                                          comment={
                                            message?.collaboration?.collaboration_reviews?.find(
                                              (review) =>
                                                review.collaborator_id ==
                                                user?.id
                                            )?.comment
                                          }
                                          rating={
                                            message?.collaboration?.collaboration_reviews?.find(
                                              (review) =>
                                                review.collaborator_id ==
                                                user?.id
                                            )?.rating
                                          }
                                          time={message.created_at
                                            .split("T")[1]
                                            .split(".")[0]
                                            .slice(0, -3)}
                                          userImage={
                                            message.sender.creator !== null
                                              ? message.sender.creator
                                                .profile_image ??
                                              "/assets/images/404_circle.png"
                                              : message.sender.brand !== null
                                                ? message.sender.brand
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : "/assets/images/404_circle.png"
                                          }
                                        />
                                      ) : message.message ===
                                        "Appeal Request" ? (
                                        <AppealInitiated
                                          isSender={
                                            message?.sender?.id === user?.id
                                              ? 1
                                              : 0
                                          }
                                          time={message.created_at
                                            .split("T")[1]
                                            .split(".")[0]
                                            .slice(0, -3)}
                                          userImage={
                                            message.sender.creator !== null
                                              ? message.sender.creator
                                                .profile_image ??
                                              "/assets/images/404_circle.png"
                                              : message.sender.brand !== null
                                                ? message.sender.brand
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : "/assets/images/404_circle.png"
                                          }
                                        />
                                      ) : message.message ===
                                        "Link Submitted" ||
                                        message.message ===
                                        "Link Submitted Again" ? (
                                        <WorkSubmitted
                                          role={user?.role}
                                          link={
                                            message.task_submission !== null
                                              ? message?.task_submission
                                              : null
                                          }
                                          contentLink={
                                            message.message ===
                                              "Link Submitted Again"
                                              ? message.task_submission
                                                ?.again_content_link
                                              : message.task_submission
                                                ?.content_link
                                          }
                                          screenShot={
                                            message.message ===
                                              "Link Submitted Again"
                                              ? message.task_submission
                                                ?.again_screenshot
                                              : message.task_submission
                                                ?.screenshot
                                          }
                                          linkSubmitted={true}
                                          time={message.created_at
                                            .split("T")[1]
                                            .split(".")[0]
                                            .slice(0, -3)}
                                          userImage={
                                            message.sender.creator !== null
                                              ? message.sender.creator
                                                .profile_image ??
                                              "/assets/images/404_circle.png"
                                              : message.sender.brand !== null
                                                ? message.sender.brand
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : "/assets/images/404_circle.png"
                                          }
                                        />
                                      ) : message?.message !== null &&
                                        message?.message !== undefined ? (
                                        message.message.includes(
                                          "Customized offer created"
                                        ) ? (
                                          <ReceivedCustomOffer
                                            time={
                                              message.created_at !== null &&
                                                message.created_at !== undefined
                                                ? message.created_at
                                                  .split("T")[1]
                                                  .split(".")[0]
                                                  .slice(0, -3)
                                                : ""
                                            }
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                            message={message.message}
                                            file={message.file}
                                            type={message.type}
                                            offer={message.offer}
                                          />
                                        ) : message?.message?.includes(
                                          "Extension request received"
                                        ) ? (
                                          <ExtensionMessageReceived
                                            time={
                                              message.created_at !== null &&
                                                message.created_at !== undefined
                                                ? message.created_at
                                                  .split("T")[1]
                                                  .split(".")[0]
                                                  .slice(0, -3)
                                                : ""
                                            }
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                            hours={
                                              message?.task_extension_request
                                                ?.hours
                                            }
                                          />
                                        ) : message?.message?.includes(
                                          "Work Change Request Received"
                                        ) ? (
                                          <AdjustmentRequested
                                            time={
                                              message.created_at !== null &&
                                                message.created_at !== undefined
                                                ? message.created_at
                                                  .split("T")[1]
                                                  .split(".")[0]
                                                  .slice(0, -3)
                                                : ""
                                            }
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                            message={
                                              message?.task_submission
                                                ?.adjustment?.detail
                                            }
                                          />
                                        ) : (
                                          <OtherUserMessage
                                            user={user}
                                            time={message.created_at
                                              .split("T")[1]
                                              .split(".")[0]
                                              .slice(0, -3)}
                                            userImage={
                                              message.sender.creator !== null
                                                ? message.sender.creator
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : message.sender.brand !== null
                                                  ? message.sender.brand
                                                    .profile_image ??
                                                  "/assets/images/404_circle.png"
                                                  : "/assets/images/404_circle.png"
                                            }
                                            message={message.message}
                                            fileName={message.original_name}
                                            file={message.file}
                                            type={message.type}
                                          />
                                        )
                                      ) : (
                                        <OtherUserMessage
                                          user={user}
                                          time={message.created_at
                                            .split("T")[1]
                                            .split(".")[0]
                                            .slice(0, -3)}
                                          userImage={
                                            message.sender.creator !== null
                                              ? message.sender.creator
                                                .profile_image ??
                                              "/assets/images/404_circle.png"
                                              : message.sender.brand !== null
                                                ? message.sender.brand
                                                  .profile_image ??
                                                "/assets/images/404_circle.png"
                                                : "/assets/images/404_circle.png"
                                          }
                                          message={message.message}
                                          fileName={message.original_name}
                                          file={message.file}
                                          type={message.type}
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </>
        )}
      </>
    </AppLayout>
  );
};

export default CollaborationDetails;
