import React, { useEffect, useRef, useState } from "react";
// import { socket } from "../../Common/Socket";
import EmojiPicker from "emoji-picker-react";
import AppLayout from "../../Components/AppLayout";
import ChatRequest from "../../Components/ChatRequest";
import DropdownForList from "../../Components/DropdownForList";
import {
  AttachmentIcon,
  CrossIcon,
  EmojiIcon,
  FullSearchIcon,
  SendIcon,
  UploadIcon,
} from "../../Components/Icons";
import {
  AcceptExtensionApi,
  AcceptLinkApi,
  AcceptOfferApi,
  AcceptSubmitWorkApi,
  addReviewApi,
  allMsgReadCall,
  AskAdjustmentApi,
  GetChatListApi,
  GetMessagesListApi,
  GetMessagesListPaginatedApi,
  RejectExtensionApi,
  RejectLinkApi,
  RejectOfferApi,
  ReportIssueApi,
  SendCustomOfferApi,
  sendMessageToReceiver,
  SubmitAppealApi,
  SubmitExtensionApi,
  SubmitLinkApi,
  SubmitWorkApi,
} from "../../Common/Repository";
import {
  AdjustmentRequested,
  AppealInitiated,
  ExtensionMessageReceived,
  ExtensionMessageSent,
  InfluencerLinkModal,
  OtherUserMessage,
  ProofRejected,
  ReceivedCustomOffer,
  ReviewGiven,
  ReviewModal,
  SentCustomOffer,
  SentMessage,
  ShowOffer,
  WorkSubmitted,
} from "../../Components/ChatModals";
import { useFilePicker } from "use-file-picker";
import dataURItoBlob from "../../Components/ImageToBlob";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SyncLoader } from "react-spinners";
import {
  resetStates,
  addMessage,
  setCurrentChatId,
} from "../../states/chat/chatSlice";

import { PuffLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";

const CreatorChat = () => {
  const location = useLocation();

  useEffect(() => {
    return () => {
      dispatch(setCurrentChatId(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.state?.page) {
      setPage(location?.state?.page);
      getChatListFromDb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const divRef = useRef(null);

  //SELECTORS
  const user = useSelector((state) => state.auth.user);
  const {
    submitExtensionError,
    askAdjustmentError,
    submitLinkError,
    loadingChats,
    loadingMessages,
    chatList,
    messageList,
    workSent,
    deliveryTimeBar,
    acceptOffer,
    inviteRequestAccepted,
    loadingBrandPayment,
    packBought,
    workReceived,
    workReceivedAgain,
    proofSent,
    proofReceived,
    proofReceivedAgain,
    proofAccepted,
    proofRejected,
    issueReported,
    customOfferSent,
    customOfferReceived,
    changeRequestReceived,
    workSubmitSentAgain,
    appealRequest,
    decisionInMyFav,
    decisionInCreatorFav,
    decisionInBrandFav,
    paymentProcessed,
    extensionRequestReceived,
    reviewIsGiven,
    reviewIsReceived,
    bothHaveGivenReviews,
    brandFavourDecision,
    workAccepted,
    workAccept,
    reportIssueTime,
    chatRequestReceived,
    chatRequestSent,
    offer,
    loadingAcceptOffer,
    loadingRejectOffer,
    workLoading,
    currentStage,
    currentChatId,
    isLateDelivery,
    pendingRequestCount,
    nextPageMessageUrl,
    totalMessagePages,
    currentPage,
  } = useSelector((state) => state.chat);


  useEffect(() => {
    console.log('chatList: ', chatList);
  }, [chatList])



  //START STATES

  const token = localStorage.getItem("token");
  const [isProceed, setIsProceed] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);
  // const [adjReq, setAdjReq] = useState(false);
  const [adjustmentError, setAdjustmentError] = useState("");
  const [messageField, setMessageField] = useState("");
  const [fieldPreview, setFieldPreview] = useState("");
  const [fileToBeSent, setFilesToBeSent] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [offerObject, setOfferObject] = useState("");
  const [offerDeliveryTime, setOfferDeliveryTime] = useState("");
  const [extensionTime, setExtensionTime] = useState("");
  const [contentLink, setContentLink] = useState("");
  const [emojiPickerVisible, setEmojiPickerVisibile] = useState(false);
  // const [acceptCustomOffer, setAcceptCustomOffer] = useState(false);
  // const [waitingDecisionFromVitrine, setWaitingDecisionFromVitrine] = useState(false);
  // const [approvalBtns, setApprovalBtns] = useState(false);
  const [description, setDescription] = useState("");
  const [chat_id, setChatId] = useState(-1);
  const [search, setSearch] = useState("");
  const [submittingWork, setSubmittingWork] = useState(false);
  const [receiver_id, setReceiverId] = useState("");
  // const [nextPageMessageUrl, setNextPageMessageUrl] = useState("");
  // const [fromMPage, setFromMPage] = useState("");
  // const [lastMPage, setLastMPage] = useState("");
  const [acceptance, setAcceptance] = useState({
    terms1: false,
    terms2: false,
  });
  // const [isPaid, setIsPaid] = useState(false);
  const [page, setPage] = useState("Inbox");
  const isFormValid = acceptance.terms1 && acceptance.terms2;
  // const [isConnected, setIsConnected] = useState(socket.connected);
  // const [adjustmentId, setAdjustmentId] = useState("");
  // const [submittedWork, setSubmittedWork] = useState("");
  const [selectedEmojis, setSelectedEmojis] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([
    "All Chats",
    "Active Collaboration",
    "Completed Collaborations",
    "On-Going",
    "Unread",
  ]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [otherUser, setOtherUser] = useState();
  const [rating, setRating] = useState(0);

  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [objectError, setObjectError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [submitWorkError, setSubmitWorkError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState();
  const [askForExtError, setAskForExtError] = useState(false);
  const [reportAnIssueError, setReportAnIssueError] = useState(false);
  const [rejectProofError, setRejectProofError] = useState(false);
  const [submitAppealError, setSubmitAppealError] = useState(false);
  const [reviewError, setReviewError] = useState(false);
  const [isAtTop, setIsAtTop] = useState(false);

  const durationForExtension = ["12", "24", "48", "72"];
  const maxWordCount250 = 250;
  const maxWordCount500 = 500;
  const remainingWords = description.length;
  const objectData = ["Story", "Reel", "Short Video", "Graphic Post", "Custom"];

  function formatTimeToUserTimeZone(backendTime) {
    const backendDateTime = new Date(backendTime);

    // Adjust for the timezone difference between UTC and browser
    const userTimeZoneOffset = new Date().getTimezoneOffset();
    const backendTimeZoneOffset = backendDateTime.getTimezoneOffset();
    const timeZoneDifference =
      (userTimeZoneOffset - backendTimeZoneOffset) * 60 * 1000;
    const userTimeZoneTime = new Date(
      backendDateTime.getTime() + timeZoneDifference
    );

    // Format the adjusted time
    const formattedTime = userTimeZoneTime.toLocaleString(undefined, {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      // second: 'numeric'
    });

    return formattedTime;
  }

  const validateSendCollab = () => {
    resetErrors();
    let errors = new Map();
    if (description.length < 21) {
      setDescriptionError(true);
      errors.set(
        "description",
        "The description needs to be more than 20 characters!"
      );
    }
    if (offerPrice.length < 1) {
      setPriceError(true);
      errors.set("price", "Please enter a valid offering price");
    }
    if (offerObject.length < 1) {
      setObjectError(true);
      errors.set("object", "Please select an object");
    }

    if (offerDeliveryTime.length < 10) {
      setDateError(true);
      errors.set("delivery_time", "Please enter a delivery time");
    }

    setFieldErrors(errors);

    if (errors.size > 0) {
      return false;
    } else {
      return true;
    }
  };

  const resetErrors = () => {
    setFieldErrors([]);
    setDateError(false);
    setDescriptionError(false);
    setPriceError(false);
    setObjectError(false);
  };

  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    readAs: "DataURL",
    accept: [
      "image/*",
      ".pdf",
      ".docx",
      ".odt",
      ".doc",
      ".xlsx",
      ".xls",
      ".txt",
      ".conf",
      ".json",
      ".csv",
    ],
    multiple: true,
    limitFilesConfig: { max: 4 },
    // minFileSize: 1,
    maxFileSize: 50, // in megabytes
  });

  //START FUNCTIONS
  const handleExtensionDuration = (value) => {
    setAskForExtError(false);
    setExtensionTime(value);
  };

  const getChatListFromDb = async () => {
    try {
      let data = {
        status: [],
      };
      // Check if "All Chats" is in selectedValues
      const allChatsSelected = selectedValues.includes("All Chats");
      selectedValues.forEach((value) => {
        if (value === "Active Collaboration") {
          data.state = "active";
        } else if (
          value === "Completed Collaborations" ||
          value === "On-Going"
        ) {
          const tValue =
            value === "Completed Collaborations"
              ? "completed"
              : value === "On-Going"
                ? "ongoing"
                : "";
          data.status.push(tValue);
        } else if (value === "Unread") {
          data.is_read = "0";
        } else if (value === "All Chats" && allChatsSelected) {
          // When "All Chats" is selected and already in selectedValues, reset data
          data = {};
        }
      });
      var header = {
        Authorization: `Bearer ${token}`,
      };
      dispatch(resetStates());
      dispatch(GetChatListApi({ header, data }));
      getMessageListFromDb(chatList[0]?.chat_id, chatList[0]?.user?.id);
    } catch (error) { }
  };

  const searchChats = async () => {
    try {
      let data = new FormData();

      data.append("search", search);
      var header = {
        Authorization: `Bearer ${token}`,
      };
      dispatch(resetStates());
      dispatch(GetChatListApi({ header, data }));
    } catch (error) { }
  };

  const handleAllRead = async () => {
    try {
      let response = await allMsgReadCall({
        Authorization: `Bearer ${token}`,
      });
      if (response) {
        toast.success("All Chats Marked as Read");
        dropdownOpen();
      }
    } catch (error) { }
  };

  const handleShowReceiverName = (userID) => {
    const mychat = chatList?.find((chat) => chat.user.id === userID);
    setOtherUser(mychat?.user);
  };

  // to get all the messages of a collaboration
  const getMessageListFromDb = async (chat_id, receiverId, type) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
      };
      let formData = {};
      formData.chat_id = chat_id;
      dispatch(setCurrentChatId(chat_id));
      setChatId(chat_id);
      setReceiverId(receiverId);
      dispatch(resetStates());
      dispatch(GetMessagesListApi({ header, formData, dispatch, chat_id })); //

      // dispatch(setTotalUnreadMessagesCount({ count: chatList[chatList.findIndex(d => d.chat_id == chat_id)].unread_message_count }))
    } catch (error) { }
  };

  const sendMessage = async (message, fileToSend, fileName) => {
    setFieldPreview("");
    try {
      let formData = new FormData();
      formData.append("receiver_id", receiver_id);
      if (message !== undefined) {
        formData.append("message", message);
      }
      if (fileToSend !== undefined) {
        let uploadBlob = dataURItoBlob(fileToSend);
        formData.append(`file`, uploadBlob);
        formData.append(`original_name`, fileName);
      }
      formData.append("chat_id", chat_id);
      let response = await sendMessageToReceiver(
        {
          Authorization: `Bearer ${token}`,
        },
        formData
      );
      if (
        response.status === 200 &&
        (fileToSend === undefined || fileToSend === "" || fileToSend === null)
      ) {
        dispatch(addMessage(response?.data?.data));
        setMessageField("");
      } else if (
        response.status === 200 &&
        (message === undefined || message === "" || message === null)
      ) {
        dispatch(addMessage(response?.data?.data));
        setMessageField("");
      } else {
        dispatch(addMessage(response?.data?.data));
        setMessageField("");
      }
    } catch (error) { }
  };

  const handleCheckboxChange = (item) => {
    if (item === "All Chats") {
      // Toggle the selection of "All Chats"
      if (selectedValues.includes("All Chats")) {
        setSelectedValues(
          selectedValues.filter((value) => value !== "All Chats")
        ); // Unselect "All Chats"
      } else {
        setSelectedValues(["All Chats"]); // Select "All Chats" and clear other selections
      }
    } else {
      // Toggle the selection of other options and unselect "All Chats" if necessary
      if (selectedValues.includes("All Chats")) {
        setSelectedValues([item]); // Select the clicked option and clear other selections
      } else {
        setSelectedValues((prevSelectedValues) => {
          if (prevSelectedValues.includes(item)) {
            return prevSelectedValues.filter((value) => value !== item);
          } else {
            return [...prevSelectedValues, item];
          }
        });
      }
    }
  };

  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const dropdownOpen = (event) => {
    if (event) {
      const obj = event.currentTarget;
      if (window.$(obj).find(".inner-dropdown").hasClass("open")) {
        window.$(obj).find(".inner-dropdown").removeClass("open");
      } else {
        window.$(".inner-dropdown").removeClass("open");
        window.$(obj).find(".inner-dropdown").addClass("open");
        window.$(obj).find(".inner-dropdown").removeClass("d-none");
      }
    } else {
      window.$(".inner-dropdown").removeClass("open");
    }
  };

  const dropdownBox = (event) => {
    event.stopPropagation();
  };

  const fileSelector = () => {
    openFileSelector();
  };

  const acceptCreatorOffer = async () => {
    let formData = {};
    formData.offer_id = offer?.id;
    let header = {
      Authorization: `Bearer ${token}`,
    };
    dispatch(AcceptOfferApi({ header, formData, dispatch, currentChatId }));
  };

  const rejectCreatorOffer = async () => {
    let formData = {};
    formData.offer_id = offer?.id;
    let header = {
      Authorization: `Bearer ${token}`,
    };
    dispatch(RejectOfferApi({ header, formData, dispatch, currentChatId }));
  };

  const SubmitWork = async () => {
    if (filesContent.length === 0) {
      setSubmitWorkError(true);
    } else {
      let formData = new FormData();
      formData.append("offer_id", offer?.id);
      for (var i = 0; i < filesContent.length; i++) {
        let fileData = dataURItoBlob(filesContent[i].content);
        formData.append(`files[${i}]`, fileData);
      }
      let header = {
        Authorization: `Bearer ${token}`,
      };
      dispatch(
        SubmitWorkApi({
          header,
          formData,
          dispatch,
          currentChatId,
          clear,
          setFilesToBeSent,
        })
      );
    }
  };

  const AcceptSubmittedWork = async () => {
    let formData = new FormData();
    formData.append("task_submission_id", currentStage?.task_submission_id);
    formData.append("status", "approved");
    let header = {
      Authorization: `Bearer ${token}`,
    };
    dispatch(
      AcceptSubmitWorkApi({ header, formData, dispatch, currentChatId })
    );
  };

  const AskForAdjustment = async () => {
    let formData = new FormData();
    formData.append("task_submission_id", currentStage?.task_submission_id);
    formData.append("detail", description);
    let header = {
      Authorization: `Bearer ${token}`,
    };
    dispatch(AskAdjustmentApi({ header, formData, dispatch, currentChatId }));
    // document.getElementById("adjustment-modal").click();
  };

  const SubmitProofLink = async () => {
    let formData = new FormData();
    formData.append("task_submission_id", currentStage.task_submission_id);
    formData.append("content_link", contentLink);
    if (fileToBeSent) {
      let fileData = dataURItoBlob(fileToBeSent);
      formData.append(`screenshot`, fileData);
    }
    let header = {
      Authorization: `Bearer ${token}`,
    };
    dispatch(SubmitLinkApi({ header, formData, dispatch, currentChatId }));
    clear();
  };

  const addReview = async () => {
    if (rating) {
      let formData = new FormData();
      formData.append("collaboration_id", currentChatId);
      formData.append("reviewer_id", user?.id); //the one sending the review
      formData.append("rating", rating);
      formData.append("comment", description);
      if (offer?.sent_by === user?.id) {
        formData.append("collaborator_id", offer?.received_by); // the one receiving the review
      }
      if (offer?.received_by === user?.id) {
        formData.append("collaborator_id", offer?.sent_by);
      }
      let header = {
        Authorization: `Bearer ${token}`,
      };
      dispatch(addReviewApi({ header, formData, dispatch, currentChatId }));
    } else {
      setReviewError(true);
    }
  };

  const AcceptProofLink = async () => {
    let formData = new FormData();
    formData.append("task_submission_id", currentStage.task_submission_id);
    let header = {
      Authorization: `Bearer ${token}`,
    };
    dispatch(AcceptLinkApi({ header, formData, dispatch, currentChatId }));
  };

  const RejectProofLink = async () => {
    if (description) {
      let formData = new FormData();
      formData.append("task_submission_id", currentStage.task_submission_id);
      formData.append("reason", description);
      let header = {
        Authorization: `Bearer ${token}`,
      };
      dispatch(RejectLinkApi({ header, formData, dispatch, currentChatId }));
    } else {
      setRejectProofError(true);
    }
  };

  const ReportIssue = async () => {
    if (description && filesContent.length > 0) {
      let formData = new FormData();
      formData.append("task_submission_id", currentStage?.task_submission_id);
      formData.append("issue", description);
      if (filesContent.length > 0) {
        let fileData = dataURItoBlob(filesContent[0]?.content);
        formData.append(`attachment`, fileData);
      }
      let header = {
        Authorization: `Bearer ${token}`,
      };
      dispatch(
        ReportIssueApi({
          header,
          formData,
          dispatch,
          currentChatId,
          clear,
          setSubmittingWork,
        })
      );
    } else {
      setReportAnIssueError(true);
    }

    // clear();
    // document.getElementById("close-report-modal").click();
  };

  const AcceptExtension = async () => {
    let formData = new FormData();
    formData.append(
      "task_extension_request_id",
      currentStage.task_extension_request_id
    );
    let header = {
      Authorization: `Bearer ${token}`,
    };
    dispatch(AcceptExtensionApi({ header, formData, dispatch, currentChatId }));
  };

  const RejectExtension = async () => {
    let formData = new FormData();
    formData.append(
      "task_extension_request_id",
      currentStage.task_extension_request_id
    );
    let header = {
      Authorization: `Bearer ${token}`,
    };
    dispatch(RejectExtensionApi({ header, formData, dispatch, currentChatId }));
  };

  const SubmitExtension = async () => {
    if (extensionTime) {
      let formData = new FormData();
      formData.append("offer_id", offer?.id);
      formData.append("extension_time", extensionTime);
      let header = {
        Authorization: `Bearer ${token}`,
      };
      dispatch(
        SubmitExtensionApi({ header, formData, dispatch, currentChatId })
      );
    } else {
      setAskForExtError(true);
    }
  };

  const CreateCustomOffer = async () => {
    try {
      if (validateSendCollab()) {
        let formData = new FormData();
        formData.append("collaboration_id", chat_id);
        formData.append("description", description);
        formData.append("price", offerPrice);
        formData.append("object", offerObject);
        formData.append("delivery_time", offerDeliveryTime);
        let header = {
          Authorization: `Bearer ${token}`,
        };
        dispatch(
          SendCustomOfferApi({ header, formData, dispatch, currentChatId })
        );
        document.getElementById("custom-offer-modal-close").click();
      }
    } catch (error) {
      toast.error(error?.response?.data?.messsage);
    }
  };

  const SubmitAppeal = async () => {
    if (description) {
      let formData = new FormData();
      formData.append("adjustment_id", currentStage?.adjustment_id);
      if (offer?.received_by === user?.id) {
        formData.append("defendant_id", offer?.sent_by);
      }
      if (offer?.sent_by === user?.id) {
        formData.append("defendant_id", offer?.received_by);
      }
      formData.append("reason", description);
      let header = {
        Authorization: `Bearer ${token}`,
      };
      dispatch(
        SubmitAppealApi({
          header,
          formData,
          dispatch,
          currentChatId,
          setDescription,
        })
      );
    } else {
      setSubmitAppealError(true);
    }
  };

  const handleViewTerms = (key) => {
    if (key === "conditions") {
      window.open("/termsAndConditions", "_blank");
    } else {
      window.open("/nonEditableChat", "_blank");
    }
  };

  const resetForm = () => {
    setAcceptance({
      terms1: false,
      terms2: false,
    });
  };

  const handleNavigateToUserDetails = () => {
    if (otherUser?.brand !== null) {
      navigate("/brandDetails", { state: otherUser?.brand?.id });
    }
    if (otherUser?.creator !== null) {
      navigate("/creatorDetails", {
        state: { creatorId: otherUser?.creator?.id },
      });
    }
  };

  const showInputMessageField = () => {
    if (
      reviewIsGiven ||
      reviewIsReceived ||
      paymentProcessed ||
      decisionInBrandFav ||
      decisionInCreatorFav ||
      decisionInMyFav ||
      proofSent ||
      proofAccepted ||
      proofRejected ||
      workAccept ||
      ((proofReceived || proofReceivedAgain) && user?.role === "2") ||
      (proofAccepted && user?.role === "2") ||
      chatRequestReceived ||
      issueReported ||
      appealRequest ||
      chatRequestSent ||
      loadingMessages ||
      loadingChats ||
      messageList.length <= 0 ||
      offer?.rejected_by ||
      chat_id < 0
    ) {
      return <></>;
    } else {
      return (
        <>
          <div
            className={` ${(deliveryTimeBar && user?.role === "1") ||
              // approvalBtns ||
              workSent ||
              decisionInBrandFav ||
              decisionInCreatorFav ||
              decisionInMyFav ||
              acceptOffer ||
              customOfferSent ||
              workAccept ||
              reviewIsGiven ||
              reviewIsReceived ||
              // waitingDecisionFromVitrine ||
              // acceptCustomOffer ||
              customOfferReceived ||
              (acceptOffer &&
                user?.role === "1" &&
                offer?.received_by == user?.id) ||
              (inviteRequestAccepted && offer?.received_by === user?.id) ||
              (acceptOffer &&
                user?.role === "2" &&
                offer?.received_by == user?.id) ||
              // packBought ||
              changeRequestReceived ||
              extensionRequestReceived ||
              inviteRequestAccepted ||
              workReceived ||
              workSubmitSentAgain ||
              workReceivedAgain
              ? "d-flex p-2 textAreaMsgBtnsTrue"
              : "textAreaMsg d-flex p-2"
              }`}
          >
            <input
              type="text"
              className="form-control border-0 here"
              placeholder="Write a message..."
              value={messageField}
              onChange={(event) => {
                setMessageField(event.target.value);
                // setFieldPreview("");
                // setFilesToBeSent("");
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  if (messageField.trim() !== "" || fileToBeSent) {
                    setEmojiPickerVisibile(false);
                    sendMessage(messageField, fileToBeSent, fieldPreview);
                    setMessageField("");
                  }
                }
              }}
            />

            <div className="d-flex sect-btn-box">
              {fieldPreview !== "" &&
                fieldPreview !== undefined &&
                fieldPreview !== null &&
                !submittingWork ? (
                <>
                  <div
                    className="text-truncate rounded-3"
                    style={{
                      maxWidth: "150px",
                    }}
                  >
                    <a
                      href={fileToBeSent}
                      target="blank"
                      className="p-3 rounded-4 pill bg-secondary text-light"
                    >
                      {fieldPreview.length > 13
                        ? fieldPreview.split(".")[0].slice(0, 7) +
                        "__." +
                        fieldPreview.split(".")[1]
                        : fieldPreview}
                    </a>
                  </div>
                  <div
                    onClick={() => {
                      setFieldPreview("");
                      setMessageField("");
                      setFilesToBeSent("");
                    }}
                  >
                    <CrossIcon />
                  </div>
                </>
              ) : null}
              <div
                className="m-auto"
                onClick={() => {
                  fileSelector();
                  setMessageField("");
                }}
              >
                <AttachmentIcon />
              </div>
              <div
                className="m-auto"
                onClick={() => setEmojiPickerVisibile(!emojiPickerVisible)}
              >
                <EmojiIcon />
              </div>
              {/* <div
              className="sendButton"
              onClick={() => {
                setEmojiPickerVisibile(false);
                sendMessage(
                  messageField,
                  fileToBeSent
                  // filesContent[0].content ?? ""
                );
                setMessageField("");
              }}
            >
              <SendIcon />
            </div> */}
              <div
                className="sendButton"
                onClick={() => {
                  // Check if message or file exists before sending
                  if (messageField.trim() !== "" || fileToBeSent) {
                    setEmojiPickerVisibile(false);
                    sendMessage(messageField, fileToBeSent, fieldPreview);
                    setMessageField("");
                  } else {
                    // Optionally show an error message or disable the button visually
                  }
                }}
              >
                <SendIcon />
              </div>
            </div>
          </div>
          {emojiPickerVisible ? (
            <div className="emoji-position">
              <EmojiPicker
                onEmojiClick={(emoji) => {
                  setSelectedEmojis((prevSelectedEmojis) => [
                    ...prevSelectedEmojis,
                    emoji.emoji,
                  ]);
                  setMessageField(
                    (prevMessageField) => prevMessageField + emoji.emoji
                  );
                }}
              />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  const [isChatListLoaded, setIsChatListLoaded] = useState(false);

  useEffect(() => {
    if (chatList && chatList.length) {
      if (!isChatListLoaded) {
        setActiveIndex(0);

        getMessageListFromDb(chatList[0].chat_id, chatList[0].user?.id);
        handleShowReceiverName(chatList[0].user?.id);
        setIsChatListLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatList]);

  const showSidebarChats = () => {
    return (
      <>
        <div className="all-chat-section">
          {loadingChats ? (
            <SyncLoader
              className="d-flex justify-content-center my-5 mx-auto"
              color="#65acae"
              size={10}
            />
          ) : chatList.length ? (
            chatList.map((chat, index) => {
              return (
                <div
                  className={`single-box-chat ${index === activeIndex ? "active-single-chat-box" : ""
                    }`}
                  key={index}
                  onClick={() => {
                    setActiveIndex(index);
                    getMessageListFromDb(chat.chat_id, chat.user?.id);
                    handleShowReceiverName(chat.user?.id);
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div className="">
                        <img
                          className="profile-image-sm"
                          src={
                            chat.user?.creator !== null
                              ? chat.user?.creator.profile_image ??
                              "/assets/images/user.png"
                              : chat.user?.brand !== null
                                ? chat.user?.brand.profile_image ??
                                "/assets/images/user.png"
                                : "/assets/images/user.png"
                          }
                          alt={`${chat.chat_id}`}
                        />
                      </div>
                      <div className="ms-4">
                        {chat.user?.creator !== null
                          ? chat.user?.creator.profile_name ?? " "
                          : chat.user?.brand !== null
                            ? chat.user?.brand.brand_name ?? " "
                            : " "}
                        <p className="chat-text-name">
                          <span>
                            {chat.user?.creator !== null
                              ? chat.user?.creator.profile_name ?? " "
                              : chat.user?.brand !== null
                                ? chat.user?.brand.company_name ?? " "
                                : " "}
                          </span>
                        </p>
                      </div>
                    </div>
                    {chat?.unread_message_count > 0 ? (
                      <span className="unread-single-message-tag">
                        {chat?.unread_message_count}
                      </span>
                    ) : null}
                  </div>
                  <div className="chat-msg-notif d-flex justify-content-between">
                    <div className="text">
                      {chat?.last_message?.file !== null &&
                        chat?.last_message?.file !== undefined
                        ? "Sent a File"
                        : chat?.last_message?.message || chat?.last_message}
                    </div>
                    <p className="msg-time mt-2">
                      {chat?.last_message?.created_at != null
                        ? `${formatTimeToUserTimeZone(
                          chat?.last_message?.created_at
                        )}`
                        : ""}
                    </p>
                    {/* <span className="unread-single-message-tag">10</span> */}
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <div className="nothing-found">No Chats Found!</div>
            </>
          )}
        </div>
      </>
    );
  };

  const showMessages = () => {
    //
    return (
      <>
        {isAtTop && currentPage + 1 <= totalMessagePages ? (
          <>
            <SyncLoader
              className="d-flex justify-content-center my-5 mx-auto"
              color="#65acae"
              size={10}
            />
          </>
        ) : null}

        {messageList
          .slice()
          .sort((a, b) => a.id - b.id)
          .map((message, index) => {
            return (
              <div key={`messages-${index}`}>
                <div
                  className={`${reviewIsGiven && user?.role == "2"
                    ? "box-padding"
                    : "box-padding"
                    }`}
                >
                  {message.sender_id === user?.id ? (
                    message.message === "Work Submission Request sent" ||
                      message.message === "Work Submission Request Sent Again" ? (
                      <WorkSubmitted
                        role={user?.role}
                        fileSubmission_id={message?.file_submission_id}
                        link={
                          message.task_submission !== null
                            ? message?.task_submission?.file_submissions
                            : []
                        }
                        linkSubmitted={false}
                        time={formatTimeToUserTimeZone(message?.created_at)}
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                      />
                    ) : message.message === "Invite request sent" ||
                      message.message === "Invite request received" ? (
                      <ShowOffer
                        time={
                          message.created_at !== null &&
                            message.created_at !== undefined
                            ? formatTimeToUserTimeZone(message.created_at)
                            : ""
                        }
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                        isReceiver={
                          message?.offer?.received_by == user?.id ? true : false
                        }
                        offer={message.offer}
                      />
                    ) : message.message === "Review added" ||
                      message.message === "Review Received" ? (
                      // review sender
                      <ReviewGiven
                        isSender={
                          message?.sender?.id === user?.id ? true : false
                        }
                        comment={
                          message?.collaboration?.collaboration_reviews?.find(
                            (review) => review.reviewer_id == user?.id
                          )?.comment
                        }
                        rating={
                          message?.collaboration?.collaboration_reviews?.find(
                            (review) => review.reviewer_id == user?.id
                          )?.rating
                        }
                        time={formatTimeToUserTimeZone(message?.created_at)}
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                      />
                    ) : message.message === "Appeal Request" ? (
                      <AppealInitiated
                        isSender={message?.sender?.id === user?.id ? 1 : 0}
                        time={formatTimeToUserTimeZone(message?.created_at)}
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                      />
                    ) : message.message === "Link Submitted" ||
                      message.message === "Link Submitted Again" ? (
                      <WorkSubmitted
                        role={user?.role}
                        link={
                          message.task_submission !== null
                            ? message?.task_submission
                            : null
                        }
                        contentLink={
                          message.message === "Link Submitted Again"
                            ? message.task_submission?.again_content_link
                            : message.task_submission?.content_link
                        }
                        screenShot={
                          message.message === "Link Submitted Again"
                            ? message.task_submission?.again_screenshot
                            : message.task_submission?.screenshot
                        }
                        linkSubmitted={true}
                        time={formatTimeToUserTimeZone(message?.created_at)}
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                      />
                    ) : message?.message !== null &&
                      message?.message !== undefined ? (
                      message?.message?.includes("Customized offer created") ? (
                        <SentCustomOffer
                          time={
                            message.created_at !== null &&
                              message.created_at !== undefined
                              ? formatTimeToUserTimeZone(message.created_at)
                              : ""
                          }
                          userImage={
                            message.sender.creator !== null
                              ? message.sender.creator.profile_image ??
                              "/assets/images/user.png"
                              : message.sender.brand !== null
                                ? message.sender.brand.profile_image ??
                                "/assets/images/user.png"
                                : "/assets/images/user.png"
                          }
                          message={message.message}
                          file={message.file}
                          type={message.type}
                          offer={message.offer}
                        />
                      ) : message?.message?.includes(
                        "Extension request sent"
                      ) ? (
                        <ExtensionMessageSent
                          time={
                            message.created_at !== null &&
                              message.created_at !== undefined
                              ? formatTimeToUserTimeZone(message.created_at)
                              : ""
                          }
                          userImage={
                            message.sender.creator !== null
                              ? message.sender.creator.profile_image ??
                              "/assets/images/user.png"
                              : message.sender.brand !== null
                                ? message.sender.brand.profile_image ??
                                "/assets/images/user.png"
                                : "/assets/images/user.png"
                          }
                          hours={message?.task_extension_request?.hours}
                        />
                      ) : (
                        <SentMessage
                          user={user}
                          time={
                            message.created_at !== null &&
                              message.created_at !== undefined
                              ? formatTimeToUserTimeZone(message.created_at)
                              : ""
                          }
                          userImage={
                            message.sender.creator !== null
                              ? message.sender.creator.profile_image ??
                              "/assets/images/user.png"
                              : message.sender.brand !== null
                                ? message.sender.brand.profile_image ??
                                "/assets/images/user.png"
                                : "/assets/images/user.png"
                          }
                          message={message.message}
                          fileName={message.original_name}
                          file={message.file}
                          type={message.type}
                        />
                      )
                    ) : (
                      <SentMessage
                        time={
                          message.created_at !== null &&
                            message.created_at !== undefined
                            ? formatTimeToUserTimeZone(message.created_at)
                            : ""
                        }
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                        message={message.message}
                        fileName={message.original_name}
                        file={message.file}
                        type={message.type}
                      />
                    )
                  ) : message.message === "Proof Rejected" ||
                    message.message === "Proof Rejected Again" ? (
                    <ProofRejected
                      time={formatTimeToUserTimeZone(message?.created_at)}
                      userImage={
                        message.sender.creator !== null
                          ? message.sender.creator.profile_image ??
                          "/assets/images/user.png"
                          : message.sender.brand !== null
                            ? message.sender.brand.profile_image ??
                            "/assets/images/user.png"
                            : "/assets/images/user.png"
                      }
                      message={
                        message.message === "Proof Rejected Again"
                          ? message?.task_submission
                            ?.again_link_rejection_reason
                          : message?.task_submission?.link_rejection_reason
                      }
                    />
                  ) : message.message ===
                    "Work Submission Request Received Again" ||
                    message.message === "Work Submission Request received" ? (
                    <WorkSubmitted
                      role={user?.role}
                      fileSubmission_id={message?.file_submission_id}
                      link={
                        message.task_submission !== null
                          ? message?.task_submission?.file_submissions
                          : []
                      }
                      linkSubmitted={false}
                      time={formatTimeToUserTimeZone(message?.created_at)}
                      userImage={
                        message.sender.creator !== null
                          ? message.sender.creator.profile_image ??
                          "/assets/images/user.png"
                          : message.sender.brand !== null
                            ? message.sender.brand.profile_image ??
                            "/assets/images/user.png"
                            : "/assets/images/user.png"
                      }
                    />
                  ) : message.message === "Invite request sent" ||
                    message.message === "Invite request received" ? (
                    <ShowOffer
                      time={
                        message.created_at !== null &&
                          message.created_at !== undefined
                          ? formatTimeToUserTimeZone(message.created_at)
                          : ""
                      }
                      userImage={
                        message.sender.creator !== null
                          ? message.sender.creator.profile_image ??
                          "/assets/images/user.png"
                          : message.sender.brand !== null
                            ? message.sender.brand.profile_image ??
                            "/assets/images/user.png"
                            : "/assets/images/user.png"
                      }
                      isReceiver={
                        message?.offer?.received_by == user?.id ? true : false
                      }
                      offer={message.offer}
                    />
                  ) : message.message === "Review added" ||
                    message.message === "Review Received" ? (
                    // review receiver
                    <ReviewGiven
                      isSender={message?.sender?.id === user?.id ? true : false}
                      comment={
                        message?.collaboration?.collaboration_reviews?.find(
                          (review) => review.collaborator_id == user?.id
                        )?.comment
                      }
                      rating={
                        message?.collaboration?.collaboration_reviews?.find(
                          (review) => review.collaborator_id == user?.id
                        )?.rating
                      }
                      time={formatTimeToUserTimeZone(message?.created_at)}
                      userImage={
                        message.sender.creator !== null
                          ? message.sender.creator.profile_image ??
                          "/assets/images/user.png"
                          : message.sender.brand !== null
                            ? message.sender.brand.profile_image ??
                            "/assets/images/user.png"
                            : "/assets/images/user.png"
                      }
                    />
                  ) : message.message === "Appeal Request" ? (
                    <AppealInitiated
                      isSender={message?.sender?.id === user?.id ? 1 : 0}
                      time={formatTimeToUserTimeZone(message?.created_at)}
                      userImage={
                        message.sender.creator !== null
                          ? message.sender.creator.profile_image ??
                          "/assets/images/user.png"
                          : message.sender.brand !== null
                            ? message.sender.brand.profile_image ??
                            "/assets/images/user.png"
                            : "/assets/images/user.png"
                      }
                    />
                  ) : message.message === "Link Submitted" ||
                    message.message === "Link Submitted Again" ? (
                    <WorkSubmitted
                      role={user?.role}
                      link={
                        message.task_submission !== null
                          ? message?.task_submission
                          : null
                      }
                      contentLink={
                        message.message === "Link Submitted Again"
                          ? message.task_submission?.again_content_link
                          : message.task_submission?.content_link
                      }
                      screenShot={
                        message.message === "Link Submitted Again"
                          ? message.task_submission?.again_screenshot
                          : message.task_submission?.screenshot
                      }
                      linkSubmitted={true}
                      time={formatTimeToUserTimeZone(message?.created_at)}
                      userImage={
                        message.sender.creator !== null
                          ? message.sender.creator.profile_image ??
                          "/assets/images/user.png"
                          : message.sender.brand !== null
                            ? message.sender.brand.profile_image ??
                            "/assets/images/user.png"
                            : "/assets/images/user.png"
                      }
                    />
                  ) : message?.message !== null &&
                    message?.message !== undefined ? (
                    message.message.includes("Customized offer created") ? (
                      <ReceivedCustomOffer
                        time={
                          message.created_at !== null &&
                            message.created_at !== undefined
                            ? formatTimeToUserTimeZone(message.created_at)
                            : ""
                        }
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                        message={message.message}
                        file={message.file}
                        type={message.type}
                        offer={message.offer}
                      />
                    ) : message?.message?.includes(
                      "Extension request received"
                    ) ? (
                      <ExtensionMessageReceived
                        time={
                          message.created_at !== null &&
                            message.created_at !== undefined
                            ? formatTimeToUserTimeZone(message.created_at)
                            : ""
                        }
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                        hours={message?.task_extension_request?.hours}
                      />
                    ) : message?.message?.includes(
                      "Work Change Request Received"
                    ) ? (
                      <AdjustmentRequested
                        time={
                          message.created_at !== null &&
                            message.created_at !== undefined
                            ? formatTimeToUserTimeZone(message.created_at)
                            : ""
                        }
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                        message={message?.task_submission?.adjustment?.detail}
                      />
                    ) : (
                      <OtherUserMessage
                        user={user}
                        time={formatTimeToUserTimeZone(message?.created_at)}
                        userImage={
                          message.sender.creator !== null
                            ? message.sender.creator.profile_image ??
                            "/assets/images/user.png"
                            : message.sender.brand !== null
                              ? message.sender.brand.profile_image ??
                              "/assets/images/user.png"
                              : "/assets/images/user.png"
                        }
                        message={message.message}
                        fileName={message.original_name}
                        file={message.file}
                        type={message.type}
                      />
                    )
                  ) : (
                    <OtherUserMessage
                      user={user}
                      time={formatTimeToUserTimeZone(message?.created_at)}
                      userImage={
                        message.sender.creator !== null
                          ? message.sender.creator.profile_image ??
                          "/assets/images/user.png"
                          : message.sender.brand !== null
                            ? message.sender.brand.profile_image ??
                            "/assets/images/user.png"
                            : "/assets/images/user.png"
                      }
                      message={message.message}
                      fileName={message.original_name}
                      file={message.file}
                      type={message.type}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </>
    );
  };

  //START USE EFFECTs

  useEffect(() => {
    toast.error(submitExtensionError);
  }, [submitExtensionError]);

  useEffect(() => {
    setAdjustmentError(askAdjustmentError);
  }, [askAdjustmentError]);

  useEffect(() => {
    if (isAtTop && currentPage + 1 <= totalMessagePages) paginate();
  }, [isAtTop]);

  const paginate = () => {
    let header = {
      Authorization: `Bearer ${token}`,
    };
    let formData = {};
    formData.chat_id = chat_id;
    dispatch(
      GetMessagesListPaginatedApi({
        header,
        formData,
        dispatch,
        chat_id,
        currentPage,
      })
    ); //
  };
  const handleScroll = () => {
    const scrollTop = divRef.current.scrollTop;
    if (scrollTop === 0) {
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
    }
  };

  useEffect(() => {
    if (messageList?.length) {
      let element = document.getElementById("creator-chat-box-scroll");
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }
  }, [messageList]);

  useEffect(() => {
    if (
      user?.role === "2" &&
      (deliveryTimeBar ||
        reportIssueTime ||
        workReceivedAgain ||
        reviewIsReceived ||
        reviewIsGiven ||
        brandFavourDecision ||
        workAccepted ||
        proofAccepted ||
        (bothHaveGivenReviews && user?.role === "2") ||
        extensionRequestReceived)
    ) {
      const countdownDays = document.getElementById("days");
      const countdownHours = document.getElementById("hours");
      const countdownMinutes = document.getElementById("minutes");
      const countdownSeconds = document.getElementById("seconds");

      if (
        !countdownDays ||
        !countdownHours ||
        !countdownMinutes ||
        !countdownSeconds
      ) {
        return;
      }

      const startTime = Date.now();
      const endTime = new Date(
        proofAccepted ||
          reviewIsGiven ||
          reviewIsReceived ||
          bothHaveGivenReviews
          ? reportIssueTime
          : offer?.delivery_time
      );

      let remainingTime = endTime - startTime;

      const updateCountdown = () => {
        remainingTime -= 1000;

        if (remainingTime <= 0) {
          clearInterval(countdownInterval);
          // Handle when time's up
        } else {
          let days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
          let hours = Math.floor(
            (remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
          );
          let minutes = Math.floor(
            (remainingTime % (60 * 60 * 1000)) / (60 * 1000)
          );
          let seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

          countdownDays.innerHTML = days;
          countdownHours.innerHTML = hours;
          countdownMinutes.innerHTML = minutes;
          countdownSeconds.innerHTML = seconds;
        }
      };

      const countdownInterval = setInterval(updateCountdown, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [
    user,
    deliveryTimeBar,
    reportIssueTime,
    workReceivedAgain,
    reviewIsGiven,
    reviewIsReceived,
    brandFavourDecision,
    workAccepted,
    proofAccepted,
    bothHaveGivenReviews,
    extensionRequestReceived,
    offer?.delivery_time,
  ]);

  useEffect(() => {
    if (user?.role === "1" && deliveryTimeBar) {
      const countdownDays = document.getElementById("days");
      const countdownHours = document.getElementById("hours");
      const countdownMinutes = document.getElementById("minutes");
      const countdownSeconds = document.getElementById("seconds");
      if (
        !countdownDays ||
        !countdownHours ||
        !countdownMinutes ||
        !countdownSeconds
      ) {
        return;
      }
      const startTime = Date.now();
      const endTime = new Date(offer?.delivery_time);

      let remainingTime = endTime - startTime;

      const updateCountdown = () => {
        remainingTime -= 1000;

        if (remainingTime <= 0) {
          clearInterval(countdownInterval);
          // Handle when time's up
        } else {
          let days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
          let hours = Math.floor(
            (remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
          );
          let minutes = Math.floor(
            (remainingTime % (60 * 60 * 1000)) / (60 * 1000)
          );
          let seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

          countdownDays.innerHTML = days;
          countdownHours.innerHTML = hours;
          countdownMinutes.innerHTML = minutes;
          countdownSeconds.innerHTML = seconds;
        }
      };

      const countdownInterval = setInterval(updateCountdown, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [user, deliveryTimeBar, offer?.delivery_time]);

  useEffect(() => {
    getChatListFromDb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  useEffect(() => {
    if (filesContent.length > 0) {
      setFieldPreview(filesContent[0]?.name);
      setFilesToBeSent(filesContent[0]?.content);
    }
  }, [filesContent]);

  return (
    <>
      <AppLayout>
        <div className="categories-dashboard px-5">
          <div className="container container-animation">
            <img
              className="purple-circle mt-5 pt-5"
              src="/assets/images/circle_top1.png"
              alt="circle_top1"
            />
            <img
              className="yellow-circle mt-5 pt-5"
              src="/assets/images/circle-03.png"
              alt="circle_top1"
            />
            <div className="pt-3">
              <div className="w-100 searchbar-right-container d-flex justify-content-between align-items-center">
                <h3 className="mx-2">
                  {page === "Requests-Sent"
                    ? "Requests Sent"
                    : page === "Requests-Received"
                      ? "Chat Requests"
                      : page === "Inbox"
                        ? "Inbox"
                        : "Inbox"}
                </h3>

                <div className="d-flex justify-content-between pe-5">
                  <p
                    onClick={() => {
                      setPage("Requests-Sent");
                    }}
                    className={`my-auto ${page === "Requests-Sent"
                      ? "active-link-style"
                      : "link-style"
                      }`}
                  >
                    Requests Sent
                  </p>

                  <p
                    onClick={() => {
                      setPage("Requests-Received");
                    }}
                    className={`my-auto position-relative ms-5 ${page === "Requests-Received"
                      ? "active-link-style"
                      : "link-style"
                      }`}
                  >
                    Chat Requests
                    {pendingRequestCount > 0 ? (
                      <>
                        <span>
                          <span className="pending-requests-tag">
                            <span>{pendingRequestCount}</span>
                          </span>
                        </span>
                      </>
                    ) : null}
                  </p>
                  <p
                    onClick={() => {
                      setPage("Inbox");
                    }}
                    className={`my-auto mx-5 pointer-cursor ${page === "Inbox" ? "active-link-style" : "link-style"
                      }`}
                  >
                    Inbox
                  </p>

                  <div className="inbox-setting pointer-cursor">
                    <div className="filter-dropdown-box" onClick={dropdownOpen}>
                      <img
                        src="/assets/images/settingsIcon-new.svg"
                        alt="setting"
                      />
                      <div
                        className="inner-dropdown mt-5"
                        onClick={dropdownBox}
                      >
                        <div className=" options-section pb-2">
                          {dropdownItems.map((item, index) => (
                            <div className="d-flex" key={index}>
                              <input
                                type="checkbox"
                                name={index}
                                checked={selectedValues.includes(item)}
                                onChange={() => handleCheckboxChange(item)}
                                className="dropdown-checkbox"
                              />
                              <p className="mb-0 ms-3">{item}</p>
                            </div>
                          ))}
                        </div>
                        <div
                          onClick={() => {
                            handleAllRead();
                          }}
                          className="d-flex mt-2"
                        >
                          <img
                            src="/assets/images/double-check-icon.svg"
                            alt="tick"
                            width={20}
                          />
                          <p className="m-0 ms-2 mark-text">Mark all as read</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fixed-secondary-bar my-5">
                <div className="row p-2">
                  <div className="col-md-4">
                    <div className="search-bar-input-search input-search w-100">
                      <div className="d-flex align-items-center position-relative search-bar--input-container -tags">
                        <div
                          className="search-tag-position"
                          onClick={() => {
                            if (search.length) {
                              searchChats();
                            }
                          }}
                        >
                          <FullSearchIcon
                            color={!search.length ? "#93BFC0" : null}
                            width="133px"
                          />
                        </div>
                        <span className="d-flex align-items-center w-100">
                          <div
                            className="ant-select ant-select-auto-complete search-bar--input css-10evijz ant-select-single ant-select-show-search w-100"
                            autoComplete="off"
                          >
                            <div className="ant-select-selector w-100">
                              <span className="ant-select-selection-search w-100">
                                <input
                                  type="text"
                                  className="ant-select-selection-search-input w-100"
                                  id="search"
                                  value={search}
                                  placeholder="Search by Profile"
                                  role="combobox"
                                  aria-haspopup="listbox"
                                  aria-owns="rc_select_8_list"
                                  aria-autocomplete="list"
                                  aria-controls="rc_select_8_list"
                                  aria-activedescendant="rc_select_8_list_-1"
                                  aria-expanded="false"
                                  onChange={(event) =>
                                    setSearch(event.target.value)
                                  }
                                  onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                      event.preventDefault();
                                      if (search !== "") {
                                        searchChats();
                                      }
                                    } else if (event.key === "Backspace") {
                                      if (search === "") {
                                        event.preventDefault();
                                        getChatListFromDb();
                                      }
                                    } else if (
                                      event.ctrlKey &&
                                      event.key === "x"
                                    ) {
                                      if (
                                        event.target.selectionStart === 0 &&
                                        event.target.selectionEnd ===
                                        search.length
                                      ) {
                                        // If text is selected and then deleted (Ctrl + X)
                                        event.preventDefault();
                                        setSearch("");
                                        getChatListFromDb();
                                      }
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                  {otherUser && page === "Inbox" ? (
                    <>
                      <div className="col-md-4">
                        <div className="d-flex">
                          <img
                            src={
                              otherUser?.creator !== null
                                ? otherUser?.creator.profile_image ??
                                "assets/images/404_circle.png"
                                : otherUser?.brand !== null
                                  ? otherUser?.brand.profile_image ??
                                  "assets/images/404_circle.png"
                                  : "assets/images/404_circle.png"
                            }
                            alt="avtar"
                            className="mx-3 profile-image-sm"
                          />
                          <p
                            onClick={() => handleNavigateToUserDetails()}
                            className="my-auto link-style"
                          >
                            {otherUser?.creator !== null
                              ? otherUser?.creator.profile_name
                              : otherUser?.brand !== null
                                ? otherUser?.brand.brand_name
                                : "otherUser"}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {page === "Requests-Received" ? (
                <>
                  {/* CHAT REQUEST BOX START  */}
                  <ChatRequest page={"Requests-Received"} />
                  {/* CHAT REQUEST BOX END  */}
                </>
              ) : page === "Requests-Sent" ? (
                <>
                  <ChatRequest page={page} />
                </>
              ) : (
                <>
                  {/* CHAT BOX START  */}
                  <div className="chats-container d-flex mt-5 mb-5">
                    {showSidebarChats()}
                    <div className="single-chat-section section-modal">
                      <div
                        className={` ${(deliveryTimeBar && user?.role === "1") ||
                          // approvalBtns ||
                          workSent ||
                          acceptOffer ||
                          workAccept ||
                          (bothHaveGivenReviews && user?.role === "2") ||
                          // waitingDecisionFromVitrine ||
                          // acceptCustomOffer ||
                          customOfferReceived ||
                          (acceptOffer &&
                            user?.role === "1" &&
                            offer?.received_by == user?.id) ||
                          (inviteRequestAccepted &&
                            offer?.received_by === user?.id) ||
                          (acceptOffer &&
                            user?.role === "2" &&
                            offer?.received_by == user?.id) ||
                          packBought ||
                          changeRequestReceived ||
                          extensionRequestReceived ||
                          inviteRequestAccepted ||
                          workReceived ||
                          workSubmitSentAgain ||
                          workReceivedAgain
                          ? "creator-chat-box-scroll-btns-true"
                          : decisionInBrandFav ||
                            decisionInCreatorFav ||
                            decisionInMyFav
                            ? "creator-chat-box-scroll-multi-btns-true"
                            : "creator-chat-box-scroll"
                          }`}
                        ref={divRef}
                        onScroll={handleScroll}
                        id="creator-chat-box-scroll"
                      >
                        <div className="countdown-container">
                          {user?.role === "2" &&
                            (deliveryTimeBar ||
                              workReceivedAgain ||
                              reviewIsGiven ||
                              reviewIsReceived ||
                              brandFavourDecision ||
                              workAccepted ||
                              proofAccepted ||
                              (bothHaveGivenReviews && user?.role === "2") ||
                              extensionRequestReceived) && (
                              <div
                                className={`delivery-time-section ${workReceivedAgain || isLateDelivery
                                  ? "wait-approval-time-color"
                                  : brandFavourDecision
                                    ? "brand-favor-time-bar"
                                    : ""
                                  }`}
                              >
                                <p className="delivery-status">
                                  {workReceivedAgain
                                    ? "Waiting for your approval:"
                                    : reviewIsGiven ||
                                      reviewIsReceived ||
                                      workAccepted ||
                                      proofAccepted ||
                                      (bothHaveGivenReviews &&
                                        user?.role === "2")
                                      ? "Collaboration - Finished - Time to Report an issue"
                                      : brandFavourDecision
                                        ? "Collaboration - Completed"
                                        : isLateDelivery
                                          ? "Late Delivery Time"
                                          : "Delivery Time:"}
                                </p>
                                <div className="countdown">
                                  <div className="countdown-item">
                                    <div
                                      id="days"
                                      className="countdown-value"
                                    ></div>
                                    <div className="countdown-label">Days</div>
                                  </div>
                                  <div className="countdown-item">
                                    <div
                                      id="hours"
                                      className="countdown-value"
                                    ></div>
                                    <div className="countdown-label">Hours</div>
                                  </div>
                                  <div className="countdown-value">:</div>

                                  <div className="countdown-item">
                                    <div
                                      id="minutes"
                                      className="countdown-value"
                                    ></div>
                                    <div className="countdown-label">
                                      Minutes
                                    </div>
                                  </div>
                                  <div className="countdown-value">:</div>

                                  <div className="countdown-item">
                                    <div
                                      id="seconds"
                                      className="countdown-value"
                                    ></div>
                                    <div className="countdown-label">
                                      Seconds
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {user?.role === "1" && deliveryTimeBar && (
                            <div
                              className={`delivery-time-section ${workSent || isLateDelivery
                                ? "wait-approval-time-color"
                                : ""
                                }`}
                            >
                              <p className="delivery-status">
                                {workSent
                                  ? "Waiting Approval"
                                  : isLateDelivery
                                    ? "Late Delivery Time: "
                                    : "Delivery Time:"}
                              </p>
                              <div className="countdown">
                                <div className="countdown-item">
                                  <div
                                    id="days"
                                    className="countdown-value"
                                  ></div>
                                  <div className="countdown-label">Days</div>
                                </div>
                                <div className="countdown-item">
                                  <div
                                    id="hours"
                                    className="countdown-value"
                                  ></div>
                                  <div className="countdown-label">Hours </div>
                                </div>
                                <div className="countdown-value">:</div>
                                <div className="countdown-item">
                                  <div
                                    id="minutes"
                                    className="countdown-value"
                                  ></div>
                                  <div className="countdown-label">Minutes</div>
                                </div>
                                <div className="countdown-value">:</div>

                                <div className="countdown-item">
                                  <div
                                    id="seconds"
                                    className="countdown-value"
                                  ></div>
                                  <div className="countdown-label">Seconds</div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          className={`${deliveryTimeBar ||
                            reviewIsGiven ||
                            proofAccepted ||
                            workReceivedAgain ||
                            extensionRequestReceived ||
                            reviewIsReceived ||
                            (bothHaveGivenReviews && user?.role == "2")
                            ? "mt-5 pt-3"
                            : ""
                            }`}
                        >
                          {messageList.length > 0 ? (
                            loadingMessages ? (
                              <SyncLoader
                                className="d-flex justify-content-center my-5 mx-auto"
                                color="#65acae"
                                size={10}
                              />
                            ) : (
                              showMessages()
                            )
                          ) : (
                            <p className="text-center creator-empty-chat">
                              Tap to open a chat
                            </p>
                          )}
                        </div>

                        {false ? (
                          <AppealInitiated
                            time="10:15pm"
                            imageSource="/assets/images/AVTAR1 (1).png"
                          />
                        ) : null}
                        {/* Modals & Buttons Start */}
                        {/* <<<< ====== THESE ARE REDESIGNED MODALS START======>>>> */}
                        {/* CUSTOM OFFER  */}
                        <div
                          className="modal fade"
                          id="custom-offer-modal"
                          tabIndex={-1}
                          aria-labelledby="custom-offer-modal"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog user-settings-modal">
                            <div className="modal-content custom-offer">
                              <div className="modal-body p-5">
                                <div className="position-relative">
                                  <textarea
                                    maxLength={maxWordCount500}
                                    placeholder="Description"
                                    className={`custom-offer-textarea${descriptionError ? " redOutline" : ""
                                      }`}
                                    name="description"
                                    value={description}
                                    onChange={(event) => {
                                      setDescription(event?.target?.value);
                                    }}
                                  />
                                  <div
                                    className={`view-offer-count-position${descriptionError ? " newPosition" : ""
                                      }`}
                                  >
                                    <p> {remainingWords}/500 Characters</p>
                                  </div>
                                  {descriptionError ? (
                                    <span className="errorText">
                                      {fieldErrors.get("description")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="col-5 mt-4">
                                    <div className=" position-relative">
                                      <input
                                        type="tel"
                                        placeholder="Price"
                                        className={`custom-input${priceError ? " redOutline" : ""
                                          }`}
                                        value={offerPrice}
                                        onChange={(event) => {
                                          const inputValue = event.target.value;
                                          if (/^\d*$/.test(inputValue)) {
                                            setOfferPrice(inputValue);
                                          }
                                        }}
                                      />
                                      <div className="collaboration-proposal-dollar-icon">
                                        <img
                                          src="/assets/images/$.png"
                                          alt="$"
                                        />
                                      </div>
                                      {priceError ? (
                                        <span className="errorText">
                                          {fieldErrors.get("price")}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    Per
                                  </div>
                                  <div className="col-5 mt-4">
                                    <select
                                      className={`custom-input px-3${objectError ? " redOutline" : ""
                                        }`}
                                      value={offerObject}
                                      onChange={(event) => {
                                        setOfferObject(event.target.value);
                                      }}
                                    >
                                      <option value="" disabled>
                                        Select Object
                                      </option>
                                      {objectData.map((object, index) => (
                                        <option key={index} value={object}>
                                          {object}
                                        </option>
                                      ))}
                                    </select>
                                    {objectError ? (
                                      <span className="errorText">
                                        {fieldErrors.get("object")}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-12 position-relative my-4">
                                  <input
                                    maxLength={8}
                                    type="date"
                                    placeholder="Duration"
                                    className={`custom-input pe-3${dateError ? " redOutline m-0" : ""
                                      }`}
                                    min={new Date().toJSON().slice(0, 10)}
                                    value={offerDeliveryTime}
                                    onChange={(event) => {
                                      setOfferDeliveryTime(
                                        event?.target?.value
                                      );
                                    }}
                                  />
                                  {dateError ? (
                                    <span className="errorText">
                                      {fieldErrors.get("delivery_time")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <img
                                    className="popup-yellow-brand-circle position-absolute"
                                    src="assets/images/yellow_circle.png"
                                    alt="circle_3"
                                    width={16}
                                  />
                                </div>
                                <div className="d-flex justify-content-between ps-3">
                                  <div className="action-btns row">
                                    <div
                                      className="common-button-style-outline col-5 align-center"
                                      id="custom-offer-modal-close"
                                      onClick={() => {
                                        setOfferDeliveryTime("");
                                        setDescription("");
                                        setOfferPrice("");
                                        setOfferObject("");
                                        resetErrors();
                                      }}
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      Discard
                                    </div>

                                    <div
                                      className="common-button-style-filled col-5 align-center"
                                      onClick={CreateCustomOffer}
                                    >
                                      Send
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* CUSTOM OFFER  */}
                        {/* REQUEST EXTENSION MODAL START  */}
                        <div
                          className="modal fade"
                          id="request-extension-modal"
                          tabIndex={-1}
                          aria-labelledby="request-extension-modal"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog user-settings-modal edit-campaign">
                            <div className="modal-content mt-5">
                              <div className="modal-body p-5">
                                <img
                                  className="blue-ball-popup"
                                  src="/assets/images/blue_ball.png"
                                  alt="blue-ball"
                                  width={25}
                                />
                                <img
                                  className="popup-yellow-circle"
                                  src="assets/images/yellow_circle.png"
                                  alt="circle_3"
                                  width={16}
                                />
                                <img
                                  className="purple-empty-circle-popup"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle"
                                  width={20}
                                />
                                <img
                                  className="purple-plus-popup"
                                  src="assets/images/purple_plus.png"
                                  alt="plus"
                                  width={16}
                                />
                                <img
                                  className="purple-empty-circle-2-popup position-absolute"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle_2"
                                  width={16}
                                />
                                <div
                                  className=" close-btn-box new-lg-close-position"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    dropdownOpen();
                                    setExtensionTime("");
                                  }}
                                >
                                  <img
                                    src="/assets/images/close-Icon2.svg"
                                    alt="X"
                                  />
                                </div>
                                <p className="m-4 title">
                                  Select the duration in hours to get, you can
                                  get maximum of 72 hours for extension.
                                </p>

                                <div className="mx-auto">
                                  <div
                                    className="dropdown-box dropdown-hours-box-list"
                                    onClick={dropdownOpen}
                                  >
                                    <div className="hours-dropdown-button d-flex">
                                      <p className="m-0 w-100 d-flex align-items-center">
                                        {`${extensionTime} Hours`}
                                      </p>
                                      <div className="align-items-center d-flex">
                                        <img
                                          src="/assets/images/arrow-sign.png"
                                          alt=""
                                          width={15}
                                          className=""
                                        />
                                      </div>
                                    </div>
                                    <div className="inner-dropdown hours-inner-dropdown">
                                      <DropdownForList
                                        selectedValue={extensionTime}
                                        data={durationForExtension}
                                        handleClick={handleExtensionDuration}
                                      />
                                    </div>
                                    <div className="ps-3">
                                      {askForExtError ? (
                                        <span className="errorText">
                                          please select a duration
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer mb-5">
                                <div className="row  justify-content-between mx-5">
                                  <div className="col-5">
                                    <div
                                      id="request-extension-modal-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      className="common-button-style-outline align-center fs-6 px-0"
                                      onClick={() => {
                                        dropdownOpen();
                                        setExtensionTime("");
                                      }}
                                    >
                                      Cancel
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div
                                      // data-bs-dismiss="modal"
                                      // aria-label="Close"
                                      onClick={SubmitExtension}
                                      className="common-button-style-filled fs-6 px-0 align-center justify-content-center"
                                    >
                                      {loadingRejectOffer ? (
                                        <PuffLoader
                                          className="justify-content-center"
                                          color="white"
                                          size={22}
                                        />
                                      ) : (
                                        "Submit"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* REQUEST EXTENSION MODAL END  */}
                        {/* Accept Work MODAL  */}
                        <div
                          className="modal fade mt-5 pt-5"
                          id="accept-work-modal"
                          tabIndex={-1}
                          aria-labelledby="accept-work-modal"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog user-settings-modal edit-campaign">
                            <div className="modal-content">
                              <div className="modal-body p-5">
                                <img
                                  className="blue-ball-popup"
                                  src="/assets/images/blue_ball.png"
                                  alt="blue-ball"
                                  width={25}
                                />
                                <img
                                  className="purple-plus-popup"
                                  src="assets/images/purple_plus.png"
                                  alt="plus"
                                  width={16}
                                />

                                <div
                                  className=" close-btn-box new-lg-close-position"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <img
                                    src="/assets/images/close-Icon2.svg"
                                    alt="X"
                                  />
                                </div>
                                {workReceivedAgain ? (
                                  <p className="m-4 title">
                                    Please make sure that you have received
                                    everything you wanted, as you will not be
                                    able to ask for any adjustments or more work
                                    in this collaboration moving forward and
                                    payment will be released to the CREATOR.
                                  </p>
                                ) : (
                                  <p className="m-4 title">
                                    Please make sure that you have received
                                    everything you wanted, as you will not be
                                    able to ask for any adjustments or more work
                                    in this collaboration moving forward and
                                    payment will be released to the CREATOR once
                                    you complete the collaboration.
                                  </p>
                                )}
                              </div>

                              {workReceivedAgain ? (
                                <>
                                  <div className="modal-footer mb-5">
                                    <div className="row justify-content-between mx-5">
                                      <div className="col-6">
                                        <div
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          onClick={() => {
                                            // TODO: Make Changes to the code
                                            // setAllToFalse();
                                            // setWorkAccepted(true);
                                            AcceptSubmittedWork();
                                          }}
                                          className="common-button-style-outline align-center fs-6 px-0"
                                        >
                                          Accept Revised Work
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          onClick={() => {
                                            // TODO: Make Changes to the code
                                            // setAllToFalse();
                                            // setWorkAccepted(true);

                                            AcceptSubmittedWork();
                                          }}
                                          className="common-button-style-filled fs-6 px-0 align-center justify-content-center"
                                        >
                                          Accept First Submission
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="modal-footer mb-5">
                                    <div className="row justify-content-between mx-5">
                                      <div className="col-5">
                                        <div
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          className="common-button-style-outline align-center fs-6 px-0"
                                        >
                                          Cancel
                                        </div>
                                      </div>
                                      <div className="col-5">
                                        <div
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          onClick={() => {
                                            // TODO: Make Changes to the code
                                            // setAllToFalse();
                                            // setWorkAccepted(true);

                                            AcceptSubmittedWork();
                                          }}
                                          className="common-button-style-filled fs-6 px-0 align-center justify-content-center"
                                        >
                                          Accept Work
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <img
                                className="purple-empty-circle-popup"
                                src="assets/images/purple_empty_circle.png"
                                alt="empty_circle"
                                width={20}
                              />
                              <img
                                className="purple-empty-circle-2-popup position-absolute"
                                src="assets/images/purple_empty_circle.png"
                                alt="empty_circle_2"
                                width={16}
                              />
                              <img
                                className="popup-yellow-circle"
                                src="assets/images/yellow_circle.png"
                                alt="circle_3"
                                width={16}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Accept Work MODAL  */}
                        {/* REJECT SUBMISSION MODAL  */}
                        <div
                          className="modal fade"
                          id="reject-submission-modal"
                          tabIndex={-1}
                          aria-labelledby="reject-submission-modal"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog user-settings-modal edit-campaign reject-submission">
                            <div className="modal-content">
                              <div className="modal-body p-5">
                                <img
                                  className="blue-ball-popup"
                                  src="/assets/images/blue_ball.png"
                                  alt="blue-ball"
                                  width={25}
                                />
                                <img
                                  className="popup-yellow-circle"
                                  src="assets/images/yellow_circle.png"
                                  alt="circle_3"
                                  width={16}
                                />
                                <img
                                  className="purple-empty-circle-popup"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle"
                                  width={20}
                                />
                                <img
                                  className="purple-plus-popup mt-5"
                                  src="assets/images/purple_plus.png"
                                  alt="plus"
                                  width={16}
                                />
                                <img
                                  className="purple-empty-circle-2-popup position-absolute"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle_2"
                                  width={16}
                                />
                                <div
                                  onClick={() => {
                                    setDescription("");
                                    setRejectProofError(false);
                                  }}
                                  className=" close-btn-box new-lg-close-position"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  id="close-reject-submission-modal"
                                >
                                  <img
                                    src="/assets/images/close-Icon2.svg"
                                    alt="X"
                                  />
                                </div>
                                <p className="title p-4">
                                  Please state the reason why you want to reject
                                  the submitted link.
                                </p>
                                <div className="">
                                  <textarea
                                    maxLength={maxWordCount500}
                                    placeholder="Please state your reason for rejecting the submitted link...."
                                    className="submit-appeal-textarea"
                                    name="description"
                                    value={description}
                                    onChange={(event) => {
                                      setDescription(event?.target?.value);
                                    }}
                                  />
                                  <div
                                    className={`word-count ${rejectProofError
                                      ? "reject-submission-error-position"
                                      : "reject-submission-position"
                                      }`}
                                  >
                                    <p>{remainingWords}/500 Characters</p>
                                  </div>
                                </div>
                                <div className="ps-3">
                                  {rejectProofError ? (
                                    <span className="errorText">
                                      The reason field is required.
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="modal-footer mb-4">
                                <div className="mx-auto">
                                  <button
                                    onClick={() => {
                                      RejectProofLink();
                                    }}
                                    className="common-button-style-filled fs-6  align-center"
                                  >
                                    {loadingRejectOffer ? (
                                      <PuffLoader
                                        className="justify-content-center"
                                        color="white"
                                        size={22}
                                      />
                                    ) : (
                                      "Reject Submission"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* REJECT SUBMISSION MODAL  */}
                        {/* REPORT MODAL  */}
                        <div
                          className="modal fade"
                          id="report-issue-modal"
                          tabIndex={-1}
                          aria-labelledby="report-issue-modal"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog user-settings-modal edit-campaign">
                            <div className="modal-content reject-submission">
                              <div className="modal-body p-5">
                                <img
                                  className="blue-ball-popup"
                                  src="/assets/images/blue_ball.png"
                                  alt="blue-ball"
                                  width={25}
                                />
                                <img
                                  className="purple-plus-popup mt-5"
                                  src="assets/images/purple_plus.png"
                                  alt="plus"
                                  width={16}
                                />

                                <div
                                  onClick={() => {
                                    setDescription("");
                                    setReportAnIssueError(false);
                                    setSubmittingWork(false);
                                    setFieldPreview("");
                                  }}
                                  className="close-btn-box new-lg-close-position"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  id="close-report-modal"
                                >
                                  <img
                                    src="/assets/images/close-Icon2.svg"
                                    alt="X"
                                  />
                                </div>
                                <p className="title p-4">
                                  Please provide all the details of the issue
                                  you are facing, We will get back to you as
                                  soon as possible!
                                </p>
                                <div className="">
                                  <textarea
                                    maxLength={maxWordCount500}
                                    placeholder="Please state your issue..."
                                    className="submit-appeal-textarea"
                                    name="description"
                                    value={description}
                                    onChange={(event) => {
                                      setDescription(event?.target?.value);
                                    }}
                                  />
                                  <div className="word-count reject-submission-position">
                                    <p> {remainingWords}/500 Characters</p>
                                  </div>
                                  <div className="ps-3">
                                    {reportAnIssueError && !description ? (
                                      <span className="errorText">
                                        The reason field is required.
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="position-relative justify-content-center d-flex">
                                <div
                                  className="btn attachments-button align-center"
                                  onClick={() => {
                                    setSubmittingWork(true);
                                    openFileSelector();
                                  }}
                                >
                                  {fieldPreview
                                    ? fieldPreview
                                    : "Select an Attachment"}
                                </div>
                              </div>
                              <div className="ps-3 ms-5">
                                {reportAnIssueError &&
                                  filesContent.length === 0 ? (
                                  <span className="errorText">
                                    please select an Attachment
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="modal-footer mb-4">
                                <img
                                  className="popup-yellow-circle"
                                  src="assets/images/yellow_circle.png"
                                  alt="circle_3"
                                  width={16}
                                />
                                <img
                                  className="purple-empty-circle-popup"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle"
                                  width={20}
                                />

                                <img
                                  className="purple-empty-circle-2-popup position-absolute"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle_2"
                                  width={16}
                                />
                                <div className="mx-auto">
                                  <button
                                    onClick={() => ReportIssue()}
                                    className="common-button-style-filled fs-6  align-center"
                                  >
                                    {workLoading ? (
                                      <PuffLoader
                                        className="justify-content-center"
                                        color="white"
                                        size={20}
                                      />
                                    ) : (
                                      "Submit"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* REPORT MODAL  */}
                        {/* ACCEPT OFFER MODAL - Brand  */}
                        <div
                          className="modal fade"
                          id="accept-offer-modal"
                          tabIndex={-1}
                          aria-labelledby="accept-offer-modal"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog user-settings-modal edit-campaign">
                            <div className="modal-content accept-offer">
                              <div className="modal-body p-5">
                                <img
                                  className="blue-ball-popup"
                                  src="/assets/images/blue_ball.png"
                                  alt="blue-ball"
                                  width={25}
                                />
                                <img
                                  className="purple-plus-popup"
                                  src="assets/images/purple_plus.png"
                                  alt="plus"
                                  width={16}
                                />

                                <div
                                  onClick={() => resetForm()}
                                  className=" close-btn-box new-lg-close-position"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <img
                                    src="/assets/images/close-Icon2.svg"
                                    alt="X"
                                  />
                                </div>
                                <div className="d-flex my-4">
                                  <input
                                    type="checkbox"
                                    name="Terms1"
                                    checked={acceptance.terms1}
                                    onChange={() =>
                                      setAcceptance({
                                        ...acceptance,
                                        terms1: !acceptance.terms1,
                                      })
                                    }
                                    className="dropdown-checkbox"
                                  />
                                  <p className="mb-0 ms-3">
                                    I accept platform
                                    <span
                                      onClick={() => {
                                        handleViewTerms("conditions");
                                      }}
                                      className="text-decoration-underline text-highlight ms-1 fw-bold"
                                    >
                                      terms and conditions.
                                    </span>
                                  </p>
                                </div>
                                <div className="d-flex mb-4">
                                  <input
                                    type="checkbox"
                                    name="Terms2"
                                    checked={acceptance.terms2}
                                    onChange={() =>
                                      setAcceptance({
                                        ...acceptance,
                                        terms2: !acceptance.terms2,
                                      })
                                    }
                                    className="dropdown-checkbox"
                                  />
                                  <p className="mb-0 ms-3">
                                    I accept
                                    <span
                                      onClick={() =>
                                        handleViewTerms("collaboration")
                                      }
                                      className="text-decoration-underline text-highlight ms-1 fw-bold"
                                    >
                                      terms of collaboration.
                                    </span>
                                  </p>
                                </div>
                                <p className="title">
                                  Note: Please read the platform T&C’s and also
                                  the terms that you have agreed on, in this
                                  collaboration as you won’t be able to change
                                  the collaboration terms after accepting the
                                  offer.
                                </p>
                                <div className="align-center mt-5">
                                  <p className="my-auto cancel-notice">
                                    You won’t be able to cancel once accepted
                                    the offer.
                                  </p>
                                </div>
                              </div>

                              <div className="modal-footer mb-5 px-5">
                                <img
                                  className="popup-yellow-circle"
                                  src="assets/images/yellow_circle.png"
                                  alt="circle_3"
                                  width={16}
                                />
                                <img
                                  className="purple-empty-circle-popup"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle"
                                  width={20}
                                />
                                <img
                                  className="purple-empty-circle-2-popup position-absolute"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle_2"
                                  width={16}
                                />
                                <div className="row  justify-content-between mx-2">
                                  <div className="col-6">
                                    <div
                                      onClick={() => resetForm()}
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      className="common-button-style-outline align-center fs-6 px-0"
                                    >
                                      Discard
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      onClick={() => {
                                        if (isFormValid) {
                                          acceptCreatorOffer();
                                        }
                                      }}
                                      className={`btn ${!isFormValid ? "disabled " : ""
                                        }common-button-style-filled fs-6 px-0 align-center justify-content-center d-flex`}
                                    >
                                      {loadingAcceptOffer ? (
                                        <PuffLoader
                                          className="justify-content-center"
                                          color="white"
                                          size={22}
                                        />
                                      ) : (
                                        "Accept Offer"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ACCEPT OFFER MODAL */}
                        {/* REJECT OFFER MODAL  */}
                        <div
                          className="modal fade mt-5 pt-5"
                          id="reject-offer-modal"
                          tabIndex={-1}
                          aria-labelledby="reject-offer-modal"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog user-settings-modal edit-campaign">
                            <div className="modal-content accept-offer">
                              <div className="modal-body p-5">
                                <img
                                  className="blue-ball-popup"
                                  src="/assets/images/blue_ball.png"
                                  alt="blue-ball"
                                  width={25}
                                />
                                <img
                                  className="purple-plus-popup"
                                  src="assets/images/purple_plus.png"
                                  alt="plus"
                                  width={16}
                                />
                                <div
                                  className=" close-btn-box new-lg-close-position"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <img
                                    src="/assets/images/close-Icon2.svg"
                                    alt="X"
                                  />
                                </div>
                                <p className="my-3 text-center title px-5">
                                  Are you sure if you want to reject the
                                  collaboration, once rejected you won’t be able
                                  to make a custom offer, the CREATOR needs to
                                  send another offer for you.
                                </p>
                              </div>
                              <div className="modal-footer mb-5 px-5">
                                <img
                                  className="purple-empty-circle-popup"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle"
                                  width={20}
                                />
                                <img
                                  className="popup-yellow-circle"
                                  src="assets/images/yellow_circle.png"
                                  alt="circle_3"
                                  width={16}
                                />
                                <img
                                  className="purple-empty-circle-2-popup position-absolute"
                                  src="assets/images/purple_empty_circle.png"
                                  alt="empty_circle_2"
                                  width={16}
                                />
                                <div className="row  justify-content-between mx-2">
                                  <div className="col-6">
                                    <div
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      className="common-button-style-outline align-center fs-6 px-0"
                                    >
                                      Discard
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      onClick={() => {
                                        rejectCreatorOffer();
                                      }}
                                      className="common-button-style-filled fs-6 px-0 align-center justify-content-center"
                                    >
                                      Reject Offer
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* REJECT OFFER MODAL  */}
                        {/* APPEAL MODAL  */}
                        <div
                          className="modal fade"
                          id="appeal-modal"
                          tabIndex={-1}
                          aria-labelledby="appeal-modal"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog user-settings-modal edit-campaign">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div
                                  onClick={() => {
                                    setDescription("");
                                    setSubmitAppealError(false);
                                  }}
                                  className="close-btn-box new-lg-close-position"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <img
                                    src="/assets/images/close-Icon2.svg"
                                    alt="X"
                                  />
                                </div>
                                <div className="pt-5 text-center fs-5">
                                  <p>
                                    Once you initiate an appeal you will not be
                                    able to chat with the creator. Once you
                                    submit this request, our support will handle
                                    the dispute and will check all the chats and
                                    aspects of the collaboration.
                                  </p>
                                </div>
                                <div className="">
                                  <textarea
                                    maxLength={maxWordCount500}
                                    placeholder="Write description here..."
                                    className="submit-appeal-textarea"
                                    name="description"
                                    value={description}
                                    onChange={(event) => {
                                      setDescription(event?.target?.value);
                                    }}
                                  />
                                  <div
                                    className={`word-count ${submitAppealError
                                      ? "appeal-count-error-position"
                                      : "appeal-count-position"
                                      }`}
                                  >
                                    <p> {remainingWords}/500 Characters</p>
                                  </div>
                                </div>
                                <div className="ps-3">
                                  {submitAppealError ? (
                                    <span className="errorText">
                                      The description field is required.
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="row modal-footer mb-4">
                                <div className="col-5">
                                  <div
                                    onClick={() => {
                                      setDescription("");
                                      setSubmitAppealError(false);
                                    }}
                                    id="submit-appeal-modal-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="common-button-style-outline align-center fs-6 px-0"
                                  >
                                    Discard
                                  </div>
                                </div>
                                <div className="col-5">
                                  <div
                                    onClick={SubmitAppeal}
                                    className="common-button-style-filled fs-6 px-0 align-center justify-content-center"
                                  >
                                    {loadingAcceptOffer ? (
                                      <PuffLoader
                                        className="justify-content-center"
                                        color="white"
                                        size={22}
                                      />
                                    ) : (
                                      "Submit Appeal"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* APPEAL MODAL  */}
                        {/* ADJUSTMENT MODAL  */}
                        <div
                          className="modal fade"
                          id="adjustment-modal"
                          tabIndex={-1}
                          aria-labelledby="adjustment-modal"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog user-settings-modal">
                            <div className="modal-content">
                              <div className="modal-body p-5">
                                <div className="">
                                  <textarea
                                    maxLength={maxWordCount500}
                                    placeholder="Please type what change is required..."
                                    className="custom-offer-textarea"
                                    name="description"
                                    value={description}
                                    onChange={(event) => {
                                      setDescription(event?.target?.value);
                                    }}
                                  />
                                  <div
                                    className={`word-count request-count-position${adjustmentError ? " bottom-40" : ""
                                      }`}
                                  >
                                    <p>{remainingWords}/500 Characters</p>
                                  </div>
                                </div>
                                {adjustmentError ? (
                                  <span className="errorText">
                                    {adjustmentError.errors.detail[0]}
                                    {/* {fieldErrors.get("description")} */}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="modal-footer">
                                <div className="row mb-2 mx-4">
                                  <div className="col-5">
                                    <div
                                      onClick={() => {
                                        setDescription("");
                                        setAdjustmentError("");
                                      }}
                                      id="adjustment-modal-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      className="common-button-style-outline align-center fs-6 px-0"
                                    >
                                      Discard
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div
                                      // data-bs-dismiss="modal"
                                      // aria-label="Close"
                                      onClick={() => AskForAdjustment()}
                                      className="common-button-style-filled fs-6 px-0 align-center justify-content-center"
                                    >
                                      {loadingAcceptOffer ? (
                                        <PuffLoader
                                          className="justify-content-center"
                                          color="white"
                                          size={22}
                                        />
                                      ) : (
                                        "Request a change"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ADJUSTMENT MODAL  */}
                        {/* REVIEW MODAL  */}
                        <ReviewModal
                          placeholder={
                            user?.role === "2"
                              ? "How was Your Experience with the Creator?"
                              : "How was Your Experience with the Brand?"
                          }
                          // setWorkAccept={setWorkAccept}
                          maxWordCount={maxWordCount250}
                          handleChange={handleChange}
                          description={description}
                          setDescription={setDescription}
                          remainingWords={remainingWords}
                          rating={rating}
                          handleRatingChange={handleRatingChange}
                          handleAddReview={addReview}
                          reviewError={reviewError}
                          workLoading={workLoading}
                        />
                        {/* REVIEW MODAL  */}
                        {/* SUBMIT WORK MODAL */}
                        <div
                          className="modal fade"
                          id="submit-work-modal"
                          tabIndex={-1}
                          aria-labelledby="submit-work-modal"
                          aria-hidden="true"
                        >
                          {isProceed ? (
                            <>
                              <div className="modal-dialog user-settings-modal edit-campaign">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div className="position-relative justify-content-center d-flex mt-4 mb-4">
                                      <div className="add-files-container align-center ">
                                        <div className="row text-center">
                                          {filesContent.length > 0 ? (
                                            filesContent.map((file) => {
                                              return (
                                                <div>
                                                  <img
                                                    onClick={() => {
                                                      openFileSelector();
                                                    }}
                                                    className="smallImage"
                                                    src={file.content}
                                                    alt="..."
                                                  />
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <>
                                              <div className="col-12">
                                                <UploadIcon
                                                  className="justify-content-center mx-auto"
                                                  color="black"
                                                />
                                                <div className="col-12 my-3 fw-bolder">
                                                  Drag and drop attachments here
                                                  to upload
                                                </div>
                                                <div
                                                  onClick={() => {
                                                    openFileSelector();
                                                  }}
                                                  className="col-12 text-highlight pointer-cursor"
                                                >
                                                  Browse file
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      <div className="position-absolute d-flex flex-column mt-4"></div>
                                    </div>

                                    <div className="title mt-3 px-4">
                                      {submitWorkError ? (
                                        <span className="errorText">
                                          No file selected
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <p className="fs-6">
                                        Please ensure that you attach everything
                                        the Brand have requested inside the
                                        collaboration terms.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <div className="row mb-4 mx-5">
                                      <div className="col-5">
                                        <div
                                          onClick={() => {
                                            setIsProceed(false);
                                            setSubmitWorkError(false);
                                            clear();
                                            setFilesToBeSent("");
                                          }}
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          id="submit-work-modal-close"
                                          className="common-button-style-outline align-center fs-6 px-0"
                                        >
                                          Discard
                                        </div>
                                      </div>
                                      <div className="col-5">
                                        <div
                                          // data-bs-toggle="modal"
                                          // data-bs-target="#submit-work-modal"
                                          onClick={() => {
                                            setSubmitWorkError(false);
                                            SubmitWork();
                                          }}
                                          className="common-button-style-filled fs-6 px-0 align-center justify-content-center"
                                        >
                                          {workLoading ? (
                                            <PuffLoader
                                              className="justify-content-center"
                                              color="white"
                                              size={22}
                                            />
                                          ) : (
                                            "Submit"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="modal-dialog user-settings-modal edit-campaign mt-5 pt-5">
                                <div className="modal-content mt-5">
                                  <div className="modal-body p-5">
                                    <img
                                      className="blue-ball-popup"
                                      src="/assets/images/blue_ball.png"
                                      alt="blue-ball"
                                      width={25}
                                    />
                                    <img
                                      className="purple-plus-popup mt-5"
                                      src="assets/images/purple_plus.png"
                                      alt="plus"
                                      width={16}
                                    />

                                    <div
                                      className=" close-btn-box new-lg-close-position"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <img
                                        src="/assets/images/close-Icon2.svg"
                                        alt="X"
                                      />
                                    </div>
                                    <p className="my-3 title px-5">
                                      Are you sure that everything requested by
                                      the Brand is completed? If so please
                                      proceed.
                                    </p>
                                  </div>
                                  <div className="modal-footer">
                                    <img
                                      className="popup-yellow-circle"
                                      src="assets/images/yellow_circle.png"
                                      alt="circle_3"
                                      width={16}
                                    />
                                    <img
                                      className="purple-empty-circle-popup"
                                      src="assets/images/purple_empty_circle.png"
                                      alt="empty_circle"
                                      width={20}
                                    />

                                    <img
                                      className="purple-empty-circle-2-popup position-absolute"
                                      src="assets/images/purple_empty_circle.png"
                                      alt="empty_circle_2"
                                      width={16}
                                    />
                                    <div className="row mb-4 mx-5">
                                      <div className="col-5">
                                        <div
                                          onClick={() => {
                                            setDescription("");
                                          }}
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          className="common-button-style-outline align-center fs-6 px-0"
                                        >
                                          Discard
                                        </div>
                                      </div>
                                      <div className="col-5">
                                        <div
                                          onClick={() => {
                                            setIsProceed(true);
                                            setSubmittingWork(true);
                                          }}
                                          className="common-button-style-filled fs-6 px-0 align-center justify-content-center"
                                        >
                                          Proceed
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {/* SUBMIT WORK MODAL  */}
                        {/* INFLUENCER LINK MMODAL  */}
                        <InfluencerLinkModal
                          setFilesToBeSent={setFilesToBeSent}
                          setFieldPreview={setFieldPreview}
                          fieldPreview={fieldPreview}
                          contentLink={contentLink}
                          setContentLink={setContentLink}
                          selectAttachment={() => {
                            setSubmittingWork(true);
                            openFileSelector();
                          }}
                          submitLink={SubmitProofLink}
                          errors={submitLinkError}
                          workLoading={workLoading}
                        />
                        {/* INFLUENCER LINK MMODAL  */}
                        {/* <<<< ====== THESE ARE REDESIGNED MODALS End ======>>>> */}

                        {/*  BUTTONS START */}

                        {workReceived ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                className="whiteButton otherButton d-flex justify-content-center"
                                data-bs-toggle="modal"
                                data-bs-target="#adjustment-modal"
                                onClick={() => {
                                  setDescription("");
                                  setAdjustmentError("");
                                }}
                              >
                                <p className="pt-1">Ask for Adjustment</p>
                              </button>

                              <button
                                className="greenButton submitButton d-flex justify-content-center"
                                data-bs-toggle="modal"
                                data-bs-target="#accept-work-modal"
                              // onClick={() => AcceptSubmittedWork()}
                              >
                                {workLoading ? (
                                  <PuffLoader
                                    className="justify-content-center"
                                    color="white"
                                    size={22}
                                  />
                                ) : (
                                  "Accept Work"
                                )}
                              </button>
                            </div>
                          </>
                        ) : null}
                        {extensionRequestReceived ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                className="whiteButton otherButton d-flex justify-content-center"
                                // data-bs-toggle="modal"
                                // data-bs-target="#adjustment-modal"
                                onClick={RejectExtension}
                              >
                                {loadingRejectOffer ? (
                                  <PuffLoader
                                    className="justify-content-center"
                                    color="#65acae"
                                    size={22}
                                  />
                                ) : (
                                  <p className="pt-1">Reject Extension</p>
                                )}
                              </button>

                              <button
                                className="greenButton submitButton d-flex justify-content-center"
                                // data-bs-toggle="modal"
                                // data-bs-target="#accept-work-modal"
                                onClick={AcceptExtension}
                              >
                                {loadingAcceptOffer ? (
                                  <PuffLoader
                                    className="justify-content-center"
                                    color="white"
                                    size={22}
                                  />
                                ) : (
                                  "Accept Extension"
                                )}
                              </button>
                            </div>
                          </>
                        ) : null}

                        {(decisionInMyFav && user?.role == "2") ||
                          decisionInBrandFav ? (
                          <>
                            <div className="mt-3">
                              <div className="d-flex buttons-display justify-content-between">
                                <button
                                  className={`w-100 d-flex justify-content-center ${user?.role == "1"
                                    ? "lightRedButton"
                                    : "lightGreenButton"
                                    }`}
                                  style={{ marginBottom: "110px" }}
                                >
                                  Decision was made in favor of the BRAND
                                </button>
                              </div>
                              <div className="d-flex buttons-display justify-content-between">
                                <button
                                  className="lightYellowButton w-100 d-flex justify-content-center"
                                  style={{ marginBottom: "50px" }}
                                >
                                  Payment is refunded to the BRAND
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (decisionInMyFav && user?.role == "1") ||
                          decisionInCreatorFav ? (
                          <>
                            <div className="mt-3">
                              <div className="d-flex buttons-display justify-content-between">
                                <button
                                  className={`w-100 d-flex justify-content-center ${user?.role == "2"
                                    ? "lightRedButton"
                                    : "lightGreenButton"
                                    }`}
                                  style={{ marginBottom: "110px" }}
                                >
                                  Decision was made in favor of the CREATOR
                                </button>
                              </div>
                              <div className="d-flex buttons-display justify-content-between">
                                <button
                                  className="lightGreenButton w-100 d-flex justify-content-center"
                                  style={{ marginBottom: "50px" }}
                                >
                                  Payment will be released to the CREATOR in
                                  next 24 hours...
                                </button>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {acceptOffer &&
                          user?.role === "1" &&
                          offer?.received_by == user?.id ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between d-flex">
                              <button
                                className="whiteButton otherButton justify-content-center d-flex"
                                // data-bs-toggle="modal"
                                // data-bs-target="#appeal-modal"
                                onClick={rejectCreatorOffer}
                              >
                                {loadingRejectOffer ? (
                                  <PuffLoader
                                    className="justify-content-center"
                                    color="#65acae"
                                    size={22}
                                  />
                                ) : (
                                  <p className="pt-1"> Reject Offer</p>
                                )}
                              </button>
                              <button
                                className="greenButton submitButton justify-content-center d-flex"
                                // data-bs-toggle="modal"
                                // data-bs-target="#submit-work-modal"
                                onClick={acceptCreatorOffer}
                              >
                                {loadingAcceptOffer ? (
                                  <PuffLoader
                                    className="justify-content-center"
                                    color="white"
                                    size={22}
                                  />
                                ) : (
                                  "Accept Offer"
                                )}
                              </button>
                            </div>
                          </>
                        ) : null}
                        {
                          // acceptCustomOffer ||
                          customOfferReceived ? (
                            <>
                              <div className="d-flex buttons-display justify-content-between d-flex">
                                <button
                                  className="whiteButton otherButton justify-content-center d-flex"
                                  onClick={rejectCreatorOffer}
                                >
                                  {loadingRejectOffer ? (
                                    <PuffLoader
                                      className="justify-content-center"
                                      color="#65acae"
                                      size={22}
                                    />
                                  ) : (
                                    <p className="pt-1"> Reject Custom Offer</p>
                                  )}
                                </button>

                                <button
                                  className="greenButton submitButton justify-content-center d-flex"
                                  onClick={acceptCreatorOffer}
                                >
                                  {loadingAcceptOffer ? (
                                    <PuffLoader
                                      className="justify-content-center"
                                      color="white"
                                      size={22}
                                    />
                                  ) : (
                                    "Accept Custom Offer"
                                  )}
                                </button>
                              </div>
                            </>
                          ) : null
                        }
                        {acceptOffer &&
                          user?.role === "2" &&
                          offer?.received_by == user?.id ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                onClick={() => resetErrors()}
                                className="btn btn-sm insideButtons linkStyleButton"
                                data-bs-toggle="modal"
                                data-bs-target="#custom-offer-modal"
                              >
                                Create Customized Offer
                              </button>
                              <button
                                className="btn btn-sm insideButtons whiteButton justify-content-center d-flex"
                                // data-bs-toggle="modal"
                                // data-bs-target="#reject-offer-modal"
                                onClick={rejectCreatorOffer}
                              >
                                {loadingAcceptOffer ? (
                                  <PuffLoader
                                    className="justify-content-center"
                                    color="#65acae"
                                    size={22}
                                  />
                                ) : (
                                  <p className="mt-1">Reject Offer</p>
                                )}
                              </button>
                              {offer?.payment_transaction === null ||
                                (offer?.payment_transaction !== null &&
                                  offer?.payment_transaction?.is_refunded ===
                                  "1") ? (
                                <button
                                  onClick={() => {
                                    navigate("/stripe-payment", {
                                      state: {
                                        offer_id: offer?.id,
                                        payment_type:
                                          "campaign_collaboration_offer",
                                      },
                                    });
                                  }}
                                  className="btn btn-sm insideButtons greenButton mx-2 justify-content-center d-flex"
                                >
                                  {loadingBrandPayment ? (
                                    <PuffLoader
                                      className="justify-content-center"
                                      color="white"
                                      size={22}
                                    />
                                  ) : (
                                    <p className="mt-1">Pay to Accept Offer</p>
                                  )}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm insideButtons greenButton mx-2 justify-content-center d-flex"
                                  data-bs-toggle="modal"
                                  data-bs-target="#accept-offer-modal"
                                >
                                  {loadingAcceptOffer ? (
                                    <PuffLoader
                                      className="justify-content-center"
                                      color="white"
                                      size={22}
                                    />
                                  ) : (
                                    "Accept Offer"
                                  )}
                                </button>
                              )}
                            </div>
                          </>
                        ) : null}
                        {user?.role === "2" &&
                          inviteRequestAccepted &&
                          offer?.payment_transaction === null ? (
                          <div className="d-flex buttons-display justify-content-between">
                            <button
                              className="whiteButton otherButton w-100"
                              onClick={() => {
                                navigate("/stripe-payment", {
                                  state: {
                                    offer_id: offer?.id,
                                    payment_type: "invite_collaboration_offer",
                                  },
                                });
                              }}
                            >
                              Make Payment to Proceed
                            </button>
                          </div>
                        ) : null}
                        {user?.role === "1" &&
                          inviteRequestAccepted &&
                          offer?.payment_transaction === null ? (
                          <div className="d-flex buttons-display justify-content-between">
                            <button
                              className="whiteButton otherButton w-100"
                              disabled={true}
                            >
                              Waiting for the Brand to make Payment
                            </button>
                          </div>
                        ) : null}
                        {user?.role === "1" && deliveryTimeBar ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                className="whiteButton otherButton d-flex justify-content-center"
                                data-bs-toggle="modal"
                                data-bs-target="#request-extension-modal"
                                onClick={() => {
                                  setAskForExtError(false);
                                }}
                              >
                                <p className="pt-1">Ask for Extension</p>
                              </button>
                              <button
                                className="greenButton submitButton d-flex justify-content-center"
                                data-bs-toggle="modal"
                                data-bs-target="#submit-work-modal"
                                onClick={() => {
                                  setSubmitWorkError(false);
                                }}
                              >
                                Submit Work
                              </button>
                            </div>
                          </>
                        ) : null}
                        {appealRequest ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button className="lightRedButton w-100 normalText">
                                Waiting for a decision from Vitrine!
                              </button>
                            </div>
                          </>
                        ) : null}
                        {decisionInBrandFav ||
                          paymentProcessed ||
                          decisionInCreatorFav ||
                          decisionInMyFav ||
                          bothHaveGivenReviews ||
                          (reviewIsGiven && user?.role == "1") ? (
                          <>
                            <div className="d-flex buttons-display justify-content-center">
                              <button
                                className={`greenButton submitButton disabledButton ${user?.role == "2" ? "w-100" : ""
                                  }`}
                              >
                                Collaboration Closed
                              </button>
                            </div>
                          </>
                        ) : null}
                        {workSent || workSubmitSentAgain ? (
                          <>
                            <div className="d-flex buttons-display justify-content-center">
                              <button
                                // onClick={() => setSubmitFull(true)}
                                className="greenButton submitButton disabledButton"
                              // data-bs-toggle="modal"
                              // data-bs-target="#submit-work-modal"
                              >
                                Waiting for Approval
                              </button>
                            </div>
                          </>
                        ) : null}
                        {workAccept ? (
                          <>
                            <div className="d-flex buttons-display justify-content-center">
                              <button
                                onClick={() => {
                                  setFilesToBeSent("");
                                  setFieldPreview("");
                                }}
                                className="greenButton submitInfluencerLink"
                                data-bs-toggle="modal"
                                data-bs-target="#influencer-link-modal"
                              >
                                Send Influencer Link
                              </button>
                            </div>
                          </>
                        ) : null}
                        {(proofAccepted && user?.role === "1") ||
                          (reviewIsReceived &&
                            user?.role === "1" &&
                            !bothHaveGivenReviews) ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                onClick={() => {
                                  setDescription("");
                                  setReviewError(false);
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#review-modal"
                                className="lightGreenButton w-100 d-flex justify-content-center"
                              >
                                <>
                                  {reviewIsReceived ? (
                                    <>
                                      Review Received -
                                      <span className="underLineText">
                                        Tap to Leave a Review
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      Influencer Link Accepted -
                                      <span className="underLineText">
                                        Leave a Review
                                      </span>
                                    </>
                                  )}
                                </>
                              </button>
                            </div>
                          </>
                        ) : null}

                        {(proofAccepted && user?.role === "2") ||
                          (reviewIsReceived &&
                            user?.role === "2" &&
                            !bothHaveGivenReviews) ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                onClick={() => {
                                  setDescription("");
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#report-issue-modal"
                                className="whiteButton reportIssueButton"
                              >
                                Report an issue!
                              </button>
                              <button
                                onClick={() => {
                                  setDescription("");
                                  setReviewError(false);
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#review-modal"
                                className="greenButton leaveReviewButton d-flex justify-content-center"
                              >
                                Tap to leave a Review
                              </button>
                            </div>
                          </>
                        ) : null}

                        {(reviewIsGiven && user?.role == "2") ||
                          (bothHaveGivenReviews && user?.role == "2") ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                onClick={() => {
                                  setDescription("");
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#report-issue-modal"
                                className={`whiteButton reportIssueButton w-100 d-flex justify-content-center ${bothHaveGivenReviews && user?.role == "2"
                                  ? "mb-5"
                                  : ""
                                  }`}
                              >
                                <p className="pt-1">Report an issue!</p>
                              </button>
                            </div>
                          </>
                        ) : null}

                        {proofReceived || proofReceivedAgain ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                onClick={() => { }}
                                data-bs-toggle="modal"
                                data-bs-target="#reject-submission-modal"
                                className="whiteButton otherButton d-flex justify-content-center"
                              >
                                <p className="pt-1">Reject Submission</p>
                              </button>

                              <button
                                onClick={() => {
                                  AcceptProofLink();
                                }}
                                className="greenButton submitButton d-flex justify-content-center"
                              >
                                {workLoading ? (
                                  <PuffLoader
                                    className="justify-content-center"
                                    color="white"
                                    size={22}
                                  />
                                ) : (
                                  "Accept Submission"
                                )}
                              </button>
                            </div>
                          </>
                        ) : null}

                        {issueReported ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button className="lightRedButton w-100 normalText">
                                Waiting for a decision from Vitrine!
                              </button>
                            </div>
                          </>
                        ) : null}
                        {proofRejected && user?.role === "1" ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#influencer-link-modal"
                                // onClick={() => {
                                //   setLinkRejected(true);
                                //   setLinkAccepted(false);
                                // }}
                                className="lightYellowButton normalButtonText w-100"
                              >
                                Influencer Link Rejected - Submit Again
                              </button>
                            </div>
                          </>
                        ) : null}
                        {proofSent ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button className="lightYellowButton normalButtonText w-100">
                                Waiting for Approval from Brand
                              </button>
                            </div>
                          </>
                        ) : null}

                        {(acceptOffer || customOfferSent) &&
                          user?.role == "2" &&
                          offer?.sent_by == user?.id ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button className="lightGreenButton normalButtonText w-100">
                                Waiting for Creator to Accept the Offer
                              </button>
                            </div>
                          </>
                        ) : null}

                        {acceptOffer &&
                          user?.role == "1" &&
                          offer?.sent_by == user?.id ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button className="lightGreenButton normalButtonText w-100">
                                Waiting for Brand to Pay & Accept the Offer
                              </button>
                            </div>
                          </>
                        ) : null}

                        {changeRequestReceived ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                onClick={() => {
                                  setDescription("");
                                  setSubmitAppealError(false);
                                }}
                                className="whiteButton otherButton"
                                data-bs-toggle="modal"
                                data-bs-target="#appeal-modal"
                              >
                                Appeal
                              </button>

                              <button
                                // onClick={() => setSubmitFull(!submitFull)}
                                className="greenButton submitButton d-flex justify-content-center"
                                data-bs-toggle="modal"
                                data-bs-target="#submit-work-modal"
                                onClick={() => {
                                  setSubmitWorkError(false);
                                }}
                              >
                                Submit Work
                              </button>
                            </div>
                          </>
                        ) : null}
                        {workReceivedAgain ? (
                          <>
                            <div className="d-flex buttons-display justify-content-between">
                              <button
                                onClick={() => {
                                  setDescription("");
                                  setSubmitAppealError(false);
                                }}
                                className="whiteButton otherButton"
                                data-bs-toggle="modal"
                                data-bs-target="#appeal-modal"
                              >
                                Appeal
                              </button>

                              <button
                                className="greenButton submitButton d-flex justify-content-center"
                                data-bs-toggle="modal"
                                data-bs-target="#accept-work-modal"
                              >
                                {workLoading ? (
                                  <PuffLoader
                                    className="justify-content-center"
                                    color="white"
                                    size={22}
                                  />
                                ) : (
                                  "Accept Work"
                                )}
                              </button>
                            </div>
                          </>
                        ) : null}

                        {/*  BUTTONS END */}
                      </div>

                      {/* INPUT  */}
                      {showInputMessageField()}
                    </div>
                  </div>
                  {/* CHAT BOX END  */}
                </>
              )}
            </div>
            <div />
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default CreatorChat;
