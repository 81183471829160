import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../states/auth/authSlice";
import creatorsReducer from "../states/creators/creatorSlice";
import brandsReducer from "../states/brands/brandSlice";
import campaignsReducer from "../states/campaigns/campaignSlice";
import collaborationsReducer from "../states/collaborations/collaborationSlice";
import myCampaignsReducer from "../states/myCampaigns/myCampaignSlice";
import packsReducer from "../states/packs/packSlice";
import creatorDetailsReducer from "../states/creatorDetails/creatorDetailsSlice";
import brandDetailsReducer from "../states/brandDetails/brandDetailsSlice";
import pendingRequestsReducer from "../states/pendingRequests/pendingRequestsSlice";
import singleCollaborationReducer from "../states/singleCollaboration/singleCollaborationSlice";
import singleCampaignReducer from "../states/singleCampaign/singleCampaignSlice";
import packDetailsReducer from "../states/packDetails/packDetailsSlice";
import contactUsReducer from "../states/contactUs/contactUsSlice";

import chatReducer from "../states/chat/chatSlice";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  creators: creatorsReducer,
  brands: brandsReducer,
  campaigns: campaignsReducer,
  collaborations: collaborationsReducer,
  myCampaigns: myCampaignsReducer,
  packs: packsReducer,
  creatorDetails: creatorDetailsReducer,
  brandDetails: brandDetailsReducer,
  pendingRequests: pendingRequestsReducer,
  singleCollaboration: singleCollaborationReducer,
  singleCampaign: singleCampaignReducer,
  packDetails: packDetailsReducer,
  chat: chatReducer,
  contactUs: contactUsReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export const persistor = persistStore(store);
