// packDetailsSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { getSinglePackCall } from "../../Common/Repository";

const initialState = {
    singlePackDetails: {},
    loading: false,
    error: null,
};

const packDetailsSlice = createSlice({
    name: "singlePack",
    initialState,
    reducers: {
        setSinglePackData: (state, action) => {
            state.singlePackDetails = action.payload;
            state.loading = false;
            state.error = null;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSinglePackCall.pending, (state) => {
                state.error = null;
            })
            .addCase(getSinglePackCall.fulfilled, (state, action) => {
                state.singlePackDetails = action.payload?.data;
                state.loading = false;
                state.error = null;
            })
            .addCase(getSinglePackCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(logout, (state) => {
                state.singlePackDetails = {};
            });
    },
});

export const { setSinglePackData, setLoading } = packDetailsSlice.actions;

export default packDetailsSlice.reducer;
