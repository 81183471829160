import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import GenderSelect from "../../Components/GenderSelect";
import DropdownForList from "../../Components/DropdownForList";
import {
  FilterCreatorsCatalogue,
  getCreators,
  getFavouriteCreatorsCall,
} from "../../Common/Repository";

// String function .toProperCase is imported from here!
import "../../resources/common/functions";

import {
  FullSearchIcon,
  GridIcon,
  ListIcon,
  InfoIcon,
} from "../../Components/Icons";

import IconButton from "@mui/material/IconButton";

import CustomizedSlider from "../../Components/CustomizedSlider";
import { useDispatch, useSelector } from "react-redux";
import { setCreators } from "../../states/creators/creatorSlice";
import { useNavigate } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import LightTooltip from "../../Components/LightToolTip";

const CreatorsCatalogue = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state.auth);

  const { creatorsData, loading, filterLoading } = useSelector(
    (state) => state.creators
  );

  const [isFavourite, setIsFavourite] = useState(false);

  const [search, setSearch] = useState("");

  const [searchCategory, setSearchCategory] = useState("");

  const [minFollowers, setMinFollowers] = useState(1000);

  const [maxFollowers, setMaxFollowers] = useState(1000000);

  const [minEngagement, setMinEngagement] = useState(0);

  const [maxEngagement, setMaxEngagement] = useState(50);

  const [minAudience, setMinAudience] = useState(1000);

  const [maxAudience, setMaxAudience] = useState(10000000);

  const [priceFilterResetToggle, setPriceFilterResetToggle] = useState(false);

  const [errors, setErrors] = useState([]);

  const [genderValue, setGenderVaue] = useState("");

  const [interestValue, setInterestValue] = useState([]);

  const [listView, setListView] = useState(false);

  const creatorsListData = {
    columnTitle: [
      "Creators Name",
      "Followers",
      "Posts",
      "Location",
      "Gender",
      "Category/Niche",
    ],
  };

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (
      (genderValue !== "" && genderValue !== "reset") ||
      interestValue.length !== 0 ||
      isFavourite
    ) {
      // If any filter is applied, call the respective filter function
      // console.log("respective filter: ");
      if (genderValue !== "" && genderValue !== "reset") {
        filterCreatorsCatalogue("gender");
      }
      if (interestValue.length !== 0) {
        filterCreatorsCatalogue("interest");
      }
      if (isFavourite) {
        getFavouriteCreatorsFromDb();
      }
    } else {
      // If no filters are applied, retrieve all creators
      // console.log('retrieve all creators: '); s
      getCreatorsFromDb();
    }
  }, [genderValue, interestValue, isFavourite]);

  const handleGenderValue = (value) => {
    setGenderVaue(value !== "reset" || value !== "" ? value : "");
  };

  const handleInterestClick = (value) => {
    if (interestValue.includes(value)) {
      // Remove the interest if it already exists
      setInterestValue(interestValue.filter((interest) => interest !== value));
    } else {
      // Add the interest if it doesn't exist
      setInterestValue([...interestValue, value]);
    }
  };

  const getCreatorsFromDb = async () => {
    try {
      dispatch(getCreators({ token, dropdownOpen }));
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  const getFavouriteCreatorsFromDb = async () => {
    try {
      let response = await getFavouriteCreatorsCall({
        Authorization: `Bearer ${token}`,
      });
      if (response?.status === 200) {
        dispatch(setCreators(response?.data?.map((d) => d.creator)));
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  const filterCreatorsCatalogue = async (key) => {
    try {
      let data = {};

      if (key === "followers") {
        data.followers = {
          min: null,
          max: maxFollowers,
        };
      }

      if (key === "engagement") {
        data.engagement = {
          min: minEngagement,
          max: maxEngagement,
        };
      }

      if (key === "audience") {
        data.audience = {
          min: minAudience,
          max: maxAudience,
        };
      }

      if (key === "category" && searchCategory.length > 0) {
        data.category_name = searchCategory;
      }

      if (key === "location" && search.length > 0) {
        data.address = search;
      }

      if (key === "interest" && interestValue.length !== 0) {
        data.niches = interestValue;
      }

      if (key === "gender" && genderValue !== "" && genderValue !== "reset") {
        data.gender = genderValue;
      }

      dispatch(
        FilterCreatorsCatalogue({
          token,
          data,
          dropdownOpen,
        })
      );
    } catch (error) { }
  };

  const interestData = [
    "Travel",
    "Cooking",
    "Singing",
    "Dancing",
    "Makeup Artist",
    "Beauty",
    "Model",
    "Football",
    "Singing",
    "Water Sports",
    "Technology",
    "Entrepreneur",
    "Eating out",
    "UGC",
    "Thematic",
    "Theatrical",
    "Editing",
    "Gaming",
    "Entertainment",
    "Humor & Comedy",
  ];

  const handleListView = () => {
    window.$("#listing-view-catalogues").removeClass("d-none");
    window.$("#box-view-catalogues").addClass("d-none");
  };

  const handleBoxView = () => {
    window.$("#box-view-catalogues").removeClass("d-none");
    window.$("#listing-view-catalogues").addClass("d-none");
  };

  const dropdownOpen = (event) => {
    if (event) {
      const obj = event.currentTarget;
      //
      if (window.$(obj).find(".innerDropDown").hasClass("open")) {
        window.$(obj).find(".innerDropDown").removeClass("open");
      } else {
        window.$(".innerDropDown").removeClass("open");
        window.$(obj).find(".innerDropDown").addClass("open");
        window.$(obj).find(".innerDropDown").removeClass("d-none");
      }
    } else {
      window.$(".innerDropDown").removeClass("open");
    }
  };

  const dropdownBox = (event) => {
    event.stopPropagation();
  };

  const handleCreatorDetail = (creatorData) => {
    if (user?.role == "2" && user?.account_verified == 1) {
      navigate("/creatorDetails", {
        state: { creatorId: creatorData?.id },
      });
    }
  };

  const sliderValues = (event, newValue, customKey) => {
    if (customKey === "followers") {
      setMinFollowers(newValue[0]);
      setMaxFollowers(newValue[1]);
    } else if (customKey === "engagement") {
      setMinEngagement(newValue[0]);
      setMaxEngagement(newValue[1]);
    } else if (customKey === "audience") {
      setMinAudience(newValue[0]);
      setMaxAudience(newValue[1]);
    }
  };

  const formattedValue = (value, customKey) => {
    if (
      customKey === "audience" ||
      customKey === "followers" ||
      customKey === "price"
    ) {
      if (value < 1000) {
        return `${value.toFixed(1)}`;
      } else if (value < 1000000) {
        return `${(value / 1000).toFixed(1)}K`;
      } else {
        return `${(value / 1000000).toFixed(1)}M`;
      }
    } else if (customKey === "engagement") {
      return `${value.toFixed(1)}%`;
    } else {
      return value;
    }
  };
  const handleInputClick = (e) => {
    // Prevent the click event from propagating to the parent div
    e.stopPropagation();
  };

  const filteredData = interestData.filter((item) =>
    item.toLowerCase().includes(searchInput.toLowerCase())
  );
  return (
    <AppLayout>
      <div className="categories-dashboard px-5">
        <div className="container container-animation">
          <img
            className="purple-circle"
            src="/assets/images/circle_top1.png"
            alt="circle_top1"
          />
          <img
            className="yellow-circle"
            src="/assets/images/circle-03.png"
            alt="circle_top1"
          />
          <div className="categories-dashboard mt-5">
            <div className="fixed-secondary-bar">
              <div className="search-bar" data-onboarding="step1">
                <div className="search-bar-input-content brand-bar-input">
                  <div className="search-bar-input-container">
                    {/* <div className="creatorCatalogueSearch position-relative">
                      <input
                        type="search"
                        autoComplete="off"
                        className="searchFieldCreator searchInsideField"
                        placeholder="Search for categories"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_8_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_8_list"
                        aria-activedescendant="rc_select_8_list_-1"
                        id="rc_select_8"
                        aria-expanded="false"
                        onChange={(event) =>
                          setSearchCategory(event.target.value)
                        }
                        value={searchCategory}
                      />
                      <div
                        className="searchIcon"
                        onClick={filterCreatorsCatalogue}
                      >
                        <FullSearchIcon
                          className="searchIconBackground"
                          width="10vw"
                          height="5.4vh"
                        />
                      </div>
                    </div> */}
                    <div className="d-flex align-items-center position-relative search-bar--input-container -tags width-40 wm-100">
                      <div
                        className="search-tag-position"
                        onClick={() => {
                          if (searchCategory !== "") {
                            filterCreatorsCatalogue("category");
                          }
                        }}
                      >
                        <FullSearchIcon
                          color={!searchCategory.length ? "#93BFC0" : null}
                          width="133px"
                        />
                      </div>
                      <span className="d-flex align-items-center w-100">
                        <div
                          className="ant-select ant-select-auto-complete search-bar--input css-10evijz ant-select-single ant-select-show-search w-100"
                          autoComplete="off"
                        >
                          <div className="ant-select-selector w-100">
                            <span className="ant-select-selection-search w-100">
                              <input
                                type="text"
                                className="ant-select-selection-search-input w-100"
                                id="search"
                                value={searchCategory}
                                placeholder="Search for categories"
                                role="combobox"
                                aria-haspopup="listbox"
                                aria-owns="rc_select_8_list"
                                aria-autocomplete="list"
                                aria-controls="rc_select_8_list"
                                aria-activedescendant="rc_select_8_list_-1"
                                aria-expanded="false"
                                onChange={(event) =>
                                  setSearchCategory(event.target.value)
                                }
                                onKeyUp={(event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault();
                                    if (searchCategory !== "") {
                                      filterCreatorsCatalogue("category");
                                    }
                                  } else if (event.key === "Backspace") {
                                    if (searchCategory === "") {
                                      event.preventDefault();

                                      getCreatorsFromDb();
                                    }
                                  } else if (
                                    event.ctrlKey &&
                                    event.key === "x"
                                  ) {
                                    if (
                                      event.target.selectionStart === 0 &&
                                      event.target.selectionEnd ===
                                      searchCategory.length
                                    ) {
                                      // If text is selected and then deleted (Ctrl + X)
                                      event.preventDefault();
                                      setSearchCategory(""); // Clear search field
                                      getCreatorsFromDb(); // Call getCreatorsFromDb
                                    }
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div className="location-search location-search--no-label position-relative d-flex align-items-center flex-row location-search--search">
                      <span className="inputLocationIcon">
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.5005 0C7.70775 0 3.80859 3.89916 3.80859 8.69184C3.80859 14.6397 11.587 23.3715 11.9182 23.7404C12.2292 24.0868 12.7723 24.0862 13.0828 23.7404C13.414 23.3715 21.1924 14.6397 21.1924 8.69184C21.1923 3.89916 17.2932 0 12.5005 0ZM12.5005 13.065C10.0891 13.065 8.12742 11.1032 8.12742 8.69184C8.12742 6.2805 10.0892 4.31878 12.5005 4.31878C14.9118 4.31878 16.8735 6.28055 16.8735 8.69189C16.8735 11.1032 14.9118 13.065 12.5005 13.065Z"
                            fill="#569C9E"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="w-100 searchFieldCreator"
                        placeholder="Search a location"
                        autoComplete="new-password"
                        onChange={(event) => setSearch(event.target.value)}
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            if (search !== "") {
                              filterCreatorsCatalogue("location");
                            }
                          } else if (event.key === "Backspace") {
                            if (search === "") {
                              event.preventDefault();
                              getCreatorsFromDb();
                            }
                          } else if (event.ctrlKey && event.key === "x") {
                            if (
                              event.target.selectionStart === 0 &&
                              event.target.selectionEnd === search.length
                            ) {
                              // If text is selected and then deleted (Ctrl + X)
                              event.preventDefault();
                              setSearch(""); // Clear search field
                              getCreatorsFromDb(); // Call getCreatorsFromDb
                            }
                          }
                        }}
                        value={search}
                      />

                      <div
                        className="inputLocationSearchIcon d-flex"
                        onClick={() => {
                          if (search !== "") {
                            filterCreatorsCatalogue("location");
                          }
                        }}
                      >
                        <div className="d-flex justify-content-center flex-column me-2">
                          <svg
                            width="21"
                            height="22"
                            viewBox="0 0 21 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.3844 18.3475C5.71023 18.3475 1.92109 14.5583 1.92109 9.88418C1.92109 5.21004 5.71023 1.4209 10.3844 1.4209C15.0585 1.4209 18.8477 5.21004 18.8477 9.88418C18.8477 14.5583 15.0585 18.3475 10.3844 18.3475Z"
                              // stroke="#549A9C"
                              stroke={search !== "" ? "#549A9C" : "#93BFC0"}
                              stroke-width="2"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M23.0791 22.5786L16.3684 15.8672"
                              // stroke="#549A9C"
                              stroke={search !== "" ? "#549A9C" : "#93BFC0"}
                              stroke-width="2"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          onClick={() => {
                            if (search !== "") {
                              setSearch("");
                              getCreatorsFromDb();
                            }
                          }}
                          className="d-flex align-items-center text-dark fs-6 link-style reset-filter-icon"
                        >
                          <img
                            src="/assets/images/reset-icon.svg"
                            alt="reset"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="iconBox wm-100">
                    <div
                      onClick={() => {
                        handleListView();
                        setListView(true);
                      }}
                      id="list-view"
                      className="wm-50"
                    >
                      <ListIcon
                        className={
                          listView ? "selectedCatalogueIcon" : "catalogueIcon"
                        }
                        strokeColor={!listView ? "#549A9C" : "white"}
                      />
                    </div>
                    <div
                      onClick={() => {
                        handleBoxView();
                        setListView(false);
                      }}
                      id="box-view"
                      className="wm-50"
                    >
                      <GridIcon
                        className={
                          !listView ? "selectedCatalogueIcon" : "catalogueIcon"
                        }
                        strokeColor={listView ? "#549A9C" : "white"}
                      />
                    </div>
                  </div>
                  {/* Reset All Filters */}
                  <div
                    onClick={() => {
                      setSearchCategory("");
                      setSearch("");

                      setPriceFilterResetToggle(!priceFilterResetToggle);
                      setMinFollowers(1000);
                      setMaxFollowers(1000000);

                      setPriceFilterResetToggle(!priceFilterResetToggle);
                      setMinEngagement(0);
                      setMaxEngagement(50);

                      setPriceFilterResetToggle(!priceFilterResetToggle);
                      setMinAudience(1000);
                      setMaxAudience(10000000);

                      setSearchInput("");
                      setInterestValue([]);

                      handleGenderValue("");
                      getCreatorsFromDb();
                    }}
                    className="reset-all-filter-icon d-flex align-items-center wm-100"
                  >
                    {/* Reset */}
                    <img src="/assets/images/reset-icon.svg" alt="reset" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filtersMainRow position-relative mb-5 mt-4 d-flex justify-content-between mobile-col">
            <div className="filtersButton" onClick={dropdownOpen}>
              <div className="filtersDropDown d-flex">
                <p className="m-0 w-100 d-flex align-items-center  normalTextTwo">
                  Followers
                  {`: ${formattedValue(
                    minFollowers,
                    "followers"
                  )}-${formattedValue(maxFollowers, "followers")}`}
                </p>
                <div className="d-inline-block">
                  <img
                    src="/assets/images/arrow-sign.png"
                    alt=""
                    width={15}
                    className=""
                  />
                </div>
              </div>
              <div className="innerDropDown" onClick={dropdownBox}>
                <LightTooltip
                  className="float-right"
                  placement="right"
                  title="Use this slider to define the minimum and maximum number of followers that creators should have to be included in your search criteria."
                >
                  <IconButton>
                    <InfoIcon width="18" height="18" />
                  </IconButton>
                </LightTooltip>
                <div className="slider-box mt-3">
                  <CustomizedSlider
                    customKey={"followers"}
                    minValue={minFollowers}
                    maxValue={maxFollowers}
                    // startingValue={maxFollowers}
                    handleChange={(event, newValue) => {
                      // console.log("New Value Type: ", newValue);
                      sliderValues(event, newValue, "followers");
                    }}
                    SliderWidth={180}
                    priceFilterResetToggle={priceFilterResetToggle}
                  />
                </div>
                <div className="d-flex justify-content-between mx-3 mt-2">
                  <div
                    onClick={() => {
                      setPriceFilterResetToggle(!priceFilterResetToggle);
                      setMinFollowers(1000);
                      setMaxFollowers(1000000);
                      getCreatorsFromDb();
                    }}
                    className="d-flex align-items-center text-dark fs-6 link-style m-0"
                  >
                    Reset
                  </div>
                  <div
                    onClick={() => filterCreatorsCatalogue("followers")}
                    className="apply-btn"
                  >
                    Apply
                  </div>
                </div>
              </div>
            </div>
            <div className="filtersButton" onClick={dropdownOpen}>
              <div className="filtersDropDown d-flex">
                <p className="m-0 w-100 d-flex align-items-center  normalTextTwo">
                  Engagement
                  {`: ${formattedValue(
                    minEngagement,
                    "engagement"
                  )}-${formattedValue(maxEngagement, "engagement")}`}
                </p>
                <div className="d-inline-block">
                  <img
                    src="/assets/images/arrow-sign.png"
                    alt=""
                    width={15}
                    className=""
                  />
                </div>
              </div>
              <div className="innerDropDown" onClick={dropdownBox}>
                <div className="slider-box mt-3">
                  <CustomizedSlider
                    customKey={"engagement"}
                    minValue={minEngagement}
                    maxValue={maxEngagement}
                    // startingValue={minEngagement}
                    handleChange={(event, newValue) =>
                      sliderValues(event, newValue, "engagement")
                    }
                    SliderWidth={180}
                    priceFilterResetToggle={priceFilterResetToggle}
                  />
                </div>
                <div className="d-flex justify-content-between mx-3 mt-2">
                  <div
                    onClick={() => {
                      setPriceFilterResetToggle(!priceFilterResetToggle);
                      setMinEngagement(0);
                      setMaxEngagement(50);
                      getCreatorsFromDb();
                    }}
                    className="d-flex align-items-center text-dark fs-6 link-style m-0"
                  >
                    Reset
                  </div>
                  <div
                    onClick={() => filterCreatorsCatalogue("engagement")}
                    className="apply-btn"
                  >
                    Apply
                  </div>
                </div>
              </div>
            </div>
            <div className="filtersButton" onClick={dropdownOpen}>
              <div className="filtersDropDown d-flex">
                <p className="m-0 w-100 d-flex align-items-center  normalTextTwo">
                  Reach
                  {`: ${formattedValue(
                    minAudience,
                    "audience"
                  )}-${formattedValue(maxAudience, "audience")}`}
                  {/* {minAudience < 10000000 ? ": " + minAudience.toString() : ""} */}
                </p>
                <div className="d-inline-block">
                  <img
                    src="/assets/images/arrow-sign.png"
                    alt=""
                    width={15}
                    className=""
                  />
                </div>
              </div>
              <div className="innerDropDown" onClick={dropdownBox}>
                <LightTooltip
                  className="float-right"
                  placement="right"
                  title="Reach refers to the total number of unique viewers who could potentially see the content created by the influencer. This filter does not reflect the number of followers but the estimated audience size that a creator's content can impact. Use this tool to match with creators whose reach aligns with the scale of visibility you want for your campaigns."
                >
                  <IconButton>
                    <InfoIcon width="18" height="18" />
                  </IconButton>
                </LightTooltip>
                <div className="slider-box mt-3">
                  <CustomizedSlider
                    customKey={"audience"}
                    minValue={minAudience}
                    maxValue={maxAudience}
                    // startingValue={maxAudience}
                    handleChange={(event, newValue) =>
                      sliderValues(event, newValue, "audience")
                    }
                    SliderWidth={180}
                    priceFilterResetToggle={priceFilterResetToggle}
                  />
                </div>
                <div className="d-flex justify-content-between mx-3 mt-2">
                  <div
                    onClick={() => {
                      setPriceFilterResetToggle(!priceFilterResetToggle);
                      setMinAudience(1000);
                      setMaxAudience(10000000);
                      getCreatorsFromDb();
                    }}
                    className="d-flex align-items-center text-dark fs-6 link-style m-0"
                  >
                    Reset
                  </div>
                  <div
                    onClick={() => filterCreatorsCatalogue("audience")}
                    className="apply-btn"
                  >
                    Apply
                  </div>
                </div>
              </div>
            </div>
            <div
              className="filtersButton filtersButton-list"
              onClick={dropdownOpen}
            >
              <div className="filtersDropDown d-flex">
                <p
                  // style={{
                  //   maxWidth: "190px",
                  // }}
                  className="m-0 me-2 text-truncate w-100 d-flex align-items-center  normalTextTwo wm-100"
                >
                  {interestValue && interestValue.length
                    ? `Interests: ${interestValue}`
                    : "Interests"}
                </p>
                <div className="d-inline-block">
                  <img
                    src="/assets/images/arrow-sign.png"
                    alt=""
                    width={15}
                    className=""
                  />
                </div>
              </div>
              <div className="innerDropDown overflowYScroll">
                <div className="d-flex justify-content-between">
                  <input
                    type="search"
                    className="form-control-sm filterSearch disable-clear"
                    placeholder="Type to Search"
                    aria-controls="brand-detail-listing-table"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onClick={handleInputClick}
                  />
                  <div className="search-filter-icon">
                    <img alt="s" src="/assets/images/searchIcon-new.svg" />
                  </div>
                  <div
                    onClick={(e) => {
                      if (interestValue.length || searchInput !== "") {
                        setSearchInput("");
                        setInterestValue([]);
                        getCreatorsFromDb();
                      } else {
                        e.stopPropagation();
                      }
                    }}
                    className="d-flex align-items-center text-dark fs-6 ms-2 link-style"
                  >
                    <div className="d-flex align-items-center text-dark fs-6 link-style reset-filter-icon">
                      <img src="/assets/images/reset-icon.svg" alt="reset" />
                    </div>
                  </div>
                </div>
                <div className="list-box"></div>
                <DropdownForList
                  data={filteredData}
                  selectedValue={interestValue}
                  handleClick={handleInterestClick}
                />
              </div>
            </div>
            <div className="filtersButton" onClick={dropdownOpen}>
              <div className="filtersDropDown d-flex">
                <p className="m-0 w-100 d-flex align-items-center  normalTextTwo">
                  Gender
                  {genderValue !== "" && genderValue !== "reset"
                    ? ": " + genderValue.toProperCase()
                    : ""}
                </p>
                <div className="d-inline-block">
                  <img
                    src="/assets/images/arrow-sign.png"
                    alt=""
                    width={15}
                    className=""
                  />
                </div>
              </div>
              <div
                className="innerDropDown genderSelection"
                onClick={dropdownBox}
              >
                <GenderSelect
                  resetButton={"resetButton"}
                  width={60}
                  handleSelectGender={handleGenderValue}
                />
              </div>
            </div>
          </div>
          <div className="col-12 pb-lg-1 pb-md-4 mb-4">
            <div className="creators-heading">
              {isFavourite ? "Favourites" : "Creator Catalogue"}
            </div>
            <div className="d-flex justify-content-center">
              <div
                onClick={() => setIsFavourite(!isFavourite)}
                className="d-block link-style fav-creators-style"
              >
                {isFavourite
                  ? "Creator Catalogue"
                  : user?.role === "1" || user?.role === 1
                    ? ""
                    : "Favourites"}
              </div>
            </div>
            {creatorsData?.length <= 0 && !loading && !filterLoading ? (
              <div className="p-5 m-5 d-flex justify-content-center">
                <span>No Creators Found </span>
              </div>
            ) : (
              ""
            )}
          </div>

          {loading ? (
            <>
              <div className="p-5 m-5 d-flex justify-content-center">
                <PuffLoader color="#65acae" size={42} />
              </div>
            </>
          ) : (
            <>
              {/* LIST VIEW START */}
              <div
                className="brand-listing-table d-none"
                id="listing-view-catalogues"
              >
                <div
                  // id="brand-detail-listing-table_wrapper"
                  className="dataTables_wrapper mt-5 dt-bootstrap5 no-footer"
                >
                  <div className="row dt-row">
                    <div className="col-sm-12 ms-3">
                      {filterLoading ? (
                        <div className="p-5 m-5 d-flex justify-content-center">
                          <PuffLoader color="#65acae" size={42} />
                        </div>
                      ) : (
                        <table
                          id="brand-detail-listing-table"
                          className="table rounded-top-3 table-striped dataTable no-footer"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              {creatorsListData.columnTitle.map((cTitle) => {
                                return (
                                  <>
                                    <th
                                      className={
                                        cTitle === "Creators Name" ||
                                          cTitle === "Categories"
                                          ? "w-auto"
                                          : "text-center w-auto"
                                      }
                                      tabIndex={0}
                                      aria-controls="brand-detail-listing-table"
                                      rowSpan={1}
                                      colSpan={1}
                                    // aria-label="Account: activate to sort column ascending"
                                    >
                                      <span className="mx-3">{cTitle}</span>
                                    </th>
                                  </>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {creatorsData?.map((cData, index) => {
                              const isEven = (index + 1) % 2 === 0;
                              const rowClass = isEven
                                ? "bg-table-stripe"
                                : "bg-white";
                              return (
                                <>
                                  <tr
                                    key={index}
                                    className={rowClass}
                                    onClick={() => handleCreatorDetail(cData)}
                                  >
                                    <td>
                                      <div className="profile-outer-box ps-4 ms-2 w-100 d-flex ">
                                        <div className="profile-inner2">
                                          <span className="person-name-dark">
                                            {cData?.profile_name ?? "-"}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      {cData?.user?.instagram_followers_count ?? "-"}
                                    </td>
                                    <td className="text-center">
                                      {cData?.user?.instagram_media_count ?? "-"}
                                    </td>
                                    <td className="text-center">
                                      {cData?.address ?? "-"}
                                    </td>
                                    <td className="text-center text-capatilize">
                                      {/* <img
                                  src={
                                    cData?.gender
                                     === "Male"
                                      ? "/assets/images/men.png"
                                      : "/assets/images/women.png"
                                  }
                                  alt={cData?.gender}
                                /> */}
                                      {cData?.gender}
                                    </td>

                                    <td className="text-center">
                                      {cData?.user?.page_connected_to_instagram_category}
                                    </td>


                                    {/* {!cData?.categories?.length ? (
                                      <td className="text-center">-</td>
                                    ) : (
                                      <>
                                        <td>
                                          <div className="d-flex">
                                            {cData?.categories
                                              .slice(0, 3)
                                              .map((category) => (
                                                <>
                                                  <button
                                                    className="table-category-btn mx-1"
                                                    key={category?.name}
                                                  >
                                                    {category?.name.length > 12
                                                      ? category?.name.slice(
                                                        0,
                                                        12
                                                      )
                                                      : category?.name}
                                                    {category?.name.length > 12
                                                      ? "..."
                                                      : ""}
                                                  </button>
                                                </>
                                              ))}
                                            {cData?.categories.length > 2 && (
                                              <div className="table-category-btn-sm">
                                                +{cData?.categories.length - 3}
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      </>
                                    )} */}
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* LIST VIEW END */}
              {/* BOX VIEW START  */}
              <div
                className="browse-catalog position-relative"
                id="box-view-catalogues"
              >
                <img
                  src="/assets/images/Plane-opt.png"
                  className="img-fluid plane-img position-absolute top-0 w-auto mt-xl-5 pt-lg-5"
                  alt="..."
                />
                <div className="p-md-5 mx-md-5 creator-container">
                  {/* BOX  */}
                  {creatorsData?.map((creator) => {
                    return (
                      <div
                        className="creatorTile"
                        onClick={() => handleCreatorDetail(creator)}
                      >
                        <div className="empty-top-space"></div>
                        {creator.profile_image !== null ? (
                          <>
                            <img
                              className="creator-image"
                              src={creator?.profile_image}
                              alt="creator profile"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              className="creator-image"
                              // src="/assets/images/404_circle.png"
                              src="/assets/images/user.png"
                              alt="creator profile"
                            />
                          </>
                        )}
                        <div>
                          <span className="creators-name">
                            {creator?.profile_name}
                          </span>
                        </div>
                        <table className="creator-inside-info">
                          <tbody>
                            <tr className="bg-white mobile-col">
                              <td>
                                Followers
                                <br />
                                <span>{creator?.user?.instagram_followers_count ?? "-"}</span>
                              </td>
                              <td>
                                Posts
                                <br />
                                <span>{creator?.user?.instagram_media_count != null ? creator.user.instagram_media_count : "-"}</span>

                              </td>
                            </tr>
                            <tr className="bg-white mobile-col">
                              <td>
                                Niche/Industry
                                <br />
                                <span>{creator?.user?.page_connected_to_instagram_category ?? "-"}</span>
                              </td>
                              <td>
                                Location
                                <br />
                                <span>{creator?.address ?? "-"}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                  {/* BOX  */}
                </div>
              </div>
              {/* BOX VIEW END  */}
              <img
                src="/assets/images/para-opt.png"
                className="hide-on-phone img-fluid position-absolute w-auto para-img"
                alt="..."
              />
            </>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default CreatorsCatalogue;
