import React, { useState } from "react";
import { useFilePicker } from "use-file-picker";
import { UploadIcon } from "./Icons";

const SelectAttachment = ({ handleFileUpload, setLinkActive, linkActive }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const [openAttachmentSelector, { filesContent, loading, error }] =
    useFilePicker({
      readAs: "DataURL",
      accept: "image/jpeg, image/png",
      multiple: true,
      limitFilesConfig: { max: 1 },
      maxFileSize: 50,
      onFilesSelected: ({ plainFiles, filesContent, errors }) => {
        handleFileUpload(filesContent);
      },
    });

  return (
    <>
      {filesContent.length === 0 && (
        <div className="position-relative justify-content-center d-flex">
          <div className="d-flex flex-column mt-2 outerbox-select-attachment">
            <div className="d-flex mt-4 pt-1">
              <UploadIcon
                className="justify-content-center mx-auto"
                color="black"
              />
            </div>
            <div className="text-center mt-3">
              Drag and drop attachments here to upload
            </div>
            <div
              className={`btn pt-1 mx-auto mt-3 green-color ${linkActive ? "text-muted" : "green-color"}`}
              onClick={() => {
                if (!linkActive) {
                  setLinkActive(false);
                  openAttachmentSelector();
                }
              }}
            >
              Browse Files
            </div>
          </div>
        </div>
      )}

      {filesContent.length > 0 && (
        <div className="d-flex flex-column mt-2">
          {filesContent.map((file) => (
            <div className="mt-3 d-flex justify-content-center">
              <img
                className="w-25"
                src={file.content || user?.creator?.creator_portfolios[0].value}
                alt="..."
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SelectAttachment;
