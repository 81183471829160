import React, { useState } from "react";
import AppLayout from "../../Components/AppLayout";

const CatalogueDetails = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  return (
    <AppLayout>
      <div className="container profile-page-container p-0 mt-5 mb-5">
        <h3>Developent is going on..</h3>
        <button
          data-bs-toggle="modal"
          data-bs-target="#info-received-modal"
          className="btn bg-primary"
        >
          Info received Modal
        </button>
        <button
          data-bs-toggle="modal"
          data-bs-target="#congratulations-modal"
          className="btn bg-success"
        >
          Congratulations Pop Up
        </button>
      </div>
      {/* INFO RECEIVED MODAL  */}
      {/* <div
        className="modal fade mt-5 pt-5 "
        id="info-received-modal"
        tabIndex={-1}
        aria-labelledby="info-received-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog user-settings-modal">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className=" close-btn-box lg-close-position"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/images/close-Icon.png" alt="X" />
              </div>
              <div className="d-flex justify-content-center mt-4">
                <img
                  src="/assets/images/info-received.png"
                  alt="Info Received"
                  width={250}
                />
              </div>
              <div className="mt-3 info-heading">Info Received</div>
              <div className="d-flex justify-content-center">
                <p className="w-75 px-5 text-center">
                  We have received your information and we will notify you by
                  email if your information is validated!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* CONGRATULATIONS MODAL  */}
      <div
        className="modal fade modal-center"
        id="congratulations-modal"
        tabIndex={-1}
        aria-labelledby="congratulations-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog congratulations-modal-style">
          <div className="modal-content">
            <div className="modal-body p-5">
              <img
                className="blue-ball-popup"
                src="/assets/images/blue_ball.png"
                alt="blue-ball"
                width={25}
              />
              <img
                className="popup-yellow-circle position-absolute"
                src="assets/images/yellow_circle.png"
                alt="circle_3"
                width={16}
              />
              <img
                className="purple-empty-circle-popup"
                src="assets/images/purple_empty_circle.png"
                alt="empty_circle"
                width={20}
              />
              <img
                className="purple-plus-popup"
                src="assets/images/purple_plus.png"
                alt="plus"
                width={16}
              />
              <img
                className="purple-empty-circle-2-popup position-absolute"
                src="assets/images/purple_empty_circle.png"
                alt="empty_circle_2"
                width={12}
              />
              <div
                className=" close-btn-box new-lg-close-position"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/images/close-Icon2.svg" alt="X" />
              </div>
              <div className="d-flex justify-content-center">
                <div className="congrats-img-bg">
                  <img
                    src="/assets/images/congrats-check.svg"
                    alt="Congrats"
                    width={200}
                  />
                </div>
              </div>
              <div className="text-center">
                <div className="mt-3 congrats-heading">Congratulations</div>
                {user?.role === "1" ?
                  <>
                    <p className="mx-auto congrats-text">
                      You have been accepted to Vitrine's Brands Catalogue where you’ll be able to make deals with brands for your influencing capabilities.
                    </p>
                  </>
                  :
                  <>
                    <p className="mx-auto congrats-text">
                      Welcome to Vitrine's free Creators Catalogue where you can launch Influencer marketing campaigns in a secure and transparent way
                    </p>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    </AppLayout>
  );
};

export default CatalogueDetails;
