import React, { useState } from "react";
import AppLayout from "../../Components/AppLayout";

const BrandHelpCenter = () => {
  const accordionData = [
    {
      id: 1,
      question: "What is Vitrine?",
      answer: "Vitrine",
    },
    {
      id: 2,
      question: "What is Creator Catalogue?",
      answer:
        "The Creator Catalogue enables brands to finely filter and seek content creators for their marketing purposes. It enables an easier medium for exchange between businesses and influencers. Creator Catalogue is an online platform that connects businesses, small business owners, and individuals with creators and influencers who can help them reach new audiences and promote their products or services in an authentic and engaging way.",
    },
    {
      id: 3,
      question: "How does Creator Catalogue help businesses?",
      answer:
        "Creator Catalogue helps businesses by providing them with a pool of creators and influencers who can promote their products or services to a new and engaged audience. By working with creators who have a following in a specific niche or area of expertise, businesses can reach new potential customers and build brand awareness.",
    },
    {
      id: 4,
      question: "How do I find a creator or influencer on Creator Catalogue?",
      answer:
        "Creators and influencers are segmented into different categories based on their niche (Fashion, Travel, Tech, etc). Once you have the desired niche, you can then finer filter creators for your purposes through audience demographic filtering and more. You can browse the filtered catalog and view the profiles of individual creators and influencers to find the right fit for your needs.",
    },
    {
      id: 5,
      question:
        "How do I contract a creator or influencer on Creator Catalogue?",
      answer:
        "Once you have found a creator or influencer who you would like to work with, you can contact them through the platform to discuss your needs and negotiate a contract. Creator Catalogue also provides tools to help you manage the contracting process and ensure that both parties agree on the terms of the engagement. Contractual agreements are provided by Vitrine.",
    },
    {
      id: 6,
      question: "How does Creator Catalogue verify creators and influencers?",
      answer:
        "We manually verify every creator and their audience to ensure a high transparency standard. For all creators, we require monthly demographic updates, screenshot proof, as well as utilizing tools and algorithms to validate information.",
    },
    {
      id: 7,
      question: "How does payment work on Creator Catalogue?",
      answer:
        "The payment is done through the Stripe gateway. Vitrine escrows the payment until the deliverables are delivered and marked as complete.",
    },
    {
      id: 8,
      question: "Why use Vitrine's Creator Catalogue?",
      answer:
        "Our service is free to liberate creators from agencies and other financiers.",
    },
    {
      id: 9,
      question: "What is the Brand Catalogue?",
      answer:
        "The Brand Catalogue is the inverse of the Creator Catalogue, in which it enables creators to browse brands, view future campaigns, apply, and send personalized offers.",
    },
  ];

  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id) => {
    if (activeAccordion === id) {
      setActiveAccordion(null); // Close the accordion if it's already open
    } else {
      setActiveAccordion(id); // Open the clicked accordion
    }
  };
  return (
    <AppLayout>
      <div className="container container-help-center position-relative">
        <img
          className="help-center-purple-coin"
          src="/assets/images/circle_top1.png"
          alt="circle_top1"
        />
        <img
          className="help-center-ethereum-coin"
          src="/assets/images/ethereum-01.png"
          alt="ethereum-01"
        />
        <img
          className="help-center-dollor-coin"
          src="/assets/images/dollar-icon.png"
          alt="circle_top1"
        />
        <img
          className="help-center-yellow-circle-ball"
          src="/assets/images/circle_3.png"
          alt="circle_3"
        />
        <div className="help-center-container">
          <div className="help-center-inner-box">
            <div className="help-center-search-box-outer mb-5">
              <div className="help-center-heading">
                <h1>How Can We Help You!</h1>
              </div>
              <section className="main">
                <input
                  type="search"
                  id="helpCenter_searchBox"
                  className="form-control help-center-search-box"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-addon"
                />
                <ul className="nav" id="search-list"></ul>
              </section>
            </div>

            <div className="row help-center-row d-flex w-100">
              {accordionData.map((item) => (
                <div
                  className="accordion mb-3"
                  key={item.id}
                  id={`accordionExample_${item.id}`}
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading_${item.id}`}>
                      <button
                        className={`accordion-button${
                          activeAccordion === item.id ? "" : " collapsed"
                        }`}
                        type="button"
                        onClick={() => toggleAccordion(item.id)}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse_${item.id}`}
                        aria-expanded={
                          activeAccordion === item.id ? "true" : "false"
                        }
                        aria-controls={`collapse_${item.id}`}
                      >
                        {item.question}
                      </button>
                    </h2>
                    {activeAccordion === item.id && ( // Conditionally render the content only when the accordion is open
                      <div
                        id={`collapse_${item.id}`}
                        className="accordion-collapse collapse show"
                        aria-labelledby={`heading_${item.id}`}
                        data-bs-parent={`#accordionExample_${item.id}`}
                      >
                        <div className="accordion-body">{item.answer}</div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default BrandHelpCenter;
