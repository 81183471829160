import React from "react";
import AppLayout from "../Components/AppLayout";

const Learn = () => {
  return (
    <>
      <AppLayout></AppLayout>
    </>
  );
};

export default Learn;
