import React from "react";

function GoogleMapsEmbed(props) {
  return (
    <iframe
      className={props.className}
      title="Google Maps"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5135960.204434028!2d5.177486300672594!3d51.05690861390359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a721ec2b1be6b%3A0x75e85d6b8e91e55b!2sGermany!5e0!3m2!1sen!2s!4v1698661928092!5m2!1sen!2s"
      width="450"
      height="450"
      style={{ border: 0, borderRadius: props.borderRadius ?? "5%" }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}

export default GoogleMapsEmbed;
