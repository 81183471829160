import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const RangeSlider = styled(Slider)({
    color: "#549A9C",
    height: 14,
    '& .MuiSlider-rail': {
        border: '1px solid rgba(85, 155, 157, 0.90)',
        background: '#EEFCFD'

        //    backgroundColor: '#559B9D4D', // Change the background color of the unselected track
        //         border: '1px solid #549A9C'
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 32,
        width: 32,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});


function CustomizedSlider({ customKey, minValue, maxValue, handleChange, SliderWidth, rangeClassName }) {

    // let step = 100;
    // let maxLabel = "";
    // let minLabel = "";
    // if (customKey === "engagement") {
    //     step = 0.01;
    //     maxLabel = "50%";
    //     minLabel = "0%";
    // } else if (customKey === "audience") {
    //     step = 10000;
    //     maxLabel = "10M+";
    //     minLabel = "1K";
    // } else if (customKey === "age") {
    //     minLabel = "18"
    //     maxLabel = "100";
    // } else if (customKey === "price") {
    //     minLabel = "0"
    //     maxLabel = "100K";
    // } else {
    //     minLabel = "1K"
    //     maxLabel = "1M";
    // }

    const formattedValue = (value) => {
        if (customKey === "audience" || customKey === "followers" || customKey === "price") {
            if (value < 1000) {
                return `${value}`;
            } else if (value < 1000000) {
                return `${(value / 1000).toFixed(0)}K`;
            } else {
                return `${(value / 1000000).toFixed(1)}M`;
            }
        } else if (customKey === "engagement") {
            return `${value}%`
        }
        else {
            return value;
        }
    };

    return (
        <Box sx={{
            width: SliderWidth ?? 320
        }}>
            <Box sx={{ m: 3 }} />
            <RangeSlider
                value={[minValue, maxValue]}
                onChange={handleChange}
                defaultValue={[minValue ?? 0, maxValue ?? 100]}
                // step={step} // Set the step based on customKey
                min={customKey === "audience" ? 1000 : customKey === "followers" ? 1000 : customKey === "engagement" ? 0 : customKey === "age" ? 18 : customKey === "price" ? 0 : undefined}
                max={customKey === "audience" ? 10000000 : customKey === "followers" ? 1000000 : customKey === "engagement" ? 50 : customKey === "age" ? 100 : customKey === "price" ? 100000 : undefined}
            />
            <div className={rangeClassName ?? "range_value"}>
                <span className="range-value min">{formattedValue(minValue)}</span>

                {/* <span className="yrs">{formattedValue(currValue)}</span> */}

                <span className="range-value max">{formattedValue(maxValue)}</span>
            </div>
        </Box>
    );
}

export default CustomizedSlider;
