import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import GenderSelect from "../../Components/GenderSelect";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DropdownForList from "../../Components/DropdownForList";
import CustomizedSlider from "../../Components/CustomizedSlider";
import { useDispatch, useSelector } from "react-redux";
import { getUserPublicProfile } from "../../Common/Repository";

const VitrineLive = () => {
  const [showFilters, setShowFilters] = useState(true);

  const [priceFilterResetToggle, setPriceFilterResetToggle] = useState(false);

  const [minAudience, setMinAudience] = useState(1000);

  const [maxAudience, setMaxAudience] = useState(10000000);

  const [minAge, setMinAge] = useState(18);

  const [maxAge, setMaxAge] = useState(100)

  const [ageFilter, setAgeFilter] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector((state) => state?.auth?.user);

  // const user = JSON.parse(localStorage.getItem("user"));

  const token = localStorage.getItem("token");


  const location = useLocation();

  useEffect(() => {
    const loginButton = document.getElementById("log-in-from-LP");

    if (location?.state?.openLoginModal && loginButton) {

      loginButton.click();
    }
  }, [location]); // Add 'location' to the dependency array if it's used in the effect

  const dropdownOpen = (event) => {
    if (event) {
      const obj = event.currentTarget;
      if (window.$(obj).find(".inner-dropdown").hasClass("open")) {
        window.$(obj).find(".inner-dropdown").removeClass("open");
      } else {
        window.$(".inner-dropdown").removeClass("open");
        window.$(obj).find(".inner-dropdown").addClass("open");
        window.$(obj).find(".inner-dropdown").removeClass("d-none");
      }
    } else {
      window.$(".inner-dropdown").removeClass("open");
    }
  };

  const sampleJson = [
    {
      name: "Alexander",
      size: 40000000,
      interest: "Travel",
      country: "Australia",
      age: "18",
      gender: "male",
    },
    {
      name: "Julia",
      size: 22000000,
      interest: "Cooking",
      country: "Germany",
      age: "31",
      gender: "female",
    },
    {
      name: "Cristian",
      size: 36000000,
      interest: "Beauty",
      country: "Europe",
      age: "18",
      gender: "male",
    },
    {
      name: "Vlad",
      size: 49491681,
      interest: "Water Sports",
      country: "United Kingdom",
      age: "30",
      gender: "male",
    },
    {
      name: "Olivia",
      size: 580840,
      interest: "Singing",
      country: "Germany",
      age: "65",
      gender: "female",
    },
    {
      name: "Yevgeny",
      size: 13008600,
      interest: "Swimming",
      country: "Australia",
      age: "23",
      gender: "male",
    },
    {
      name: "Dmitri",
      size: 45000000,
      interest: "Travel",
      country: "North America",
      age: "24",
      gender: "male",
    },
    {
      name: "Zoe",
      size: 29500000,
      interest: "Dancing",
      country: "Netherlands",
      age: "41",
      gender: "female",
    },
    {
      name: "Gheorghe",
      size: 980000,
      interest: "Swimming",
      country: "Europe",
      age: "35",
      gender: "male",
    },
    {
      name: "Igor",
      size: 2632002,
      interest: "Makeup Artist",
      country: "Germany",
      age: "30",
      gender: "male",
    },
    {
      name: "Emilia",
      size: 25000000,
      interest: "Beauty",
      country: "France",
      age: "65",
      gender: "female",
    },
    {
      name: "Niklas",
      size: 9100000,
      interest: "Model",
      country: "Europe",
      age: "27",
      gender: "male",
    },
    {
      name: "Bogdan",
      size: 1000000,
      interest: "Beauty",
      country: "Netherlands",
      age: "24",
      gender: "male",
    },
    {
      name: "Emma",
      size: 619999,
      interest: "Singing",
      country: "Europe",
      age: "40",
      gender: "female",
    },
    {
      name: "Luca",
      size: 7200000,
      interest: "Football",
      country: "North America",
      age: "35",
      gender: "male",
    },
    {
      name: "Jan",
      size: 7000000,
      interest: "Cycling",
      country: "Europe",
      age: "30",
      gender: "male",
    },
    {
      name: "Lina D.",
      size: 372500,
      interest: "Water Sports",
      country: "Germany",
      age: "18",
      gender: "female",
    },
    {
      name: "Oliver S.",
      size: 948900,
      interest: "Technology",
      country: "Australia",
      age: "36",
      gender: "male",
    },
    {
      name: "Victor",
      size: 37008925,
      interest: "Entrepreneur",
      country: "North America",
      age: "24",
      gender: "male",
    },
    {
      name: "Daria",
      size: 1678947,
      interest: "Eating out",
      country: "United Kingdom",
      age: "40",
      gender: "female",
    },
    {
      name: "Henrik",
      size: 23000000,
      interest: "UGC",
      country: "Europe",
      age: "35",
      gender: "male",
    },
    {
      name: "Hugo",
      size: 42121238,
      interest: "Thematic",
      country: "South America",
      age: "43",
      gender: "male",
    },
    {
      name: "Aurora",
      size: 47945787,
      interest: "Theatrical",
      country: "France",
      age: "27",
      gender: "female",
    },
    {
      name: "Roman",
      size: 48000000,
      interest: "Editing",
      country: "Germany",
      age: "53",
      gender: "male",
    },
    {
      name: "Ivan",
      size: 800000,
      interest: "Gaming",
      country: "Australia",
      age: "24",
      gender: "male",
    },
    {
      name: "Ella",
      size: 1108773,
      interest: "Entertainment",
      country: "Europe",
      age: "40",
      gender: "female",
    },
    {
      name: "Adrian",
      size: 25460991,
      interest: "Humor & Comedy",
      country: "New Zealand",
      age: "35",
      gender: "male",
    },
    {
      name: "Benjamin",
      size: 1362813,
      interest: "Humor & Comedy",
      country: "Australia",
      age: "34",
      gender: "male",
    },
    {
      name: "Viktoria",
      size: 1500000,
      interest: "Theatrical",
      country: "North America",
      age: "25",
      gender: "female",
    },
    {
      name: "Dmitri",
      size: 46730448,
      interest: "UGC",
      country: "France",
      age: "100",
      gender: "male",
    },
    {
      name: "Oni Adetutu",
      size: 1058479,
      interest: "Cycling",
      country: "Australia",
      age: "18",
      gender: "male",
    },
    {
      name: "Franklin",
      size: 5000000,
      interest: "Technology",
      country: "Kenya",
      age: "88",
      gender: "male",
    },
  ];

  const interestData = [
    "Travel",
    "Cooking",
    "Singing",
    "Dancing",
    "Makeup Artist",
    "Beauty",
    "Model",
    "Football",
    "Singing",
    "Water Sports",
    "Technology",
    "Entrepreneur",
    "Eating out",
    "UGC",
    "Thematic",
    "Theatrical",
    "Editing",
    "Gaming",
    "Entertainment",
    "Humor & Comedy",
  ];
  const locationData = [
    "Australia",
    "Europe",
    "France",
    "Germany",
    "North America",
    "Netherlands",
    "New Zealand",
    "South America",
    "United Kingdom",
  ];

  const [genderFilter, setGenderFilter] = useState(false);

  const [genderValue, setGenderValue] = useState("");

  const handleGenderValue = (gender) => {
    setGenderValue(gender);

    setTimeout(() => {
      setGenderFilter(false);
    }, 2000);
  };

  const [selectedLocation, setSelectedLocation] = useState("");
  const handleLocClick = (value) => {
    setSelectedLocation(value);
  };

  const [selectedInterests, setSelectedInterests] = useState([]);

  const handleInterestClick = (value) => {
    if (!selectedInterests.includes(value)) {
      setSelectedInterests([...selectedInterests, value]);
    } else {
      setSelectedInterests(selectedInterests.filter((item) => item !== value));
    }
  };

  const filteredData = sampleJson.filter((item) => {
    const matchesInterests =
      selectedInterests.length === 0 ||
      selectedInterests.includes(item.interest);

    const matchesLocation =
      selectedLocation === "" || item.country === selectedLocation;

    const matchesGender =
      genderValue === "" ||
      item.gender.toLowerCase() === genderValue.toLowerCase();

    const matchesAge = item.age >= minAge && item.age <= maxAge;

    const matchesAudience = item.size >= minAudience && item.size <= maxAudience;

    return (
      matchesInterests &&
      matchesLocation &&
      matchesGender &&
      matchesAge &&
      matchesAudience
    );
  });




  const formattedValue = (value) => {
    if (value < 1000) {
      return `${value}`;
    } else if (value < 1000000) {
      return `${(value / 1000).toFixed(2)}K`;
    } else {
      return `${(value / 1000000).toFixed(1)}M`;
    }
  }

  const resetFilters = () => {
    setSelectedInterests([]);
    setSelectedLocation("");
    setGenderValue("");
    setPriceFilterResetToggle(!priceFilterResetToggle);
    setMinAge(18);
    setMaxAge(100);
    setMinAudience(1000);
    setMaxAudience(10000000);
  };

  const sliderValues = (event, newValue, customKey) => {
    if (customKey === 'age') {
      setMinAge(newValue[0]);
      setMaxAge(newValue[1]);
    } else {
      setMinAudience(newValue[0]);
      setMaxAudience(newValue[1])
    }
  };

  const getPublicView = async () => {
    try {
      dispatch(getUserPublicProfile({ token, user }));
    } catch (error) {
      console.log('error get public view', error?.response?.data?.error)
      // setErrors(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (user?.role == 1) {
      getPublicView();
    }
  }, []);


  return (
    <>
      <AppLayout>
        <section className="section-color position-relative">
          <div className="first-sec container">
            <div className="row">
              <div className="col-7">
                <div className="col-12 top_content">
                  <div className="LP-heading">
                    <span className="text-highlight">
                      Empowering content creators
                    </span>{" "}
                    one investment at a time.
                  </div>
                </div>
                <p className="col-12 mt-2">
                  A mutually beneficial relationship between social creators and
                  their fans.
                </p>
              </div>
              <div className="col-5 position-relative">
                <img
                  src="/assets/images/banner/Banner-Animation-opt.gif"
                  className="img-fluid ban-img"
                  alt=".1.."
                />
                <img
                  src="/assets/images/banner/lft-ele-opt.png"
                  className="ban-img5 img-fluid position-absolute"
                  alt=".2.."
                />
                <img
                  src="/assets/images/banner/rgt-ele-opt.png"
                  className="ban-img7 img-fluid position-absolute"
                  alt="..3."
                />
                <img
                  src="/assets/images/banner/emoji.webp"
                  className="ban-img8 img-fluid position-absolute"
                  alt="..4."
                />
              </div>
              <div className=" col-12 mt-2 col-md-6 d-flex justify-content-between pb-5">
                <button
                  onClick={() => {
                    if (user === null) {
                      document.getElementById("sign-up-from-LP").click();
                    }
                  }}
                  className="btn col-6 me-3 new-filled-btn "
                >
                  <div className="button-text">Sign Up Now</div>
                  <img
                    src="/assets/images/btn-arrow.svg"
                    alt="->"
                    className="btn-icon"
                  />
                </button>
                <Link
                  to={
                    user !== null
                      ? user?.role === "1"
                        ? "/brandCatalogue"
                        : "/creatorsCatalogue"
                      : null
                  }
                  onClick={() => {
                    if (user === null) {
                      document.getElementById("log-in-from-LP").click();
                    } else return;
                  }}
                  className="btn col-6 new-outline-btn "
                >
                  View Catalogues
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* FIRST SECTION END */}
        <section className="bg-white py-5">
          <div className="creator-section py-5 my-2 my-md-5 ">
            <div className="container">
              <div className="row  m-0">
                <div className="col-12 d-md-none">
                  <h3>
                    Vitrine Creator
                    <span className="text-highlight"> Catalogue</span>
                  </h3>
                </div>
                <div className="col-12 col-md-5">
                  <div className="creators">
                    <div className="rotate_box">
                      <img
                        src="/assets/images/banner/rotate-glass-opt_2.gif"
                        className="rotate_bx img-fluid"
                        alt=".6.sss."
                      />
                      <img
                        src="/assets/images/Birds1-opt.png"
                        className="fly_1 img-fluid"
                        alt="..7."
                      />
                      <img
                        src="/assets/images/Birds2-opt.png"
                        className="fly_2 img-fluid"
                        alt="..8."
                      />
                      <img
                        src="/assets/images/Birds3-opt.png"
                        className="fly_3 img-fluid"
                        alt="..9."
                      />
                      <img
                        src="/assets/images/cloud1-opt.png"
                        className="cloud_1 img-fluid"
                        alt="..10."
                      />
                      <img
                        src="/assets/images/cloud2-opt.png"
                        className="cloud_2 img-fluid"
                        alt="..11."
                      />
                    </div>
                    <img
                      src="/assets/images/podium_landscape -opt.png"
                      className="sticky_bottom img-fluid"
                      alt="..12."
                    />
                  </div>
                </div>
                <div className=" col-12 col-md-7">
                  <h3 className=" d-none d-md-block">
                    Vitrine Creator
                    <span className="text-highlight"> Catalogue</span>
                  </h3>
                  <div className="creator_content">
                    <p>
                      Find the perfect match to increase your visibility. - Join
                      a community of international creators -Make the most of
                      your collaborations.
                    </p>
                    <p className="p2	">
                      A transparent and secure ecosystem for influencer
                      marketing services
                    </p>
                    <div className="browse-creator-btn">
                      <button
                        onClick={() => {
                          if (user === null) {
                            document.getElementById("log-in-from-LP").click();
                          } else {
                            navigate("/creatorsCatalogue");
                          }
                        }}
                        className="btn common-button-style-filled"
                        type="button"
                      >
                        <div className="button-text">Browse Creators</div>
                        <img
                          src="/assets/images/btn-arrow.svg"
                          alt="->"
                          className="btn-icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sec-two-animation position-relative">
                <div className="h-100 animation-box  d-flex flex-column justify-content-between">
                  <div className="row top-animation align-items-end">
                    <div className="col-3 text-center">
                      <img
                        src="/assets/images/new-circle.svg"
                        alt="2"
                        width={30}
                      />
                    </div>
                    <div className="col-3 text-center me-5">
                      <img
                        src="/assets/images/circle.svg"
                        alt="2"
                        width={20}
                        className="me-5"
                      />
                    </div>

                    <div className="col-3 pb-5 mt-5 justify-content-end d-flex">
                      <img src="/assets/images/circle.svg" alt="2" width={30} />
                    </div>
                  </div>
                  <div className="row bottom-animation align-items-end">
                    <div className="col-3 text-center">
                      <img src="/assets/images/circle.svg" alt="2" width={30} />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        src="/assets/images/purple-circle.png"
                        alt="2"
                        width={30}
                      />
                    </div>
                    <div className="col-3 pb-5 mt-5 text-left me-5 me-md-0">
                      <img src="/assets/images/pplus.svg" alt="2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* SECOND SECTION END  */}
        <section className="section-color">
          <div className="container py-5">
            <div className="third-section tailor-sec row m-0 position-relative">
              <div className="col-md-6 col-12">
                <h3 className="pb-4">
                  Tailor, Filter and Connect with The{" "}
                  <span className="text-highlight">Best Suited Creator</span>{" "}
                  for your Brand
                </h3>
                <div className="mb-5 mt-3 col-10 audience-slider-mob col-md-8 d-none d-md-block">
                  <CustomizedSlider
                    customKey={"audience"}
                    minValue={minAudience}
                    maxValue={maxAudience}
                    handleChange={(event, newValue) => sliderValues(event, newValue, "audience")}
                    SliderWidth={250}
                    priceFilterResetToggle={priceFilterResetToggle}
                  />

                </div>
                <div className="d-md-none">
                  <div
                    onClick={() => {
                      setShowFilters(!showFilters);
                    }}
                    className="text-center d-md-none text-highlight fw-bolder link-style my-5 fs-1"
                  >
                    Filters
                    <div className="d-inline-block ms-3">
                      <img
                        src="/assets/images/arrow-sign.png"
                        alt=""
                        width={15}
                        className=""
                      />
                    </div>
                  </div>
                  {showFilters ? (
                    <>
                      <section className="AudienceFilterMob mx-auto text-center pb-5">
                        <div className="text-center">Reach</div>
                        <div className="mb-5 mt-3 mx-auto col-10 col-md-8">
                          <div className="d-flex justify-content-center">
                            <CustomizedSlider
                              customKey={"audience"}
                              minValue={minAudience}
                              maxValue={maxAudience}
                              handleChange={(event, newValue) => sliderValues(event, newValue, "audience")}
                              SliderWidth={290}
                              priceFilterResetToggle={priceFilterResetToggle}
                            />
                          </div>
                        </div>
                      </section>
                      <div className="d-flex flex-column align-items-center">
                        <section className="InterestFilterMob third-section2 my-5 py-5">
                          <div className="LP-filters-container">
                            <div className="row m-0">
                              <div className="col-lg-3 my-5 col-sm-6 col-12 filter-box-design interest-sec interest ms-2 ms-sm-0">
                                <div className="filter-box-icon">
                                  <img
                                    src="/assets/images/interests-icon.svg"
                                    alt="int"
                                    width={100}
                                    className="ms-3"
                                  />
                                </div>
                                <div className="LP-dropdown-button">
                                  <div
                                    className="btn common-button-style-outline ctm_toggle dropdown-toggle"
                                    onClick={dropdownOpen}
                                  >
                                    <div className="d-flex">
                                      <p className="m-0 me-2 w-100 d-flex align-items-center text-truncate">
                                        {selectedInterests &&
                                          selectedInterests.length
                                          ? `Interest : ${selectedInterests}`
                                          : "Interest"}
                                      </p>
                                      <div className="d-inline-block">
                                        <img
                                          src="/assets/images/arrow-sign.png"
                                          alt=""
                                          width={15}
                                          className=""
                                        />
                                      </div>
                                    </div>
                                    <div className="inner-dropdown">
                                      <DropdownForList
                                        data={interestData}
                                        handleClick={handleInterestClick}
                                        selectedValue={selectedInterests}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="LocationFilterMob third-section2 my-5 py-5">
                          <div className="LP-filters-container">
                            <div className="row ps-3 m-0">
                              <div className="col-lg-3 my-5 col-sm-6 col-12 filter-box-design interest-sec g_loct ms-2 ms-sm-0">
                                <div className="filter-box-icon">
                                  <img
                                    src="/assets/images/location-icon.svg"
                                    alt="int"
                                    width={120}
                                    className="m-4"
                                  />
                                </div>
                                <div className="LP-dropdown-button">
                                  <div
                                    className="btn common-button-style-outline ctm_toggle dropdown-toggle"
                                    onClick={dropdownOpen}
                                  >
                                    <div className="d-flex">
                                      <p className="m-0 me-2 w-100 d-flex align-items-center text-truncate">
                                        {selectedLocation
                                          ? `Location : ${selectedLocation}`
                                          : "Location"}
                                      </p>
                                      <div className="d-inline-block">
                                        <img
                                          src="/assets/images/arrow-sign.png"
                                          alt=""
                                          width={15}
                                          className=""
                                        />
                                      </div>
                                    </div>
                                    <div className="inner-dropdown">
                                      <DropdownForList
                                        data={locationData}
                                        handleClick={handleLocClick}
                                        selectedValue={selectedLocation}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="AgeFilterMob third-section2 my-5 py-5">
                          <div className="LP-filters-container">
                            <div className="row m-0">
                              <div className="col-lg-3 my-5 col-sm-6 col-12 filter-box-design position-relative">
                                <div className="filter-box-icon">
                                  <img
                                    src="/assets/images/age-icon1.svg"
                                    alt="int"
                                    width={70}
                                    className="mb-4"
                                  />
                                  <img
                                    src="/assets/images/age-icon2.svg"
                                    alt="int"
                                    width={90}
                                    className="mb-4"
                                  />
                                </div>
                                <div className="LP-dropdown-button">
                                  <button
                                    onClick={() => {
                                      setAgeFilter(!ageFilter);
                                    }}
                                    className="btn common-button-style-outline ms-2 d-flex"
                                    type="button"
                                  >
                                    <div className="w-100">Age</div>
                                    <div className="d-inline-block">
                                      <img
                                        src="/assets/images/arrow-sign.png"
                                        alt=""
                                        width={15}
                                        className=""
                                      />
                                    </div>
                                  </button>
                                </div>
                                <div className="my-5">
                                  {ageFilter ? (
                                    <>
                                      <div className="mb-5 mt-3 mx-auto col-12 col-md-8">
                                        <div className="d-flex justify-content-center">
                                          <CustomizedSlider
                                            customKey={"age"}
                                            minValue={minAge}
                                            maxValue={(maxAge)}
                                            handleChange={(event, newValue) => sliderValues(event, newValue, "age")}
                                            SliderWidth={290}
                                            priceFilterResetToggle={priceFilterResetToggle}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="GenderFilterMob third-section2 my-5 py-5">
                          <div className="LP-filters-container">
                            <div className="row m-0">
                              <div className="col-lg-3 my-5 col-sm-6 col-12 filter-box-design">
                                <div className="filter-box-icon">
                                  <img
                                    src="/assets/images/gender-icon.svg"
                                    alt="int"
                                    width={120}
                                    className="m-4"
                                  />
                                </div>
                                <div className="LP-dropdown-button">
                                  <button
                                    onClick={() => {
                                      setGenderFilter(!genderFilter);
                                    }}
                                    className="btn common-button-style-outline ms-2 d-flex"
                                    type="button"
                                  >
                                    <div className="w-100"> Gender</div>
                                    <div className="d-inline-block">
                                      <img
                                        src="/assets/images/arrow-sign.png"
                                        alt=""
                                        width={15}
                                        className=""
                                      />
                                    </div>
                                  </button>
                                </div>
                                {/* todo */}
                                <div className="my-5">
                                  {genderFilter ? (
                                    <>
                                      <GenderSelect
                                        handleSelectGender={handleGenderValue}
                                      />
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="browse-creator-btn d-none d-md-block py-5">
                  <button
                    className="btn common-button-style-filled"
                    type="button"
                  >
                    <div className="button-text">Reach</div>
                    <img
                      src="/assets/images/btn-arrow.svg"
                      alt="->"
                      className="btn-icon"
                    />
                  </button>
                </div>
              </div>
              <div className="col-md-6 d-none d-md-block position-relative ">
                <div className="white-circle-bg d-flex justify-content-center">
                  <img
                    src="/assets/images/Ring-Animation-cartoon-opt.gif"
                    className="circle-boy "
                    alt=".18.."
                    width={250}
                  />
                </div>
              </div>

              {/* ANIMATIONS  */}
              <div
                className="sec-height d-none d-md-block"
                style={{
                  position: "absolute",
                  top: 0,
                  width: "50%",
                  right: '0%',
                  height: "100%",
                }}
              >
                <div className="animation-item">
                  <div className="anim-img anim-5">
                    <img
                      src="/assets/images/banner/emoji.png"
                      alt="5"
                      width={120}
                    />
                  </div>
                  <div className="anim-img anim-1">
                    <img src="/assets/images/circle.svg" alt="1" width={20} />
                  </div>
                  <div className="anim-img anim-2">
                    <img src="/assets/images/new-circle.svg" alt="2" />
                  </div>
                  <div className="anim-img anim-3">
                    <img src="/assets/images/pplus.svg" alt="3" width={20} />
                  </div>
                  <div className="anim-img anim-4">
                    <img src="/assets/images/message-icon.svg" alt="4" />
                  </div>
                  <div className="anim-img anim-6">
                    <img src="/assets/images/new-circle.svg" alt="6" />
                  </div>
                  <div className="anim-img anim-7">
                    <img src="/assets/images/circle.svg" alt="7" width={20} />
                  </div>
                </div>
              </div>
              {/* ANIMATIONS  */}
            </div>
          </div>
        </section>
        {/* THIRD SECTION END  */}
        <section className="d-none d-md-block bg-white pb-5">
          <div className="third-section2 container filters-height position-relative pb-5">
            <div className="position-relative filter-sec">
              <img
                src="/assets/images/right-footimg-opt.png"
                className="right-footimg img-fluid cloud-position"
                alt="..."
                width={180}
              />
              <h3 className="filter-heading">
                Apply <span className="text-highlight">Filters</span>
              </h3>
              <div className="row m-0 md-height">
                <div className="col-md-6 col-xl-3 filter-box-design interest-sec interest">
                  <div className="filter-box-icon">
                    <img
                      src="/assets/images/interests-icon.svg"
                      alt="int"
                      width={100}
                      className="ms-3"
                    />
                  </div>
                  <div className="LP-dropdown-button">
                    <div
                      className="btn common-button-style-outline ctm_toggle dropdown-toggle"
                      onClick={dropdownOpen}
                    >
                      <div className="d-flex">
                        <p className="m-0 me-2 w-100 d-flex align-items-center text-truncate">
                          {selectedInterests && selectedInterests.length
                            ? `Interest : ${selectedInterests}`
                            : "Interest"}
                        </p>
                        <div className="d-inline-block">
                          <img
                            src="/assets/images/arrow-sign.png"
                            alt=""
                            width={15}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="inner-dropdown">
                        <DropdownForList
                          data={interestData}
                          handleClick={handleInterestClick}
                          selectedValue={selectedInterests}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3 filter-box-design interest-sec g_loct">
                  <div className="filter-box-icon">
                    <img
                      src="/assets/images/location-icon.svg"
                      alt="int"
                      width={120}
                      className="m-4"
                    />
                  </div>
                  <div className="LP-dropdown-button">
                    <div
                      className="btn common-button-style-outline ctm_toggle dropdown-toggle"
                      onClick={dropdownOpen}
                    >
                      <div className="d-flex">
                        <p className="m-0 me-2 w-100 d-flex align-items-center text-truncate">
                          {selectedLocation
                            ? `Location : ${selectedLocation}`
                            : "Location"}
                        </p>
                        <div className="d-inline-block">
                          <img
                            src="/assets/images/arrow-sign.png"
                            alt=""
                            width={15}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="inner-dropdown">
                        <DropdownForList
                          data={locationData}
                          handleClick={handleLocClick}
                          selectedValue={selectedLocation}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="map-section position-absolute ms-4">
                      <img
                        src="/assets/images/map.png"
                        className="img-fluid pt-3"
                        alt="Map"
                      />
                    </div> */}
                </div>
                <div className="col-md-6 col-xl-3 filter-box-design">
                  <div className="filter-box-icon">
                    <img
                      src="/assets/images/age-icon1.svg"
                      alt="int"
                      width={70}
                      className="mb-4"
                    />
                    <img
                      src="/assets/images/age-icon2.svg"
                      alt="int"
                      width={90}
                      className="mb-4"
                    />
                  </div>
                  <div className="LP-dropdown-button">
                    <button
                      onClick={() => {
                        setAgeFilter(!ageFilter);
                      }}
                      className="btn common-button-style-outline ms-2 d-flex"
                      type="button"
                    >
                      <div className="w-100">Age</div>
                      <div className="d-inline-block">
                        <img
                          src="/assets/images/arrow-sign.png"
                          alt=""
                          width={15}
                          className=""
                        />
                      </div>
                    </button>
                  </div>
                  <div className="my-2 pos">
                    {ageFilter ? (
                      <>
                        <div className="m-0 m-sm-0 mx-sm-5">
                          <CustomizedSlider
                            customKey={"age"}
                            minValue={minAge}
                            maxValue={maxAge}
                            handleChange={(event, newValue) => sliderValues(event, newValue, "age")}
                            SliderWidth={200}
                            priceFilterResetToggle={priceFilterResetToggle}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-xl-3 filter-box-design">
                  <div className="filter-box-icon">
                    <img
                      src="/assets/images/gender-icon.svg"
                      alt="int"
                      width={120}
                      className="m-4"
                    />
                  </div>
                  <div className="LP-dropdown-button">
                    <button
                      onClick={() => {
                        setGenderFilter(!genderFilter);
                      }}
                      className="btn common-button-style-outline ms-2 d-flex"
                      type="button"
                    >
                      <div className="w-100"> Gender</div>
                      <div className="d-inline-block">
                        <img
                          src="/assets/images/arrow-sign.png"
                          alt=""
                          width={15}
                          className=""
                        />
                      </div>
                    </button>
                  </div>
                  {/* Full Screen */}
                  <div className="my-5">
                    {genderFilter ? (
                      <>
                        <GenderSelect gender={genderValue} handleSelectGender={handleGenderValue} />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* ANIMATIONS  */}
            <div
              className="sec-height container"
              style={{
                position: "absolute",
                zIndex: "",
                bottom: 0,
                width: "100%",
              }}
            >
              <div className="animation-item">
                <div className="anim-img anim-8">
                  <img src="/assets/images/pplus.svg" alt="8" width={20} />
                </div>
                <div className="anim-img anim-9">
                  <img src="/assets/images/circle.svg" alt="9" width={20} />
                </div>
                <div className="anim-img anim-10">
                  <img src="/assets/images/cloud2.png" alt="10" width={120} />
                </div>
                <div className="anim-img anim-11">
                  <img
                    src="/assets/images/yellow-circle.svg"
                    alt="11"
                    width={25}
                  />
                </div>
                <div className="anim-img anim-12">
                  <img src="/assets/images/pplus.svg" alt="12" width={20} />
                </div>
                <div className="anim-img anim-13">
                  <img
                    src="/assets/images/yellow-circle.svg"
                    alt="13"
                    width={25}
                  />
                </div>
                <div className="anim-img anim-14">
                  <img src="/assets/images/pplus.svg" alt="14" width={20} />
                </div>
              </div>
            </div>
            {/* ANIMATIONS  */}
          </div>
        </section>
        {/* FOURTH SECTION END  */}
        <section className="section-color-rounded">
          <div className="position-relative">
            <div className="container pt-sm-5">
              <div className="col-12 mt-5 py-4">
                <div className="row m-0">
                  <h2 className="col-7 m-auto">
                    Creators
                    <span className="text-highlight"> Detail</span>
                  </h2>
                  <button
                    onClick={resetFilters}
                    className="btn col-5 col-md-4 new-filled-btn me-md-3"
                    type="button"
                  >
                    <div className="button-text">Reset Filters</div>
                    <img
                      src="/assets/images/btn-arrow.svg"
                      alt="->"
                      className="btn-icon"
                    />
                  </button>
                </div>
              </div>
              <div className="col-12 table-colm bg-white">
                <table className="table">
                  <thead className="">
                    <tr className="bg-danger head-color">
                      <th className="col text-white py-4">Creators Name</th>
                      <th className="col text-white py-4">Reach</th>
                      <th className="col text-white py-4">Audience Interest</th>
                      <th className="col text-white py-4">Location</th>
                      <th className="col text-white py-4">Age</th>
                      <th className="col text-white py-4">Gender</th>
                    </tr>
                  </thead>
                  <tbody id="creators">
                    {filteredData.map((sample, index) => {
                      const isEvenRow = index % 2 === 0;
                      const rowClass = isEvenRow
                        ? "bg-white"
                        : "table-row-highlight";

                      return (
                        <tr className={`onHoverRow ${rowClass}`} key={index}>
                          <td>{sample.name}</td>
                          <td>{formattedValue(sample.size)}</td>
                          <td>{sample.interest}</td>
                          <td>{sample.country}</td>
                          <td>{sample.age}</td>
                          <td>{sample.gender}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="sec-height container"
              style={{
                position: "absolute",
                bottom: "-90px",
                width: "56%",
              }}
            >
              <div className="animation-item">
                <div className="anim-img anim-15">
                  <img src="/assets/images/pplus.svg" alt="15" width={20} />
                </div>
                <div className="anim-img anim-16">
                  <img
                    src="/assets/images/new-circle.svg"
                    alt="16"
                    width={20}
                  />
                </div>
                <div className="anim-img anim-17">
                  <img
                    src="/assets/images/new-circle.svg"
                    alt="17"
                    width={25}
                  />
                </div>
                <div className="anim-img anim-18">
                  <img
                    src="/assets/images/new-circle.svg"
                    alt="18"
                    width={30}
                  />
                </div>
                <div className="anim-img anim-19">
                  <img src="/assets/images/circle.svg" alt="19" width={20} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FIFTH SECTION END  */}
      </AppLayout>
    </>
  );
};

export default VitrineLive;
