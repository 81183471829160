import React, { useState } from "react";

const DropdownForList = ({ data, selectedValue, handleClick }) => {


  return (
    <>
      <div className="dropdown-list-data">

        {data?.map((item, index) => (
          <div
            className={`text-truncate d-flex ps-1 pe-2 rounded-3 justify-content-between ${selectedValue?.includes(item) ? "selected" : ""}`}
            key={index}
            onClick={() => handleClick(item)}
          >
            {item}
            {selectedValue?.includes(item) && <span className="tick-mark">&#10004;</span>}
          </div>
        ))}
      </div>
    </>
  );
};

export default DropdownForList;
