import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import dayjs from "dayjs";

const TimePickerClock = ({
  activeFrom,
  activeTo,
  setActiveFrom,
  setActiveTo,
}) => {
  const parseTimeString = (timeString) => {
    if (timeString) {
      if (timeString.length < 6 && timeString.length > 0) {
        timeString += ":00";
      }
    }
    return timeString ? dayjs(timeString, "HH:mm:ss") : null;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        value={parseTimeString(activeFrom) || parseTimeString(activeTo) || null}
        onChange={(newTime) => {
          if (setActiveFrom) {
            setActiveFrom(newTime ? newTime : null);
          } else if (setActiveTo) {
            setActiveTo(newTime ? newTime : null);
          }
        }}
        renderInput={(params) => (
          <input
            type="text"
            {...params.inputProps}
            className="ant-select-selection-search-input w-100"
            value={activeFrom || activeTo}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default TimePickerClock;
