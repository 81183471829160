import React, { useEffect, useState } from "react";
import AppLayout from "../Components/AppLayout";

import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";

import { useNavigate, useSearchParams } from "react-router-dom";
import { ResetPasswordApi } from "../Common/Repository";
import { resetLoadSuccessStates } from "../states/auth/authSlice";

export default function ForgotPasswordPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const { updatePassLoading, updatePassSuccess, updatePassError } = useSelector(
    (state) => state.auth
  );

  const handleSubmitPress = async () => {
    try {
      let data = {};
      passFields.forEach((field) => {
        data[field.key] = field.value;
      });
      const clearedFields = passFields.map((field) => ({
        ...field,
        error: "",
      }));
      setPassFields(clearedFields);

      data["email"] = email;
      data["code"] = verificationCode;

      dispatch(ResetPasswordApi({ data }));
    } catch (error) {

    }
  };

  useEffect(() => {
    setEmail(searchParams.get("email"));
    setVerificationCode(searchParams.get("code"));
  }, []);

  useEffect(() => {
    if (updatePassSuccess) {
      dispatch(resetLoadSuccessStates());
      navigate("/");
    }
  }, [updatePassSuccess]);

  const [passFields, setPassFields] = useState([
    {
      type: "password",
      key: "password",
      label: "New Password:",
      value: "",
      error: "",
      placeholder: "Enter a new password*",
    },
    {
      type: "password",
      key: "password_confirmation",
      label: "Confirm New Password:",
      value: "",
      error: "",
      placeholder: "Enter same password again*",
    },
  ]);

  const handleInputChange = (event, fieldKey) => {
    const updatedFields = passFields.map((field) => {
      if (field.key === fieldKey) {
        // dispatch(clearError());
        return {
          ...field,
          value: event.target.value,
          error: "",
        };
      }
      return field;
    });
    setPassFields(updatedFields);
  };

  useEffect(() => {
    if (updatePassError && updatePassError !== undefined)
      updateErrorInForgotPass(updatePassError);
  }, [updatePassError]);

  const updateErrorInForgotPass = (errors) => {
    const newFields = passFields.map((field) => {
      return {
        ...field,
        error: errors[field.key]?.[0] || "",
      };
    });
    setPassFields(newFields);
  };

  return (
    <AppLayout>
      <>
        <div className="container-box-style">
          <div className="m-auto">
            <h2 className="text-center my-5">Forgot Password</h2>
          </div>
          <div className="row box-bg">
            <div className="col-12">
              <div className="row position-relative">
                {passFields.map((field) => (
                  <>
                    <div className="col-md-6 input-style" key={field.key}>
                      
                          <div className="text-muted m-3"> {field.label} </div>

                          <input
                            type={field.type}
                            name={field.key}
                            value={field.value}
                            className="form-control my-1"
                            placeholder={field.placeholder}
                            onChange={(event) =>
                              handleInputChange(event, field.key)
                            }
                          />
                       
                      <div className="p-3 text-danger"> {field.error} </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="row">
              <p className="justify-content-center text-center mt-4 mb-2">* fields are required</p>
                <div className="action-btns d-flex my-3 justify-content-between">
                  <div
                    onClick={() => {
                      navigate(-1);
                      setPassFields([
                        {
                          type: "password",
                          key: "password",
                          label: "New Password:",
                          value: "",
                          error: "",
                          placeholder: "Enter a new password",
                        },
                        {
                          type: "password",
                          key: "confirm_password",
                          label: "Confirm New Password:",
                          value: "",
                          error: "",
                          placeholder: "Enter same password again",
                        },
                      ]);
                    }}
                    className="common-button-style-outline width-40 me-5 align-center"
                  >
                    Cancel
                  </div>
                  <div
                    className="common-button-style-filled width-40 align-center"
                    onClick={() => handleSubmitPress()}
                    disabled={updatePassLoading}
                  >
                    {updatePassLoading ? (
                      <PuffLoader color="white" size={28} />
                    ) : (
                      "Change Password"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </AppLayout>
  );
}
