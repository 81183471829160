import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { FilterCampaignsCreator, getCampaigns } from "../../Common/Repository";

const initialState = {
  loading: false,
  creatorCampaignData: [],
  error: null,
  success: false,
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCampaigns: (state, action) => {
      state.creatorCampaignData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        state.creatorCampaignData = [];
      })
      .addCase(getCampaigns.pending, (state) => {
        if (!state.creatorCampaignData.length) {
          state.loading = true;
        }
        state.error = null;
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        state.creatorCampaignData = action.payload?.data?.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(FilterCampaignsCreator.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(FilterCampaignsCreator.fulfilled, (state, action) => {
        state.creatorCampaignData = action.payload?.data?.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(FilterCampaignsCreator.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setCampaigns } = campaignsSlice.actions;
export default campaignsSlice.reducer;
