import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { getMyCollaborationsCall } from "../../Common/Repository";

const initialState = {
    loading: false,
    collaborationsData: [],
    error: null,
    success: false,
};



const collaborationsSlice = createSlice({
    name: 'collaborations',
    initialState,
    reducers: {
        setCollaborations: (state, action) => {
            state.collaborationsData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMyCollaborationsCall.pending, (state) => {
                if (!state.collaborationsData.length) {
                    state.loading = true;
                }
                state.error = null;
            })
            .addCase(getMyCollaborationsCall.fulfilled, (state, action) => {
                state.collaborationsData = action.payload?.data?.data?.collaborations;
                state.loading = false;
                state.error = null;
            })
            .addCase(getMyCollaborationsCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(logout, (state) => {
                return initialState;
            })
    },
});

export const { setCollaborations } = collaborationsSlice.actions;
export default collaborationsSlice.reducer;
