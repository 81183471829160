import React, { useState, useRef, useEffect } from "react";
import {
  ResendOtpApi,
  resendOtpCall,
  VerifyOtpApi,
} from "../../Common/Repository";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { logout, resetLoadSuccessStates } from "../../states/auth/authSlice";

const OtpVerification = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const [seconds, setSeconds] = useState(60);

  const [messages, setMessages] = useState(null);

  const inputRefs = useRef([]);

  const {
    user,
    otpLoading,
    otpSuccess,
    resendOtpSuccess,
    error,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user?.otp_code_time) {
      // Convert otp_code_time to a Date object
      const otpCodeTime = new Date(user.otp_code_time)
      const browserTimezoneOffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
      const otpCodeTimeBrowserTimezone = new Date(otpCodeTime - browserTimezoneOffset);
      const currentTime = new Date();
      const elapsedSeconds = Math.floor((currentTime - otpCodeTimeBrowserTimezone) / 1000);
      const remainingSeconds = Math.max(60 - elapsedSeconds, 0);

      setSeconds(remainingSeconds);
    }
  }, [user?.otp_code_time]);



  useEffect(() => {
    // console.log('user: ', user);
  }, [user])

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [seconds]);

  useEffect(() => {
    if (error?.payload?.error) {
      setMessages(error?.payload?.error);
    }
    if (error?.message) {
      setMessages(error?.message)
    }
  }, [error]);

  useEffect(() => {
    if (otpSuccess) {
      navigate("/", {
        state: {
          openLoginModal: true,
        },
      });
      dispatch(resetLoadSuccessStates());
      toast.success("OTP verified successfully!");
    }
    if (resendOtpSuccess) {
      dispatch(resetLoadSuccessStates());
      // setSeconds(60);
    }
  }, [otpSuccess, resendOtpSuccess]);

  const handleInputChange = (index, value) => {
    setMessages(null);
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    setMessages("");
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text/plain");
    const pastedOtp = pastedData.slice(0, 6).split("");

    const newOtp = [...otp];
    for (let i = 0; i < 6; i++) {
      if (pastedOtp[i]) {
        newOtp[i] = pastedOtp[i];
      } else {
        newOtp[i] = "";
      }
    }
    setOtp(newOtp);
  };

  const handleOtpVerifyPress = async () => {
    try {
      const data = {};
      data.otp_code = otp.join("");
      data.email = user?.email;
      dispatch(VerifyOtpApi({ data }));
    } catch (error) {
      if (error?.response?.data?.message) {
        setMessages(error?.response?.data?.message);
        toast.error("Failed to verify OTP!");
      }
    }
  };

  const handleResendOtp = async () => {
    setOtp(["", "", "", "", "", ""]);
    setMessages("");
    try {
      const data = {};
      data.user_id = user.id;
      dispatch(ResendOtpApi({ data }));
    } catch (error) {
      toast.error("Failed to resend OTP!");
    }
  };

  window.onpopstate = function () {
    dispatch(logout());
  }


  return (
    <>
      <div className="d-flex-col justify-content-center">
        <div
          className="logOutVerify pointer-cursor"
          onClick={() => {
            dispatch(logout());
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }}
        >
          Log out
        </div>
        <div className="otp-verification-box justify-content-center">
          <div className="my-4 text-center">
            <h4>Verification</h4>
          </div>
          <div className="user-profile-img-box">
            <svg
              width={100}
              height={100}
              x={0}
              y={0}
              viewBox="0 0 512 512"
              style={{ enableBackground: "new 0 0 512 512" }}
              xmlSpace="preserve"
              className
            >
              <g>
                <path
                  fill="#fde5c9"
                  d="M313.32 318.51c-16.61 17.75-36.27 27.12-56.91 27.12l-1.02-.01c-21.97-.32-42.86-11.1-60.42-31.18a113.97 113.97 0 0 1-3.4-4.06c-1.15 23.55-7.48 37.16-17.45 46.25 22.84 19.47 51.57 45.45 81.88 45.45 30.32 0 59.05-25.98 81.89-45.45-10-9.12-16.35-22.78-17.47-46.46-2.28 2.93-4.64 5.71-7.1 8.34z"
                  data-original="#fde5c9"
                />
                <path
                  fill="#ffa721"
                  d="M53.89 482.36c5-69.82 37.53-90.09 68.53-102.07 41.85 32.53 91.34 37.07 142.15 37.44 48.3-1.2 70.53-11.91 128.84-35.92 30.1 12.33 60.5 34.04 64.84 102.55l-.14-2-55.9-11.52 9.65 41.16H100.14v-29.64z"
                  data-original="#ffa721"
                  className
                />
                <path
                  fill="#fde5c9"
                  d="m100.14 512 9.65-41.16-55.9 11.52c-.44 6.07-.67 12.51-.67 19.35 0 2.73 1.09 5.35 3.02 7.28s4.54 3.01 7.27 3.01z"
                  data-original="#fde5c9"
                />
                <path
                  fill="#e6961e"
                  d="M53.89 482.36c.53-7.4 1.37-14.24 2.49-20.58h43.76v-3.8c0-5.68 4.61-10.29 10.29-10.29s10.29 4.61 10.29 10.29v3.8h3.11c5.68 0 10.28 4.61 10.28 10.29s-4.6 10.29-10.28 10.29h-3.11V512h-20.58v-29.64zM122.42 380.29c4.67-1.8 9.31-3.42 13.81-4.99 15.23-5.31 28.41-9.91 38.1-18.87 29.83 30.24 119.98 41.56 163.4.06 9.69 8.92 22.85 13.51 38.04 18.81 5.73 2 11.68 4.07 17.64 6.51a191.792 191.792 0 0 1-134.73 55.21c-51.4 0-100.31-20.52-136.26-56.73z"
                  data-original="#e6961e"
                  className
                />
                <path
                  fill="#fddfbc"
                  d="M256.41 345.63c-.34 0-.68 0-1.02-.01-21.97-.32-42.86-11.1-60.42-31.18-16.53-18.91-29.28-45.5-37.07-77.25-19.26-3.14-33.11-15.57-36.16-33.18-2.85-16.4 5.09-37.15 29.24-46.54 5.3-2.06 6.74-3.29 8.8 2.01.53 1.35.75 2.76.69 4.13.93-12.44 15.72-67.56 20.53-73.17 17.29 8.87 34.08 13.36 50.01 13.36 36.74 0 93.21 9.56 102.65-3.11 43.09 20.65 10.62 72.85 10.64 74.68.02 2.14 3.08-3.11 4.23-1.45-1.87-2.71-4.8.98-3.52-2.33 2.06-5.3 4.05-6.46 9.35-4.4 20.36 7.91 32.18 26.17 29.41 45.43-2.45 17.05-15.24 29.72-33.37 33.72-8.36 30.53-20.82 54.78-37.08 72.17-16.61 17.75-36.27 27.12-56.91 27.12zm-46.98-45.9.82.97c-.26-.34-.53-.66-.82-.97zm143.83-122.08z"
                  data-original="#fddfbc"
                />
                <path
                  fill="#423e4f"
                  d="M350.63 187.06c-.69 0-1.38-.07-2.08-.21a10.29 10.29 0 0 1-8.21-10.03c-.03-1.83-1.1-39.41-44.19-60.06-9.43 12.68-31.75 35.93-68.49 35.93-15.93 0-32.72-4.49-50.01-13.36-4.85 5.65-11.84 15.84-12.68 28.41-.3 4.58-3.6 8.4-8.09 9.37-4.47.97-9.07-1.15-11.23-5.19-1.58-2.94-38.05-72.85 13.64-145.23.43-.59.92-1.15 1.46-1.64C161.88 24.03 188.81 0 224.71 0c18.05 0 35.05 6.01 50.58 17.88 13.5 1.57 64.07 9.98 88.12 49.44 17.7 29.04 16.58 67.23-3.33 113.52a10.31 10.31 0 0 1-9.45 6.22z"
                  data-original="#423e4f"
                  className
                />
                <path
                  fill="#fde5c9"
                  d="m411.86 512-9.65-41.16 55.9 11.52c.44 6.07.67 12.51.67 19.35 0 2.73-1.09 5.35-3.02 7.28s-4.54 3.01-7.27 3.01z"
                  data-original="#fde5c9"
                />
                <path
                  fill="#e6961e"
                  d="M458.11 482.36c-.53-7.4-1.37-14.24-2.49-20.58h-43.76v-3.8c0-5.68-4.61-10.29-10.29-10.29s-10.29 4.61-10.29 10.29v3.8h-3.11c-5.68 0-10.28 4.61-10.28 10.29s4.6 10.29 10.28 10.29h3.11V512h20.58v-29.64z"
                  data-original="#e6961e"
                  className
                />
              </g>
            </svg>
          </div>
          <div className="text-center">
            <p className="my-3 px-3">
              Please enter the one time password to verify your account
            </p>
            <p className="bottom-text">A code has been sent to <span className="text-highlight text-decoration-underline fw-bold"> {user?.email} </span> </p>
          </div>

          <div className="inputs d-flex flex-row justify-content-center">
            {otp.map((value, index) => (
              <div className="otp-box" key={index}>
                <input
                  className="m-2 text-center otp-input-field form-control"
                  type="text"
                  maxLength={1}
                  value={value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyUp={(e) => handleKeyDown(index, e)}
                  onPaste={handlePaste}
                  ref={(input) => (inputRefs.current[index] = input)}
                />
              </div>
            ))}
          </div>
          <div className="my-1 text-center">
            {messages != null ? (
              <small className="text-danger mb-2">{messages}</small>
            ) : null}
          </div>
          <div className="text-center">
            <a className="">
              <small className="">
                If you didn't receive a code!
                <span
                  className={`${seconds > 0 ? "text-muted" : "text-danger"
                    } ms-1 text-decoration-underline pointer-cursor`}
                  onClick={() => {
                    if (seconds <= 0) {
                      dispatch(resetLoadSuccessStates());
                      handleResendOtp();
                    }
                  }}
                >
                  Resend {`${seconds > 0 ? "(" + seconds + ")" : ""}`}
                </span>
              </small>
            </a>
          </div>

          <div className="d-flex mt-3 mb-4 justify-content-center">
            {otpLoading ? (
              <PuffLoader color="#65acae" size={42} />
            ) : (
              <button
                onClick={() => handleOtpVerifyPress()}
                className="btn common-button-style-filled border-0"
              >
                Verify
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
