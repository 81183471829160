import React from "react";
import { FailureIcon, SuccessIcon } from "./Icons";
import { useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";





const NotificationsPanel = (props) => {

  const navigate = useNavigate();

  const { notifications, notificationLoading } = useSelector((state) => state.auth);


  const chatShowDate = (time) => {
    const date = new Date(time);
    const now = new Date();
    const diffMilliseconds = now.getTime() - date.getTime();
    const diffSeconds = Math.floor(diffMilliseconds / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffSeconds < 60) {
      // less than 1 minute ago
      return 'just now';
    } else if (diffMinutes < 60) {
      // less than 1 hour ago
      return `${diffMinutes} minutes ago`;
    } else if (diffHours < 24) {
      // less than 1 day ago
      return `${diffHours} hours ago`;
    } else if (diffDays < 7) {
      // less than 1 week ago
      return `${diffDays} ${diffDays === 1 ? 'day' : 'days'} ago`;
    } else {
      // more than 1 week ago, show only date
      return date.toLocaleDateString();
    }
  };


  const sortedNotifications = notifications.slice().sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });


  return (
    <>
      <div className="notificationPanel">
        <div className="notificationHeader">
          <h4>Notifications</h4>
          <div
            className="close-notification"
            onClick={() => {
              props.setNotifications(false);
            }}
          >
            <img src="/assets/images/basil_cross-outline.png" alt="X" />
          </div>
        </div>

        {notificationLoading ?
          <>
            <div className="p-5 m-5 d-flex justify-content-center">
              <PuffLoader color="#65acae" size={42} />
            </div>
          </>
          :
          <>
            <div className="notification-body">

              {/* single noti  */}

              {sortedNotifications?.length ? sortedNotifications?.map((notiData) => {
                return (
                  <>
                    <div
                      onClick={() => {
                        if (notiData?.campaign_id) {
                          navigate("/campaignDetailsSection", {
                            state: notiData?.campaign_id
                          })
                        } else if (notiData?.pack_id) {
                          navigate("/view-pack", {
                            state: { packId: notiData?.pack_id, creatorId: null }
                          })
                        } else if (notiData?.brand_id) {
                          navigate("/brandDetails", {
                            state: notiData?.brand_id
                          })
                        } else if (notiData?.creator_id) {
                          navigate("/creatorDetails", {
                            state: notiData?.creator_id
                          })
                        } else if (notiData?.collaboration_id) {
                          navigate("/collabrationDetails", {
                            state: notiData?.collaboration_id
                          })
                        }
                      }}
                      className="notificationItem p-3">
                      <div className="notificationIcon">
                        {(notiData?.title === "Profile update" || notiData?.title === "Profile Update" || notiData?.title === "Campaign Submitted" || notiData?.title === "Campaign Saved" || notiData?.title === "Information Approved" || notiData?.title === "Campaign Collaboration Request" || notiData?.title === "Campaign Approved" || notiData?.title === "Collaboration Invite" || notiData?.title === "Pack Proposal Approved" || notiData?.title === "Pack buy" || notiData?.title === "Pack Updated") ? <SuccessIcon /> : <FailureIcon />}

                      </div>
                      <div className="notificationColumn">
                        <div className="my-auto ms-3 notificationTitle">
                          {notiData?.title}
                        </div>
                        <div className="d-inline-block my-auto ms-3 d-flex justify-content-between notificationSubtitle">
                          <div style={{
                            maxWidth: "400px",
                          }}>
                            {notiData?.body}
                          </div>
                          <div>{chatShowDate(notiData?.created_at)} </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }) : null}
            </div>
          </>
        }



      </div>
    </>
  );
};

export default NotificationsPanel;
