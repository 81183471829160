import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import { useNavigate } from "react-router-dom";
import { filterPacks, getMyPacks } from "../../Common/Repository";
import { FullSearchIcon } from "../../Components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { setPacks } from "../../states/packs/packSlice";
import { PuffLoader } from "react-spinners";

const Packs = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.auth);

  const { packCatalogueData, loading } = useSelector((state) => state.packs);

  const [search, setSearch] = useState("");

  const [selectedDate, setSelectedDate] = useState("");

  const [errors, setErrors] = useState([]);

  const [firstTime, setFirstTime] = useState(true);

  const [filterApplied, setFilterApplied] = useState(false);

  const getPackFromServer = async () => {
    try {
      dispatch(getMyPacks({ token }));
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    } else {
      if (selectedDate) {
        filterPackFromServer();
      } else {
        getPackFromServer();
      }
    }
    // eslint-disable-next-line
  }, [selectedDate]);

  const filterPackFromServer = async () => {
    try {
      let formData = {};
      // if (dateSelected.length > 0) {
      //   formData.creation_date = dateSelected;
      // }

      if (selectedDate.length > 0) {
        formData.creation_date = selectedDate;
      }

      if (search.length > 0) {
        formData.title = search;
      }
      let response = await filterPacks(
        {
          Authorization: `Bearer ${token}`,
        },
        formData
      );
      if (response?.status === 200) {
        dispatch(setPacks(response?.data?.data?.data));
        dropdownOpen();
        // setPackCatalogueData(response?.data?.data?.data);
        // TODO: Show success reponse
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getPackFromServer();
    // eslint-disable-next-line
  }, []);

  const handleCreatePackClick = () => {
    navigate("/create-pack");
  };

  const handlePackClick = (packId) => {
    navigate("/view-pack", { state: { packId: packId, creatorId: null } });
  };

  const dropdownOpen = (event) => {
    if (event) {
      const obj = event.currentTarget;
      if (window.$(obj).find(".inner-dropdown").hasClass("open")) {
        window.$(obj).find(".inner-dropdown").removeClass("open");
      } else {
        window.$(".inner-dropdown").removeClass("open");
        window.$(obj).find(".inner-dropdown").addClass("open");
        window.$(obj).find(".inner-dropdown").removeClass("d-none");
      }
    } else {
      window.$(".inner-dropdown").removeClass("open");
    }
  };
  useEffect(() => {
    dropdownOpen();
  }, []);

  const handleDateChange = (e) => {
    if (e.target.value !== "") {
      setFilterApplied(true);
    }
    setSelectedDate(e.target.value);
    filterPackFromServer();
  };

  // const dropdownBox = (event) => {
  //   event.stopPropagation();
  // };

  const resetFilters = () => {
    setFilterApplied(false);
    setSelectedDate("");
    setSearch("");
    getPackFromServer();
  };

  return (
    <AppLayout>
      <div className="categories-dashboard p-5">
        <div className="container container-animation">
          <div className="categories-dashboard packCatalogue">
            <div className="fixed-secondary-bar searchBar">
              <div className="search-bar-input-container w-100 px-2 mobile-col">
                <div className="search-bar-input-search input-search">
                  <div className="d-flex align-items-center position-relative search-bar--input-container -tags">
                    <div
                      className="search-tag-position"
                      onClick={() => {
                        if (search.length) {
                          filterPackFromServer();
                        }
                      }}
                    >
                      <FullSearchIcon
                        color={!search.length ? "#93BFC0" : null}
                        width="133px"
                      />
                    </div>
                    <span className="d-flex align-items-center w-100">
                      <div
                        className="ant-select ant-select-auto-complete search-bar--input css-10evijz ant-select-single ant-select-show-search w-100"
                        autoComplete="off"
                      >
                        <div className="ant-select-selector w-100">
                          <span className="ant-select-selection-search w-100">
                            <input
                              type="text"
                              className="ant-select-selection-search-input w-100"
                              id="search"
                              placeholder="Search by title"
                              role="combobox"
                              aria-haspopup="listbox"
                              aria-owns="rc_select_8_list"
                              aria-autocomplete="list"
                              aria-controls="rc_select_8_list"
                              aria-activedescendant="rc_select_8_list_-1"
                              aria-expanded="false"
                              value={search}
                              onChange={(event) =>
                                setSearch(event.target.value)
                              }
                              onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  if (search !== "") {
                                    filterPackFromServer();
                                  }
                                } else if (event.key === "Backspace") {
                                  if (search === "") {
                                    event.preventDefault();
                                    if (filterApplied) {
                                      filterPackFromServer();
                                    } else {
                                      getPackFromServer();
                                    }
                                  }
                                } else if (event.ctrlKey && event.key === "x") {
                                  if (
                                    event.target.selectionStart === 0 &&
                                    event.target.selectionEnd === search.length
                                  ) {
                                    // If text is selected and then deleted (Ctrl + X)
                                    event.preventDefault();
                                    setSearch("");
                                    if (filterApplied) {
                                      filterPackFromServer();
                                    } else {
                                      getPackFromServer();
                                    }
                                  }
                                }
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                {/* new section  */}

                {/* date dropdown  */}
                <div
                  className="campaigns-date-dropdown-box dropdown-box"
                  onClick={dropdownOpen}
                >
                  <div className="dropDownText">
                    <div className="date-picker-button d-flex position-relative">
                      <input
                        className="border-0 mt-1 no-outline"
                        type="date"
                        id="date_calendar"
                        max={new Date().toISOString().split("T")[0]}
                        value={selectedDate}
                        onChange={(event) => {
                          handleDateChange(event);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="leaveEmptySpace">
                  {/* Reset All Filters */}
                  <div
                    onClick={() => {
                      resetFilters();
                    }}
                    className="reset-all-filter-icon d-flex align-items-center mb-0"
                    style={{ width: "56px", height: "56px" }}
                  >
                    {/* Reset */}
                    <img
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                      src="/assets/images/reset-icon.svg"
                      alt="reset"
                    />
                  </div>
                  <div className="emptySpace"></div>

                  <div
                    className="createPackButton greenButton"
                    onClick={handleCreatePackClick}
                  >
                    Create a Pack
                  </div>
                </div>
                {/* new section  */}
              </div>
            </div>
          </div>

          {loading ? (
            <>
              <div className="p-5 m-5 d-flex justify-content-center">
                <PuffLoader color="#65acae" size={42} />
              </div>
            </>
          ) : (
            <>
              {packCatalogueData && packCatalogueData.length > 0 ? (
                <div className="packCatalogueBox container">
                  {packCatalogueData.map((cData, index) => {
                    return (
                      <div key={index} className="col-sm-3 cursor-pointer">
                        <div
                          onClick={() => {
                            handlePackClick(cData?.id);
                          }}
                          className="position-relative"
                        >
                          {cData?.pack_images[0] !== undefined ? (
                            <>
                              <img
                                src={
                                  cData.pack_images[0] !== undefined
                                    ? cData.pack_images[0].image
                                    : "/assets/images/404_circle.png"
                                }
                                alt={`Background ${index}`}
                                className="packCatalogueImage "
                              />
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  width: "260px",
                                  height: "260px",
                                  marginTop: "10px",
                                  position: "relative",
                                }}
                              >
                                <svg
                                  className=""
                                  width="100%"
                                  height="100%"
                                  viewBox="0 0 362 401"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <mask
                                    id="mask0_670_150"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="362"
                                    height="386"
                                  >
                                    <rect
                                      width="362"
                                      height="386"
                                      rx="30.5"
                                      fill="white"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_670_150)">
                                    <rect
                                      width="362"
                                      height="400.378"
                                      fill="#D9D9D9"
                                    />
                                  </g>
                                  <path
                                    d="M163 183V191C163 191.53 163.211 192.039 163.586 192.414C163.961 192.789 164.47 193 165 193H171M171 183V203M191 183V191C191 191.53 191.211 192.039 191.586 192.414C191.961 192.789 192.47 193 193 193H199M199 183V203M177 185V201C177 201.53 177.211 202.039 177.586 202.414C177.961 202.789 178.47 203 179 203H183C183.53 203 184.039 202.789 184.414 202.414C184.789 202.039 185 201.53 185 201V185C185 184.47 184.789 183.961 184.414 183.586C184.039 183.211 183.53 183 183 183H179C178.47 183 177.961 183.211 177.586 183.586C177.211 183.961 177 184.47 177 185Z"
                                    stroke="#FE0201"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </>
                          )}

                          <div className="packCatalogueTag">
                            <div>{cData.title}</div>
                            <div>${cData.price} Per Pack</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="nothing-found">No Packs Found!</div>
              )}
            </>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Packs;
