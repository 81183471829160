import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import About from "./Pages/About";
import Learn from "./Pages/Learn";
import VitrineLive from "./Pages/Live/VitrineLive";
import AddCampaigns from "./Pages/Brand/AddCampaigns";
// import BrandChat from "./Pages/Brand/BrandChat";
import BrandDetails from "./Pages/Brand/BrandDetails";
import Campaigns from "./Pages/Brand/Campaigns";
import Collabration from "./Pages/Brand/Collabration";
import Profile from "./Pages/Brand/Profile";
import Setting from "./Pages/Brand/Setting";
import SingleCampaignDetails from "./Pages/Brand/SingleCampaignDetails";
import Welcome from "./Pages/Brand/Welcome";
import BrandCatalogue from "./Pages/Brand/BrandCatalogue";
import CreatorsCatalogue from "./Pages/Creators/CreatorsCatalogue";
import CatalogueDetails from "./Pages/Creators/CatalogueDetails";
import CreatorsProfile from "./Pages/Creators/CreatorsProfile";
import CreatorsSetting from "./Pages/Creators/CreatorsSetting";
import MainHelpView from "./Pages/HelpCenter/MainHelpView";
import BrandHelpCenter from "./Pages/HelpCenter/BrandHelpCenter";
import CreatorsHelpCenter from "./Pages/HelpCenter/CreatorsHelpCenter";
import CreatorDetails from "./Pages/Brand/CreatorDetails";
import CampaignDetailsSection from "./Pages/Brand/CampaignDetailsSection";
import OtpVerification from "./Pages/Creators/OtpVerification";
import Packs from "./Pages/Creators/Packs";
import CreatorChat from "./Pages/Creators/CreatorChat";
import CreatorCampaigns from "./Pages/Creators/CreatorCampaigns";
import CreatePack from "./Pages/Creators/Packs/CreatePack";
import EditPack from "./Pages/Creators/Packs/EditPack";
import PackDetails from "./Pages/Creators/Packs/PackDetails";
import CreatorCompleteProfile from "./Pages/Creators/CreatorCompleteProfile";
import BrandCompleteProfile from "./Pages/Brand/BrandCompleteProfile";
import EditCampaign from "./Pages/Brand/EditCampaign";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import CollaborationDetails from "./Pages/CollaborationDetails";
import TermsConditions from "./Pages/TermsConditions";
import ContactUs from "./Pages/ContactUs";
import WrappedPaymentForm from "./Stripe/WrappedPaymentForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { messaging, onMessageListener, requestPermission } from "./firebase";
import { useEffect } from "react";
import CustomizedSlider from "./Components/CustomizedSlider";
import ForgotPasswordPage from "./Pages/ForgotPassword";
import BackupSlider from "./Components/BackupSlider";
import { error } from "./states/auth/authSlice";

function App() {
  // const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    // console.log("Inside the on message effect");
    if(messaging !== undefined  && messaging !== null) {
      requestPermission();
    }
    const unsubscribe = onMessageListener().then((payload) => {
      toast.success(
        `${payload?.notification?.title}: ${payload?.notification?.body}`
      );
    }).catch((error) => {
      console.log("Failed to get notification!");
    }) ;
    return () => {
      console.log("Return Called....");
      unsubscribe.catch((err) => console.log("failed: ", err));
    };
  }, []);

  // function requireAuth(nextState, replace) {
  //   console.log("Check Token: ", token);
  //   if (token) {
  //     replace({
  //       pathname: '/',
  //       state: { nextPathname: nextState.location.pathname }
  //     })
  //   }
  // }

  return (
    <Router>
      <Routes>
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="/addCampaigns" element={<AddCampaigns />} />
        <Route
          path="/creatorCompleteProfile"
          element={<CreatorCompleteProfile />}
        />
        <Route
          path="/brandCompleteProfile"
          element={<BrandCompleteProfile />}
        />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/brandDetails" element={<BrandDetails />} />
        <Route
          path="/campaignDetailsSection"
          element={<CampaignDetailsSection />}
        />
        <Route path="/myCampaigns" element={<Campaigns />} />
        <Route path="/creatorCampaigns" element={<CreatorCampaigns />} />
        <Route path="/edit-campaign" element={<EditCampaign />} />
        <Route path="/brandCatalogue" element={<BrandCatalogue />} />
        <Route path="/collabration" element={<Collabration />} />
        <Route path="/collabrationDetails" element={<CollaborationDetails />} />
        <Route path="/creatorDetails" element={<CreatorDetails />} />
        <Route path="/brandProfile" element={<Profile />} />
        <Route path="/brandSetting" element={<Setting />} />
        <Route
          path="/singleCampaignDetails"
          element={<SingleCampaignDetails />}
        />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/customSlider" element={<CustomizedSlider />} />
        <Route path="/backupSlider" element={<BackupSlider />} />

        <Route path="/creatorsCatalogue" element={<CreatorsCatalogue />} />

        <Route
          path="/creatorsCatalogueDetails"
          element={<CatalogueDetails />}
        />
        <Route path="/creatorsProfile" element={<CreatorsProfile />} />
        <Route path="/packs" element={<Packs />} />
        <Route path="/creatorChat" element={<CreatorChat />} />
        <Route path="/creatorsSetting" element={<CreatorsSetting />} />
        <Route path="/brandHelp" element={<BrandHelpCenter />} />
        <Route path="/creatorsHelp" element={<CreatorsHelpCenter />} />
        <Route path="/create-pack" element={<CreatePack />} />
        <Route path="/edit-pack" element={<EditPack />} />
        <Route path="/view-pack" element={<PackDetails />} />
        <Route path="/mainHelp" element={<MainHelpView />} />
        <Route path="/" element={<VitrineLive />} />

        <Route path="/otpVerification" element={<OtpVerification />} />
        <Route path="/about" element={<About />} />
        <Route path="/learn" element={<Learn />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/termsAndConditions" element={<TermsConditions />} />
        <Route path="/stripe-payment" element={<WrappedPaymentForm />} />
        {/* Path for Adding a redirect to show popup */}
        <Route
          path="/signup/brand"
          element={<Navigate to="/?signup=brand" />}
        />
        <Route
          path="/signup/creator"
          element={<Navigate to="/?signup=creator" />}
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
