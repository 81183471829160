import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { FilterOtherBrandsCatalogue, getBrands } from "../../Common/Repository";

const initialState = {
  loading: false,
  brandCatalogueData: [],
  error: null,
  success: false,
  // Filter Brands
  filterLoading: false,
  filterError: null,
  filterSuccess: false,
};

const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    setBrandCatalogueData: (state, action) => {
      state.brandCatalogueData = action.payload;
    },
    resetBrandSlice: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.filterLoading = false;
      state.filterSuccess = true;
      state.filterError = null;
      state.brandCatalogueData = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.pending, (state) => {
        if (!state?.brandCatalogueData?.length) {
          state.loading = true;
        }
        state.error = null;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.brandCatalogueData = action?.payload?.data?.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(FilterOtherBrandsCatalogue.pending, (state) => {
        state.filterLoading = true;
        state.filterError = null;
        state.filterSuccess = false;
      })
      .addCase(FilterOtherBrandsCatalogue.fulfilled, (state, action) => {
        // 
        state.brandCatalogueData = action?.payload?.data;
        state.filterLoading = false;
        state.filterSuccess = true;
        state.filterError = null;
      })
      .addCase(FilterOtherBrandsCatalogue.rejected, (state, action) => {
        state.filterLoading = false;
        state.filterSuccess = false;
        state.filterError = action.payload;
      })
      .addCase(logout, (state) => {
        state.loading = false;
        state.error = null;
        state.success = false;
        state.filterLoading = false;
        state.filterSuccess = true;
        state.filterError = null;
        state.brandCatalogueData = [];
      });
  },
});

export const { setBrandCatalogueData, resetBrandSlice } = brandsSlice.actions;
export default brandsSlice.reducer;
