import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser, updateChatAndMessage } from "../states/chat/chatSlice";
import { socket } from "../Common/Socket";
import "react-phone-number-input/style.css";
import { getUserPublicProfile } from "../Common/Repository";


const AppLayout = (props) => {
  const dispatch = useDispatch();
  // Commented out because wasn't being used in here
  // const [isConnected, setIsConnected] = useState(socket.connected);

  const { user, token } = useSelector((state) => state.auth);
  //   const user = useSelector((state) => state.auth.user);

  // const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [verifyNotice, setVerifyNotice] = useState(false);

  useEffect(() => {
    if (user) {
      if (user?.role == "2") {
        if (user?.brand.profile_complete == 0 && user?.account_verified == 0) {
          setVerifyNotice(true);
        }
      } else if (user?.role == "1") {
        if (user?.creator?.profile_complete == 0 && user?.account_verified == 0) {
          setVerifyNotice(true);
        }
      }
    }
  }, [user]);

  const handleStartVerification = () => {
    if (user) {
      if (user?.role == "2") {
        navigate("/brandCompleteProfile");
      } else if (user?.role == "1") {
        navigate("/creatorCompleteProfile");
      }
    }
  };
  useEffect(() => {
    if (token !== "") {
      // Set the current user in chat reducer.
      dispatch(setCurrentUser(user));
      if (!socket.connected) {
        const handleMessageSendEvent = (object) => {
          console.log("message event", object);
          dispatch(updateChatAndMessage(object));
        };

        socket.connect();
        socket.on("connect", () => {
          socket.emit("storeUser", user?.id);
          if (token !== "") {
          }
        });
        socket.on("message_send_event", handleMessageSendEvent);
        return () => {
          socket.off("message_send_event", handleMessageSendEvent);
          socket.disconnect();
        };
      }
    }
  }, []);

  // const getPublicView = async () => {
  //   console.log('applayout');
  //   try {
  //     dispatch(getUserPublicProfile({ token, user }));
  //   } catch (error) {
  //     console.log('error get public view', error?.response?.data?.error)
  //     // setErrors(error?.response?.data?.error);
  //   }
  // };

  // useEffect(() => {
  //   getPublicView();
  // }, []);


  return (
    <div className="home">
      <header>
        <Navbar />
        {user && verifyNotice ? (
          <>
            <div className="container verify-notice">
              <div>
                Please complete your profile verification in order to be able to
                access the platform features completely...
              </div>
              <div
                onClick={() => handleStartVerification()}
                className="text-dark fw-bolder link-style-notice"
              >
                {user?.role == "1"
                  ? user?.creator?.profile_complete == "0" &&
                    (user?.creator?.profile_image === "" ||
                      user?.creator?.profile_image === null) &&
                    (user?.creator?.active_hours_from === null ||
                      user?.creator?.active_hours_from === "") &&
                    (user?.creator?.active_hours_to === null ||
                      user?.creator?.active_hours_from === "") &&
                    (user?.creator?.days === null || user?.creator?.days === "") &&
                    (user?.creator?.profile_tag === null ||
                      user?.creator?.profile_tag === "" ||
                      user?.creator?.profile_tag?.length == 0) &&
                    user?.creator?.creator_portfolios?.length == 0 &&
                    user?.creator?.past_experiences?.length == 0 &&
                    user?.creator?.sponsors?.length == 0 &&
                    user?.instagram_verified_at == null
                    ? "Start Verification"
                    : "Continue Verification"
                  : user?.role == "2" &&
                    user?.brand?.profile_complete == "0" &&
                    (user?.brand?.brand_industry === "" || user?.brand?.brand_industry === null) &&
                    (user?.brand?.established_date === "" || user?.brand?.established_date === null) &&
                    (user?.brand?.brand_social_interest === "" || user?.brand?.brand_social_interest === null) &&
                    (user?.brand?.bio === "" || user?.brand?.bio === null) &&
                    (user?.brand?.website_link === "" ||
                      user?.brand?.website_link === null) &&
                    (user?.brand?.instagram === "" ||
                      user?.brand?.instagram === null) &&
                    (user?.brand?.linkedIn === "" ||
                      user?.brand?.linkedIn === null) &&
                    (user?.brand?.profile_image === null ||
                      user?.brand?.profile_image === "")
                    ? "Start Verification"
                    : "Continue Verification"}
                <span className="ps-1">
                  &#x2192;
                </span>
              </div>
            </div>
          </>
        ) : null}
      </header>

      <main>
        <div>{props.children}</div>
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default AppLayout;
