import React, { useEffect, useState } from "react";
import CircleImageNFound from "./404_CircleImage";
import { ReviewStar } from "./Icons";

const Reviews = ({ reviewsReceived, totalReviews, averageStars }) => {
  const [reviewsData, setReviewsData] = useState([]);

  useEffect(() => {
    if (reviewsReceived) {
      setReviewsData(reviewsReceived);
    }
  }, []);

  return (
    <>
      <div className="reviewBox">
        <div className="reviewHeading">
          <div>Reviews</div>
          <div className="reviewStars">
            <ReviewStar fill="white" className="me-1" />
            {averageStars && averageStars?.length > 2 ? averageStars?.toString().substring(0, 3) : averageStars ? averageStars.toFixed(1) : "0"} ({totalReviews ?? "0"})
          </div>
        </div>
        {reviewsData.map((review) => {
          return (
            <>
              <div key={review.id} className="reviewRow">
                <div className="width-10">
                  {review?.reviewer?.brand?.profile_image !== undefined &&
                    review?.reviewer?.brand?.profile_image !== null ? (
                    <img
                      className="reviewerImage"
                      src={review?.reviewer?.brand?.profile_image}
                      alt="avtar-a"
                    />
                  ) : review?.reviewer?.creator?.profile_image !== undefined &&
                    review?.reviewer?.creator?.profile_image !== null ? (
                    <img
                      className="reviewerImage"
                      src={review?.reviewer?.creator?.profile_image}
                      alt="avtar-a"
                    />
                  ) : review?.reviewer_profile_picture !== undefined &&
                    review?.reviewer_profile_picture !== null ? (
                    <img
                      className="reviewerImage"
                      src={review?.reviewer_profile_picture}
                      alt="avtar-a"
                    />
                  ) : (
                    <CircleImageNFound width={70} height={70} />
                  )}
                </div>
                <div className="d-flex align-items-center w-100">
                  <div className="w-100">
                    <div className="reviewerName mb-1">
                      {review?.reviewer?.brand?.brand_name !== undefined ? review?.reviewer?.brand?.brand_name : review?.reviewer?.creator?.profile_name !== undefined ? review?.reviewer?.creator?.profile_name : review?.reviewer_name !== undefined && review?.reviewer_name !== null ? review?.reviewer_name : "-"}
                    </div>
                    <div className="review-content">{review?.comment ?? "-"}</div>
                  </div>
                </div>
                <div className="reviewRating">
                  <div className="d-flex">
                    <ReviewStar className="me-1" />
                    {review?.rating}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Reviews;
