import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import { useNavigate } from "react-router-dom";
import { filterOwnCampaign, getOwnCampaigns } from "../../Common/Repository";
import { FullSearchIcon } from "../../Components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { setMyCampaigns } from "../../states/myCampaigns/myCampaignSlice";
import { PuffLoader } from "react-spinners";

const Campaigns = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [resetTrue, setResetTrue] = useState(false);

  const [firstTime, setFirstTime] = useState(true);

  const [filterApplied, setFilterApplied] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");

  const [selectedValues, setSelectedValues] = useState([]);

  const { token } = useSelector((state) => state.auth);

  const { myCampaignsData, loading } = useSelector(
    (state) => state.myCampaigns
  );

  const [searchVal, setSearchVal] = useState("");

  const [errors, setErrors] = useState([]);

  const getCampaignsFromServer = async () => {
    try {
      dispatch(getOwnCampaigns({ token }));
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      getCampaignsFromServer();
    } else {
      if (selectedDate || selectedValues || resetTrue) {
        applyFilter();
      } else {
        getCampaignsFromServer();
      }
    }
  }, [selectedDate, selectedValues]);

  const applyFilter = async () => {
    try {
      const formData = {};
      if (searchVal.length > 0) {
        formData.title = searchVal;
      }

      if (selectedValues.length > 0) {
        formData.status = selectedValues ?? "";

        // formData.status = selectedValues[0].toLowerCase();
      }

      if (selectedDate.length > 0) {
        formData.created_at = selectedDate;
      }

      let response = await filterOwnCampaign(
        {
          Authorization: `Bearer ${token}`,
        },
        formData
      );
      if (response?.status === 200) {
        dispatch(setMyCampaigns(response?.data));
        if (selectedValues.length === 0) {
          dropdownOpen();
        }
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  const campaignListData = {
    listTitle: ["Campaign", "Status", "Last Updated", "Contacted"],
  };

  const [dropdownItems, setDropdownItems] = useState([
    "Active",
    "In-Active",
    "Draft",
  ]);

  const handleCheckboxChange = (item) => {
    if (selectedValues.includes(item)) {
      setSelectedValues(
        selectedValues.filter((value) => value !== item.toLowerCase())
      );
    } else {
      setSelectedValues([...selectedValues, item.toLowerCase()]);
    }
  };

  useEffect(() => {
    if (selectedValues.length === 0) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
    setResetTrue(true);
  }, [selectedValues]);

  const handleDateChange = (event) => {
    setSelectedDate(event?.target?.value);
    let DateEvent = event?.target?.value;
    if (DateEvent === "") {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
    setResetTrue(true);
  };

  const resetFilters = () => {
    setFilterApplied(false);
    setSelectedValues([]);
    setSearchVal("");
    setSelectedDate("");
    getCampaignsFromServer();
  };

  const handleCampaignclick = (campaignId) => {
    navigate("/campaignDetailsSection", { state: campaignId });
  };

  const dropdownOpen = (event) => {
    if (event) {
      const obj = event.currentTarget;
      if (window.$(obj).find(".inner-dropdown").hasClass("open")) {
        window.$(obj).find(".inner-dropdown").removeClass("open");
      } else {
        window.$(".inner-dropdown").removeClass("open");
        window.$(obj).find(".inner-dropdown").addClass("open");
        window.$(obj).find(".inner-dropdown").removeClass("d-none");
      }
    } else {
      window.$(".inner-dropdown").removeClass("open");
    }
  };

  useEffect(() => {
    dropdownOpen();
  }, [selectedDate]);

  const dropdownBox = (event) => {
    event.stopPropagation();
  };

  return (
    <AppLayout>
      <div className="categories-dashboard px-5">
        <div className="container container-animation">
          <img
            className="purple-circle"
            src="/assets/images/circle_top1.png"
            alt="circle_top1"
          />
          <img
            className="yellow-circle"
            src="/assets/images/circle-03.png"
            alt="circle_top1"
          />
          {/* <img
            className="dollor-coin"
            src="/assets/images/dollar-icon.png"
            alt="circle_top1"
          /> */}
          <div className="categories-dashboard mt-5">
            <h4 className="mx-md-5">My Campaigns</h4>
            <div className="fixed-secondary-bar">
              <div className="search-bar" data-onboarding="step1">
                <div className="search-bar-input-content ">
                  <div className="search-bar-input-search input-search">
                    <div className="d-flex align-items-center position-relative search-bar--input-container -tags">
                      <div
                        className="search-tag-position"
                        onClick={() => {
                          if (searchVal.length) {
                            applyFilter();
                          }
                        }}
                      >
                        <FullSearchIcon
                          color={!searchVal.length ? "#93BFC0" : null}
                          width="133px"
                        />
                      </div>
                      <span className="d-flex align-items-center w-100">
                        <div
                          className="ant-select ant-select-auto-complete search-bar--input css-10evijz ant-select-single ant-select-show-search w-100"
                          autoComplete="off"
                        >
                          <div className="ant-select-selector w-100">
                            <span className="ant-select-selection-search w-100">
                              <input
                                type="text"
                                className="ant-select-selection-search-input w-100"
                                id="search"
                                value={searchVal}
                                placeholder="Search by campaign title"
                                role="combobox"
                                aria-haspopup="listbox"
                                aria-owns="rc_select_8_list"
                                aria-autocomplete="list"
                                aria-controls="rc_select_8_list"
                                aria-activedescendant="rc_select_8_list_-1"
                                aria-expanded="false"
                                onChange={(event) =>
                                  setSearchVal(event.target.value)
                                }
                                onKeyUp={(event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault();
                                    if (searchVal !== "") {
                                      applyFilter();
                                    }
                                  } else if (event.key === "Backspace") {
                                    if (searchVal === "") {
                                      event.preventDefault();
                                      if (filterApplied) {
                                        applyFilter();
                                      } else {
                                        getCampaignsFromServer();
                                      }
                                    }
                                  } else if (
                                    event.ctrlKey &&
                                    event.key === "x"
                                  ) {
                                    if (
                                      event.target.selectionStart === 0 &&
                                      event.target.selectionEnd ===
                                        searchVal.length
                                    ) {
                                      // If text is selected and then deleted (Ctrl + X)
                                      event.preventDefault();
                                      getCampaignsFromServer();
                                    }
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="find_creators-search d-flex find_creators-search--no-label position-relative align-items-center flex-md-row find_creators-search--search mobile-col">
                    <div
                      className="dropdown-box status-dropdown-box wm-100"
                      onClick={dropdownOpen}
                    >
                      <div className="dropdown-button d-flex">
                        <p className="m-0 w-100 d-flex align-items-center">
                          Status
                        </p>
                        <div className="d-inline-block">
                          <img
                            src="/assets/images/arrow-sign.png"
                            alt=""
                            width={15}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="inner-dropdown" onClick={dropdownBox}>
                        <div className="type-dropdown-option status-dropdown-option">
                          {dropdownItems.map((item, index) => (
                            <div className="d-flex" key={index}>
                              <input
                                type="checkbox"
                                name={index}
                                checked={selectedValues.includes(
                                  item.toLocaleLowerCase()
                                )}
                                onChange={() =>
                                  handleCheckboxChange(item.toLocaleLowerCase())
                                }
                                className="dropdown-checkbox mb-2"
                              />
                              <div className="ms-2 ">{item}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="innerSection mb-2 mb-md-0 wm-100">
                      <div
                        className="campaigns-date-dropdown-box dropdown-box wm-100"
                        onClick={dropdownOpen}
                      >
                        <div className="date-picker-button d-flex">
                          {/* <p className="m-0 w-100 d-flex align-items-center">
                          {selectedDate !== ""
                            ? convertDateFormat(selectedDate)
                            : "Creation Date"}
                        </p> */}
                          <input
                            // ref={inputRef}
                            id="date_calendar"
                            className="border-0 mt-1 no-outline"
                            type="date"
                            max={new Date().toISOString().split("T")[0]}
                            value={selectedDate}
                            onChange={(e) => {
                              handleDateChange(e);
                            }}
                          />
                          {/* <img
                            onClick={() => {
                              document
                                .getElementById("date_calendar")
                                .showPicker();
                            }}
                            className="calender-icon"
                            src="/assets/images/calenderIcon.svg"
                            alt="date"
                            width={25}
                          /> */}
                        </div>
                        {/* <div className="inner-dropdown" onClick={dropdownBox}>

                      </div> */}
                      </div>
                    </div>
                  </div>
                  {/* Reset All Filters */}
                  <div
                    onClick={() => {
                      resetFilters();
                    }}
                    className="reset-all-filter-icon d-flex align-items-center wm-100"
                    style={{ width: "56px", height: "56px" }}
                  >
                    {/* Reset */}
                    <img
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                      src="/assets/images/reset-icon.svg"
                      alt="reset"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <>
              <div className="p-5 m-5 d-flex justify-content-center">
                <PuffLoader color="#65acae" size={42} />
              </div>
            </>
          ) : (
            <>
              {myCampaignsData && myCampaignsData.length > 0 ? (
                <div
                  className="brand-listing-table"
                  id="listing-view-catalogues"
                >
                  <div className="dataTables_wrapper mt-5 dt-bootstrap5 no-footer">
                    <div className="row dt-row">
                      <div className="col-sm-12 ms-3">
                        <table
                          id="brand-detail-listing-table"
                          className="table-responsive rounded-top-3 table-striped dataTable no-footer"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              {campaignListData.listTitle.map(
                                (cTitle, index) => (
                                  <th
                                    key={index}
                                    className={
                                      cTitle === "Campaign"
                                        ? "w-auto"
                                        : "text-center w-auto"
                                    }
                                    aria-controls="brand-detail-listing-table"
                                  >
                                    <span className="">{cTitle}</span>
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {myCampaignsData.map((campaignData, index) => {
                              const isEven = (index + 1) % 2 === 0;
                              const rowClass = isEven
                                ? "bg-table-stripe"
                                : "bg-white";
                              return (
                                <tr key={index} className={rowClass}>
                                  <td
                                    onClick={() =>
                                      handleCampaignclick(campaignData?.id)
                                    }
                                  >
                                    <div
                                      key={`${index}`}
                                      className="profile-outer-box w-75 d-flex"
                                    >
                                      <div className="profile-inner1">
                                        <span className="person-image">
                                          {campaignData?.color !== undefined &&
                                          campaignData?.color !== null ? (
                                            <div
                                              style={{
                                                backgroundColor:
                                                  campaignData?.color,
                                                height: "50px",
                                                width: "50px",
                                                borderRadius: "50%",
                                              }}
                                            ></div>
                                          ) : (
                                            <img
                                              src={
                                                campaignData?.campaign_image !==
                                                  undefined &&
                                                campaignData?.campaign_image !==
                                                  null
                                                  ? campaignData?.campaign_image
                                                      .image
                                                  : "assets/images/404_circle.png"
                                              }
                                              className="img-fluid person-image"
                                              alt="..."
                                            />
                                          )}
                                        </span>
                                      </div>
                                      <div className="profile-inner2">
                                        <span className="person-name-dark text-truncate">
                                          {campaignData.title !== null
                                            ? campaignData.title
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <div
                                      className={`d-flex flex-column align-items-center text-center pt-1 mx-auto ${
                                        campaignData.is_complete === "0" ||
                                        campaignData.is_complete === 0 ||
                                        campaignData.is_complete === null
                                          ? "draft-status-tag"
                                          : campaignData.is_complete === "1" ||
                                            campaignData.is_complete === 1
                                          ? campaignData.campaign_verified ===
                                              "0" ||
                                            campaignData.campaign_verified === 0
                                            ? "inactive-status-tag"
                                            : campaignData.campaign_verified ===
                                                "1" ||
                                              campaignData.campaign_verified ===
                                                1
                                            ? "active-status-tag"
                                            : "draft-status-tag"
                                          : "draft-status-tag"
                                      }`}
                                    >
                                      <div className="d-flex me-2">
                                        <span className="me-2 align-center">
                                          <div
                                            className={
                                              campaignData.is_complete ===
                                                "0" ||
                                              campaignData.is_complete ===
                                                null ||
                                              campaignData.is_complete === 0
                                                ? "dot-draft"
                                                : campaignData.is_complete ===
                                                    "1" ||
                                                  campaignData.is_complete === 1
                                                ? campaignData.campaign_verified ===
                                                    "0" ||
                                                  campaignData.campaign_verified ===
                                                    0
                                                  ? "dot-in-active"
                                                  : campaignData.campaign_verified ===
                                                      "1" ||
                                                    campaignData.campaign_verified ===
                                                      1
                                                  ? "dot-active"
                                                  : "dot-draft"
                                                : "dot-draft"
                                            }
                                          ></div>
                                        </span>
                                        <div className="py-1">
                                          {campaignData.is_complete === "0" ||
                                          campaignData.is_complete === 0 ||
                                          campaignData.is_complete === null
                                            ? "Draft"
                                            : campaignData.is_complete ===
                                                "1" ||
                                              campaignData.is_complete === 1
                                            ? campaignData.campaign_verified ===
                                                "0" ||
                                              campaignData.campaign_verified ===
                                                0
                                              ? "In-Active"
                                              : campaignData.campaign_verified ===
                                                  "1" ||
                                                campaignData.campaign_verified ===
                                                  1
                                              ? "Active"
                                              : "Draft"
                                            : "Draft"}
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  <td className="text-center">
                                    {campaignData.updated_at.split("T")[0]}
                                  </td>

                                  <td className="text-center">
                                    {campaignData.contacted ?? "N/A"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="nothing-found">No Campaigns Found!</div>
              )}
            </>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Campaigns;
