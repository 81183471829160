import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { FilterCreatorsCatalogue, getCreators } from "../../Common/Repository";

const initialState = {
    loading: false,
    creatorsData: [],
    error: null,
    success: false,
    // Filters States
    filterLoading: false,
    filterSuccess: false,
    filterError: null,
};

const creatorSlice = createSlice({
    name: 'creators',
    initialState,
    reducers: {
        setCreators: (state, action) => {
            state.creatorsData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logout, (state) => {
                state.creatorsData = [];
                state.loading = false;
                state.success = false;
            })
            .addCase(getCreators.pending, (state) => {
                if (!state.creatorsData.length) {
                    state.loading = true;
                }
                state.error = null;
            })
            .addCase(getCreators.fulfilled, (state, action) => {
                state.creatorsData = action.payload?.data?.data;

                state.loading = false;
                state.error = null;
            })
            .addCase(getCreators.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(FilterCreatorsCatalogue.pending, (state) => {
                state.filterSuccess = false;
                state.filterLoading = true;
                state.error = null;

            })
            .addCase(FilterCreatorsCatalogue.fulfilled, (state, action) => {
                state.creatorsData = action.payload?.data;
                state.filterLoading = false;
                state.filterSuccess = true;
                state.error = null;

            })
            .addCase(FilterCreatorsCatalogue.rejected, (state, action) => {
                state.filterLoading = false;
                state.error = action.payload;

            })
    },
});

export const { setCreators } = creatorSlice.actions;
export default creatorSlice.reducer;
