import React from "react";
import AppLayout from "../Components/AppLayout";

const About = () => {
  return (
    <AppLayout>
      <div className="container">
        <div className="campaign-box-detail-abt mx-auto my-5 py-5">
          <h4 className="mx-2">About Us</h4>
          <div className="about-us-text">
            We founded Vitrine with a vision: to unrestrict the full potential
            of creativity and lead a new era for content creators worldwide. At
            the heart of our mission lies a commitment to not just envision but
            actively shape a more vibrant, equitable, and innovative creator
            economy. Getting closer to creators worldwide recurring issues were
            identified regarding brand collaborations: unpaid work, ghosting
            post-delivery, exploitation of content without fair pay, and brands'
            reputations being tarnished by scams. These challenges not only
            affect individuals but also hinder the growth and health of our
            industry at large. In response to these widespread issues, we
            developed Creator Catalogue - our no cost platform designed to
            facilitate how creators collaborate with brands. Creator Catalogue
            addresses the critical pain points directly, offering secure,
            transparent and equitable partnerships that protect and empower
            creators and brands. Creator Catalogue is more than a solution to
            influencer marketing problems; it's a step towards setting a new
            standard in creator-brand collaborations. We envision a thriving
            ecosystem where creativity is valued, rights are respected, and each
            collaboration contributes to a fairer creator economy. We invite you
            to explore Creator Catalogue. Together, we can create a future where
            every creator's potential is fully realized.
          </div>
          <div className="d-flex justify-content-center mt-5">
            <h3>
              Our<span className="text-highlight"> Values</span>
            </h3>
          </div>
          <div className="row custom-height">
            <div className="col-md-4">
              <div className="row about-us-single-box">
                <div className="col-12 align-self-end">
                  <div className="about-us-box mt-5">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <span className="p-2 hide-on-phone">
                          <div className="dot-active-about-us"></div>
                        </span>
                        <h6 className="">Embrace the Whole Story</h6>
                        <img
                          src="/assets/images/arrow-up.svg"
                          alt="^"
                          className="pb-2 hide-on-phone"
                        />
                        {/* <span className="p-2"><div className="dot-active-about-us"></div></span> */}
                      </div>
                    </div>
                    <div className="about-us-description">
                      At Vitrine, we see every creator and fan as an essential
                      part of our narrative. This holistic approach informs our
                      decision-making, ensuring our platform serves diverse
                      needs equitably. It's not just a platform it's a movement
                      shaping the social entertainment evolution.
                    </div>
                  </div>
                </div>
                <div className="col-12 align-self-end">
                  <div className="about-us-box">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <span className="p-2 hide-on-phone">
                          <div className="dot-active-about-us"></div>
                        </span>
                        <h6 className="">
                          Crafting Ideas,Building with Creators
                        </h6>
                        <img
                          src="/assets/images/arrow-up.svg"
                          alt="^"
                          className="pb-2 hide-on-phone"
                        />
                        {/* <span className="p-2"><div className="dot-active-about-us"></div></span> */}
                      </div>
                    </div>
                    <div className="about-us-description">
                      Creativity is the lifeblood of the indsutry and Vitrine,
                      driving our collaborative efforts. Our team doesn't just
                      work together; we build innovative solutions with a shared
                      passion.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <img
                className="wm-100"
                src="/assets/images/globe-new.svg"
                alt="globe"
                width={400}
              />
            </div>
            <div className="col-md-4">
              <div className="row about-us-single-box ms-3">
                <div className="col-12 align-self-end">
                  <div className="about-us-box">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-between ">
                        <span className="p-2 hide-on-phone">
                          <div className="dot-active-about-us"></div>
                        </span>
                        <h6>Clear as Glass</h6>
                        <img
                          src="/assets/images/arrow-up.svg"
                          alt="^"
                          className="pb-2 hide-on-phone"
                        />

                        {/* <span className="p-2"><div className="dot-active-about-us"></div></span> */}
                      </div>
                    </div>
                    <div className="about-us-description">
                      Transparency is our operational backbone, ensuring trust,
                      clarity and recognition our platform operates with the
                      clarity of a vitrine.
                    </div>
                  </div>
                </div>
                <div className="col-12 align-self-end">
                  <div className="about-us-box">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <span className="p-2 hide-on-phone">
                          <div className="dot-active-about-us"></div>
                        </span>
                        <h6 className="">Embrace the Unknown </h6>
                        <img
                          src="/assets/images/arrow-up.svg"
                          alt="^"
                          className="pb-2 hide-on-phone"
                        />

                        {/* <span className="p-2"><div className="dot-active-about-us"></div></span> */}
                      </div>
                    </div>
                    <div className="about-us-description">
                      Curiosity and adaptability are the engines driving
                      Vitrine's innovation. We eagerly explore uncharted
                      territories, ensuring we're always evolving with the
                      dynamic creator economy.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default About;
