import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import { useNavigate } from "react-router-dom";
import DropdownForList from "../../Components/DropdownForList";
import {
  FilterCampaignsCreator,
  getCampaigns,
  getCategoriesApi,
} from "../../Common/Repository";
import { FullSearchIcon } from "../../Components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";

const CreatorCampaigns = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state.auth);

  const { creatorCampaignData, loading } = useSelector(
    (state) => state.campaigns
  );

  const [searchInputIndustry, setSearchInputIndustry] = useState("");

  const [searchInputLocation, setSearchInputLocation] = useState("");

  const [locationValue, setLocationValue] = useState("");

  const [search, setSearch] = useState("");

  const [errors, setErrors] = useState([]);

  const [selectedValues, setSelectedValues] = useState([]);

  const [firstTime, setFirstTime] = useState(true);

  const [categoryValue, setCategoryValue] = useState("");

  const [categoriesList, setCategoriesList] = useState([]);

  const [categoryData, setCategoryData] = useState();

  const [resetTrue, setResetTrue] = useState(false);

  const [filterApplied, setFilterApplied] = useState(false);

  const handleLocClick = (value) => {
    if (value === "") {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
    setResetTrue(true);
    setLocationValue(value);
  };

  const handleCategoryClick = (value) => {
    if (value === "") {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
    setResetTrue(true);
    setCategoryValue(value);
  };

  const handleCheckboxChange = (item) => {
    if (selectedValues.length > 0) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }

    setFilterApplied(true);
    if (selectedValues.includes(item)) {
      setSelectedValues(selectedValues.filter((value) => value !== item));
    } else {
      setSelectedValues([...selectedValues, item]);
    }
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    } else {
      if (
        selectedValues.length > 0 ||
        locationValue.length > 0 ||
        categoryValue.length > 0 ||
        resetTrue
      ) {
        filterCampaignsDb();
      } else {
        getCampaignsFromServer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, locationValue, categoryValue]);

  const getCampaignsFromServer = async () => {
    try {
      dispatch(getCampaigns({ token }));
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  const getCategories = async () => {
    try {
      let response = await getCategoriesApi({
        Authorization: `Bearer ${token}`,
      });
      if (response?.status === 200) {
        setCategoriesList(response?.data?.data);
      }
    } catch (error) {
      toast.error("Error Fetching Categories.");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const [dropdownItems, setDropdownItems] = useState([
    "Fixed Payment",
    "Commission Percentage",
  ]);

  const filterCampaignsDb = async () => {
    try {
      let newFormData = new FormData();

      if (search.length > 0) {
        newFormData.append("brand_name", search);
      }

      if (locationValue.length > 0) {
        newFormData.append("location", locationValue);
      }

      if (categoryValue.length > 0) {
        newFormData.append("category", categoryValue);
      }

      if (selectedValues.length > 0) {
        if (selectedValues.includes("Fixed Payment")) {
          newFormData.append("type", "fixed");
        } else if (selectedValues.includes("Commission Percentage")) {
          newFormData.append("type", "commission");
        }
      }

      let header = {
        Authorization: `Bearer ${token}`,
      };

      dispatch(FilterCampaignsCreator({ header, formData: newFormData }));
      dropdownOpen();

      // if (response?.status === 200) {
      //   dispatch(setCampaigns(response?.data?.data))
      // }
    } catch (error) {
      console.log("error: ", error);
      setErrors(error?.response?.data?.error);
    }
  };

  const handleCampaignClick = (campaignId) => {
    if (user?.role == "1" && user?.account_verified == 1) {
      navigate("/campaignDetailsSection", { state: campaignId });
    }
  };

  const dropdownOpen = (event) => {
    if (event) {
      const obj = event.currentTarget;
      if (window.$(obj).find(".inner-dropdown").hasClass("open")) {
        window.$(obj).find(".inner-dropdown").removeClass("open");
      } else {
        window.$(".inner-dropdown").removeClass("open");
        window.$(obj).find(".inner-dropdown").addClass("open");
        window.$(obj).find(".inner-dropdown").removeClass("d-none");
      }
    } else {
      window.$(".inner-dropdown").removeClass("open");
    }
  };

  const dropdownBox = (event) => {
    event.stopPropagation();
  };

  // const categoryData = [
  //   "Cycling",
  //   "Cooking",
  //   "Dancing",
  //   "Singing",
  //   "Cycling",
  //   "Cooking",
  //   "Dancing",
  //   "Singing",
  // ];

  // const categoryData = [
  //   "Fashion",
  //   "Food",
  //   "Health",
  //   "Home",
  //   "Kid",
  //   "Services",
  //   "Sports",
  // ];

  const locationData = [
    "New York, USA",
    "Berlin, Germany",
    "Hankok, Germany",
    "City, Country",
    "London, UK",
    "Media City, UK",
  ];

  useEffect(() => {
    getCampaignsFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (categoriesList && categoriesList.length > 0) {
      const data = categoriesList.map((c) => c.name);
      setCategoryData(data);
    }
  }, [categoriesList, setCategoryData]);

  const filteredCategories = categoryData?.filter((category) =>
    category.toLowerCase().includes(searchInputIndustry)
  );

  const filteredLocation = locationData?.filter((item) =>
    item.toLowerCase().includes(searchInputLocation.toLowerCase())
  );

  return (
    <AppLayout>
      <div className="categories-dashboard p-5 ">
        <div className="container container-animation">
          <img
            className="purple-circle"
            src="/assets/images/circle_top1.png"
            alt="circle_top1"
          />
          <img
            className="yellow-circle"
            src="/assets/images/circle-03.png"
            alt="circle_top1"
          />
          <h5 className="mx-2">Campaigns</h5>
          <div className="categories-dashboard mt-3">
            <div className="fixed-secondary-bar campaigns-fixed-secondary-bar">
              <div className="search-bar " data-onboarding="step1">
                <div className="d-flex justify-content-between mobile-col">
                  <div className="campaign-search-input input-search wm-100">
                    <div className="d-flex align-items-center position-relative search-bar--input-container -tags">
                      <div
                        className="search-tag-position"
                        onClick={() => {
                          if (search.length) {
                            filterCampaignsDb();
                          }
                        }}
                      >
                        <FullSearchIcon
                          color={!search.length ? "#93BFC0" : null}
                          width="133px"
                        />
                      </div>
                      <span className="d-flex align-items-center w-100">
                        <div
                          className="ant-select ant-select-auto-complete search-bar--input css-10evijz ant-select-single ant-select-show-search w-100"
                          autoComplete="off"
                        >
                          <div className="ant-select-selector w-100">
                            <span className="ant-select-selection-search w-100">
                              <input
                                type="text"
                                className="ant-select-selection-search-input w-100"
                                id="search"
                                value={search}
                                placeholder="Search by Profile"
                                role="combobox"
                                aria-haspopup="listbox"
                                aria-owns="rc_select_8_list"
                                aria-autocomplete="list"
                                aria-controls="rc_select_8_list"
                                aria-activedescendant="rc_select_8_list_-1"
                                aria-expanded="false"
                                onChange={(event) =>
                                  setSearch(event.target.value)
                                }
                                onKeyUp={(event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault();
                                    if (search !== "") {
                                      filterCampaignsDb();
                                    }
                                  } else if (event.key === "Backspace") {
                                    if (search === "") {
                                      event.preventDefault();
                                      if (filterApplied) {
                                        filterCampaignsDb();
                                      } else {
                                        getCampaignsFromServer();
                                      }
                                    }
                                  } else if (
                                    event.ctrlKey &&
                                    event.key === "x"
                                  ) {
                                    if (
                                      event.target.selectionStart === 0 &&
                                      event.target.selectionEnd ===
                                      search.length
                                    ) {
                                      // If text is selected and then deleted (Ctrl + X)
                                      event.preventDefault();
                                      setSearch("");
                                      if (filterApplied) {
                                        filterCampaignsDb();
                                      } else {
                                        getCampaignsFromServer();
                                      }
                                    }
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* new section  */}
                  <div className="d-flex campaign-other-filters justify-content-evenly mx-2 wm-100">
                    {/* type dropdown  */}
                    <div
                      className="dropdown-box type-dropdown-box"
                      onClick={dropdownOpen}
                    >
                      <div className="dropdown-button d-flex">
                        <p className="m-0 w-100 d-flex align-items-center">
                          {selectedValues.length > 0
                            ? `Type: ${selectedValues.join(",")}`
                            : "Type"}
                        </p>
                        <div className="d-inline-block">
                          <img
                            src="/assets/images/arrow-sign.png"
                            alt=""
                            width={15}
                          />
                        </div>
                      </div>
                      <div
                        className="inner-dropdown mt-3"
                        onClick={dropdownBox}
                      >
                        <div className="type-dropdown-option">
                          {dropdownItems.map((item, index) => (
                            <div className="d-flex" key={index}>
                              <input
                                disabled={
                                  item === "Commission Percentage"
                                    ? true
                                    : false
                                }
                                type="checkbox"
                                name={index}
                                checked={selectedValues.includes(item)}
                                onChange={() => handleCheckboxChange(item)}
                                className="dropdown-checkbox mb-2"
                              />
                              <div className="ms-2 ">{item}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* category dropdown  */}
                    <div
                      className="dropdown-box dropdown-box-list"
                      onClick={dropdownOpen}
                    >
                      <div className="dropdown-button d-flex">
                        <p className="m-0 w-100 d-flex align-items-center">
                          {categoryValue
                            ? `Category: ${categoryValue}`
                            : "Category"}
                        </p>
                        <div className="d-inline-block">
                          <img
                            src="/assets/images/arrow-sign.png"
                            alt=""
                            width={15}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="inner-dropdown mt-3">
                        <div className="mx-2">
                          <div className="d-flex justify-content-between">
                            <input
                              type="search"
                              className="form-control form-control-sm search-input-style"
                              placeholder="Type to Search"
                              aria-controls="brand-detail-listing-table"
                              onChange={(e) =>
                                setSearchInputIndustry(e.target.value)
                              }
                              value={searchInputIndustry}
                              onClick={handleInputClick}
                            />
                            <div className="search-filter-icon">
                              <img
                                alt="s"
                                src="/assets/images/searchIcon-new.svg"
                              />
                            </div>
                            <div
                              onClick={() => {
                                setSearchInputIndustry("");
                                handleCategoryClick("");
                              }}
                              className="d-flex align-items-center text-dark fs-6 ms-2 link-style reset-filter-icon"
                            >
                              <img
                                src="/assets/images/reset-icon.svg"
                                alt="reset"
                              />

                              {/* Reset */}
                            </div>
                          </div>
                        </div>
                        <DropdownForList
                          data={filteredCategories}
                          handleClick={handleCategoryClick}
                        />
                      </div>
                    </div>

                    {/* location dropdown  */}
                    <div
                      className="dropdown-box dropdown-box-list"
                      onClick={dropdownOpen}
                    >
                      <div className="dropdown-button d-flex">
                        <p className="m-0 me-2 w-100 d-flex align-items-center text-truncate">
                          {locationValue
                            ? `Location: ${locationValue}`
                            : "Location"}
                        </p>
                        <div className="d-inline-block">
                          <img
                            src="/assets/images/arrow-sign.png"
                            alt=""
                            width={15}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="inner-dropdown mt-3">
                        <div className="mx-2">
                          <div className="d-flex justify-content-between">
                            <input
                              type="search"
                              className="form-control form-control-sm search-input-style"
                              placeholder="Type to Search"
                              aria-controls="brand-detail-listing-table"
                              onChange={(e) =>
                                setSearchInputLocation(e.target.value)
                              }
                              value={searchInputLocation}
                              onClick={handleInputClick}
                            />
                            <div className="search-filter-icon">
                              <img
                                alt="s"
                                src="/assets/images/searchIcon-new.svg"
                              />
                            </div>
                            <div
                              onClick={() => {
                                setSearchInputLocation("");
                                handleLocClick("");
                              }}
                              className="d-flex align-items-center text-dark fs-6 ms-2 link-style reset-filter-icon"
                            >
                              {/* Reset */}
                              <img
                                src="/assets/images/reset-icon.svg"
                                alt="reset"
                              />
                            </div>
                          </div>
                        </div>
                        <DropdownForList
                          data={filteredLocation}
                          handleClick={handleLocClick}
                        />
                      </div>
                    </div>
                    {/* Reset All Filters */}
                    <div
                      onClick={() => {
                        setResetTrue(false);

                        setSelectedValues([]);

                        setSearchInputIndustry("");
                        handleCategoryClick("");

                        setSearchInputLocation("");
                        handleLocClick("");

                        setSearch("");
                      }}
                      className="reset-all-filter-icon d-flex align-items-center "
                    >
                      {/* Reset */}
                      <img src="/assets/images/reset-icon.svg" alt="reset" />
                    </div>
                  </div>

                  {/* new section  */}
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-0 mt-0 mt-md-5 pt-md-5">
            {loading ? (
              <>
                <div className="p-5 m-5 d-flex justify-content-center">
                  <PuffLoader color="#65acae" size={42} />
                </div>
              </>
            ) : creatorCampaignData.length < 1 ? (
              <>
                <div className="nothing-found">No Campaigns Found!</div>
              </>
            ) : (
              <>
                <div className="brand-catalogue-box">
                  {creatorCampaignData?.map((cData, index) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            handleCampaignClick(cData?.id);
                          }}
                          className="col-md-6 col-lg-3 position-relative"
                        >
                          {cData && cData?.campaign_image ? (
                            <div className="image-overlay-container">
                              <img
                                src={cData?.campaign_image?.image}
                                alt={`CampaignImage - ${index}`}
                                className="creator-campaign-image"
                              />
                              <div className="image-overlay"></div>
                            </div>
                          ) : cData?.color ? (
                            <div className="image-overlay-container">
                              <span
                                className="d-block creator-campaign-image"
                                style={{
                                  backgroundColor: cData?.color,
                                }}
                              ></span>
                              <div className="image-overlay"></div>
                            </div>
                          ) : (
                            <div className="image-overlay-container">
                              <img
                                src="/assets/images/404_square.png"
                                alt={`CampaignImage Error - ${index}`}
                                className="creator-campaign-image"
                              />
                              <div className="image-overlay"></div>
                            </div>
                          )}
                          <div className="campaign-social">
                            <img
                              src="/assets/images/instaSocial.svg"
                              alt="insta"
                            />
                          </div>
                          <div className="creator-campaign-tag">
                            <div>{cData?.title ?? "N/A"}</div>
                            <div className="d-flex">
                              <div className="brand-name">
                                {cData?.brand?.brand_name ?? "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default CreatorCampaigns;
