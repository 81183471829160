import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { getReviews, getSingleBrandCall } from "../../Common/Repository";

const initialState = {
    loading: false,
    brandDetails: {},
    reviewData: [],
    approvedCollab: [],
    collabCount: 0,
    averageRating: null,
    reviewCount: 0,
    errors: null,
};

const brandDetailsSlice = createSlice({
    name: 'brandDetails',
    initialState,
    reducers: {
        setBrandDetails: (state, action) => {
            state.brandDetails = action.payload;
        },
        setReviewData: (state, action) => {
            state.reviewData = action.payload;
        },
        setApprovedCollab: (state, action) => {
            state.approvedCollab = action.payload;
        },
        setCollabCount: (state, action) => {
            state.collabCount = action.payload;
        },
        setAverageRating: (state, action) => {
            state.averageRating = action.payload;
        },
        setReviewCount: (state, action) => {
            state.reviewCount = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReviews.pending, (state) => {
                state.errors = null;
            })
            .addCase(getReviews.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getReviews.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.error.message;
            })
            .addCase(getSingleBrandCall.pending, (state) => {
                state.errors = null;
            })
            .addCase(getSingleBrandCall.fulfilled, (state, action) => {
                state.reviewData = action?.payload?.data?.reviews?.reviews;
                state.reviewCount = action?.payload?.data?.reviews?.total_reviews;
                state.averageRating = action?.payload?.data?.reviews?.average_rating;
                state.loading = false;
            })
            .addCase(getSingleBrandCall.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.error.message;
            })
            .addCase(logout, (state) => {
                state.brandDetails = {};
                state.reviewData = [];
                state.approvedCollab = [];
                state.collabCount = 0;
                state.averageRating = null;
                state.reviewCount = 0;
                state.errors = null;
            });
    },
});

export const {
    setLoading,
    setBrandDetails,
    setReviewData,
    setApprovedCollab,
    setCollabCount,
    setAverageRating,
    setReviewCount,
    setErrors
} = brandDetailsSlice.actions;
export default brandDetailsSlice.reducer;
