import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import { toast } from "react-toastify";
import {
  getInstagramAccount,
  updateCreatorProfile,
} from "../../Common/Repository";
import SelectAttachment from "../../Components/SelectAttachment";
// import SponserFile from "../../Components/SponserFile";
import TimePickerClock from "../../Components/TimePickerClock";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../states/auth/authSlice";
import IconButton from "@mui/material/IconButton";
import LightTooltip from "../../Components/LightToolTip";
import { InfoIcon, LinkIcon } from "../../Components/Icons";
import { PuffLoader } from "react-spinners";

const CreatorCompleteProfile = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state.auth);

  const [profileImage, setProfileImage] = useState();

  const [profileImageTemp, setProfileImageTemp] = useState(
    user?.creator?.profile_image
  );

  const [portfolioImage, setPortfolioImage] = useState(null);

  // const [sponsorImage, setSponsorImage] = useState(
  //   user?.creator?.sponsors && user.creator.sponsors[0]?.compressed_file
  // );

  const [showErrors, setShowErrors] = useState(null);

  const [finishLoader, setFinishLoader] = useState(false);

  const [experiencesActive, setExperiencesActive] = useState(false);

  const [linkActive, setLinkActive] = useState(false);

  const [nichesTagsActive, setNichesTagsActive] = useState(false);

  const [activeFrom, setActiveFrom] = useState(
    user?.creator?.active_hours_from ?? null
  );

  const [activeTo, setActiveTo] = useState(
    user?.creator?.active_hours_to ?? null
  );

  const [portfolioLink, setPortfolioLink] = useState(
    user?.creator?.creator_portfolios || []
  );


  const [sponsorDescription, setSponsorDescription] = useState(
    user?.creator?.sponsors && user.creator.sponsors[0]?.description
  );

  const [nichesTags, setNichesTags] = useState(user?.creator?.profile_tag);

  const [nichesString, setNichesString] = useState(
    nichesTags?.map((niche) => niche).join(", ")
  );

  const [experiences, setExperiences] = useState(
    user?.creator?.past_experiences.length > 0
      ? user?.creator?.past_experiences
      : [
        {
          from: "",
          to: "",
          brand_name: "",
          description: "",
        },
      ]
  );

  const [isLink, setIsLink] = useState(true);

  let [errorData, setErrorData] = useState(new Map());

  const [profileImageError, setProfileImageError] = useState(false);

  const [nichesError, setNichesError] = useState(false);

  const handleInputChange = (index, value, key) => {
    setExperiencesActive(true);
    let tmpExp = [...experiences];
    tmpExp[index][key] = value;
    setExperiences(tmpExp);
  };

  const [step, setStep] = useState(1);
  const [isStepLoading, setIsStepLoading] = useState(true);
  const [nextLoader, setNextLoader] = useState(false);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);

  // Using to populate Days and time
  useEffect(() => {
    if (user?.creator?.active_hours) {
      let activeHours = user?.creator?.active_hours?.days;
      if (activeHours) {
        if (
          activeHours[0].name === "Monday" &&
          activeHours[0].availability[0].start_time !== "null"
        ) {
          setMonday(true);
          setActiveFrom(activeHours[0].availability[0].start_time);
          setActiveTo(activeHours[0].availability[0].end_time);
        }
        if (
          activeHours[1].name === "Tuesday" &&
          activeHours[1].availability[0].start_time !== "null"
        ) {
          setTuesday(true);
          setActiveFrom(activeHours[1].availability[0].start_time);
          setActiveTo(activeHours[1].availability[0].end_time);
        }
        if (
          activeHours[2].name === "Wednesday" &&
          activeHours[2].availability[0].start_time !== "null"
        ) {
          setWednesday(true);
          setActiveFrom(activeHours[2].availability[0].start_time);
          setActiveTo(activeHours[2].availability[0].end_time);
        }
        if (
          activeHours[3].name === "Thursday" &&
          activeHours[3].availability[0].start_time !== "null"
        ) {
          setThursday(true);
          setActiveFrom(activeHours[3].availability[0].start_time);
          setActiveTo(activeHours[3].availability[0].end_time);
        }
        if (
          activeHours[4].name === "Friday" &&
          activeHours[4].availability[0].start_time !== "null"
        ) {
          setFriday(true);
          setActiveFrom(activeHours[4].availability[0].start_time);
          setActiveTo(activeHours[4].availability[0].end_time);
        }
        if (
          activeHours[5].name === "Saturday" &&
          activeHours[5].availability[0].start_time !== "null"
        ) {
          setSaturday(true);
          setActiveFrom(activeHours[5].availability[0].start_time);
          setActiveTo(activeHours[5].availability[0].end_time);
        }
        if (
          activeHours[6].name === "Sunday" &&
          activeHours[6].availability[0].start_time !== "null"
        ) {
          setSunday(true);
          setActiveFrom(activeHours[6].availability[0].start_time);
          setActiveTo(activeHours[6].availability[0].end_time);
        }
      }
    }
  }, []);

  useEffect(() => {
    const savedStep = localStorage.getItem("currentStep");
    if (savedStep) {
      setStep(Number(savedStep));
    } else {
      setStep(1);
    }
    setIsStepLoading(false);
  }, []);

  const handleNext = async () => {
    const isValid = await validateData();
    if (isValid) {
      if (step === 5) {
        navigate("/brandCatalogue");
      } else {
        const saveSuccess = await saveForLater();
        if (saveSuccess) {
          const newStep = step + 1;
          setStep(newStep);
          localStorage.setItem("currentStep", newStep);
          setNextLoader(false);
        }
      }
    }
  };

  const handleStepBack = () => {
    if (step === 1) {
      navigate(-1);
    } else {
      const newStep = step - 1;
      setStep(newStep);
      localStorage.setItem("currentStep", newStep);
    }
  };

  const handleBack = () => {
    saveForLater();
  };

  const hasValidExperiences = () => {
    return experiences.some(
      (exp) =>
        exp.from !== "" &&
        exp.to !== "" &&
        exp.brand_name !== "" &&
        exp.description !== ""
    );
  };

  const [openFileSelector, { filesContent, loading, error }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/jpeg, image/png",
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 50,
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // this callback is always called, even if there are errors
      handleProfileImageUpdate(filesContent);
      setProfileImageTemp(filesContent[0].content);
    },
  });

  const dataURItoBlob = (dataURI) => {
    if (dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }
  };

  const prepareFormData = (includeProfileComplete) => {
    const formData = new FormData();

    if (profileImage) formData.append(`profile_image`, profileImage);

    if (linkActive) {
      if (portfolioLink) formData.append(`portfolio_link`, portfolioLink);
    } else if (!linkActive && portfolioImage) {
      formData.append(`portfolio_files`, portfolioImage);
    }

    if (experiencesActive && experiences) {
      experiences.forEach((experience, index) => {
        for (const key in experience) {
          formData.append(`experiences[${index}][${key}]`, experience[key]);
        }
      });
    }

    let activeHours = {
      days: [
        { name: "Monday", availability: [] },
        { name: "Tuesday", availability: [] },
        { name: "Wednesday", availability: [] },
        { name: "Thursday", availability: [] },
        { name: "Friday", availability: [] },
        { name: "Saturday", availability: [] },
        { name: "Sunday", availability: [] },
      ],
    };

    if (
      monday ||
      tuesday ||
      wednesday ||
      thursday ||
      friday ||
      saturday ||
      sunday
    ) {
      const formattedActiveFrom = dayjs(activeFrom);
      const formattedActiveTo = dayjs(activeTo);

      // Loop through each day of the week and set availability accordingly
      activeHours.days.forEach((day) => {
        if (eval(day.name.toLowerCase())) {
          day.availability.push({
            start_time: formattedActiveFrom.format("HH:mm"),
            end_time: formattedActiveTo.format("HH:mm"),
          });
        } else {
          day.availability.push({ start_time: "null", end_time: "null" });
        }
      });
      formData.append("activeHours", JSON.stringify(activeHours));
    }

    if (nichesTagsActive) {
      // nichesTags.forEach((niche, index) => {
      //   formData.append(`niches[${index}][name]`, niche.name);
      // });
      formData.append("niches", nichesString);
    }
    // if (sponsorImage) {
    //   formData.append(`sponsors[0][compressed_file]`, sponsorImage);
    // }
    if (sponsorDescription) {
      formData.append(`sponsors[0][description]`, sponsorDescription);
    }
    if (includeProfileComplete) {
      formData.append(`profile_complete`, 1);
    }
    return formData;
  };

  const addInstagram = async () => {
    const response = await getInstagramAccount({
      Authorization: `Bearer ${token}`,
    });

    if (response.status === 200) {
      let faceBookLink = response.data?.facebook_auth_link;
      if (faceBookLink) {
        window.open(faceBookLink, "_blank", "noopener,noreferrer");
      }
    }
  };

  // const addInstagram = async () => {
  //   const response = await getInstagramAccount({
  //     Authorization: `Bearer ${token}`,
  //   });

  //   if (response.status === 200) {
  //     let faceBookLink = response.data?.facebook_auth_link;
  //     if (faceBookLink) {
  //       const popupFeatures = "width=600,height=600,top=100,left=100,scrollbars=yes,resizable=yes";
  //       window.open(faceBookLink, "popup", popupFeatures);
  //     }
  //   }
  // };



  const saveForLater = async () => {
    setNextLoader(true);
    const formData = prepareFormData();
    const success = await updateCurrentProfileStage(formData, "save");
    if (success) {
      return true;
    } else {
      toast.error("Failed to save information.");
      return false;
    }
  };

  const sendAllData = async () => {
    setFinishLoader(true);
    setShowErrors(null);
    const isValid = await validateData();
    if (isValid) {
      const formData = prepareFormData(true);
      const success = await updateCurrentProfileStage(formData, "finish");
      if (success) {
        setFinishLoader(false);
        // toast.success("Information Sent Successfully!");
        navigate(-1);
        document.getElementById("info-received-modal-btn")?.click();
      } else {
        setFinishLoader(false);
        toast.error("Failed to send information.");
      }
    } else {
      setFinishLoader(false);
    }
  };

  const updateCurrentProfileStage = async (formData, action) => {
    try {
      let response = await updateCreatorProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        formData
      );
      console.log('response update profile', response)
      if (response?.status === 200) {
        let updatedUser = { ...user };
        updatedUser.creator = response?.data?.data;
        dispatch(setUser(updatedUser));
        // setUser(updatedUser);
        // localStorage.setItem("user", JSON.stringify(updatedUser));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // setShowErrors(error?.response?.data?.error);
      setShowErrors(
        error?.response?.data?.error ?? error?.response?.data?.errors
      );
      return false;
    }
  };

  const validateData = async () => {
    let errorMap = new Map();
    if (
      step === 2 &&
      !profileImage &&
      !(
        user?.creator?.profile_image !== undefined &&
        user?.creator?.profile_image !== null
      )
    ) {
      errorMap.set("Profile", "Please upload your profile pic!");
      setProfileImageError(true);
    }
    if (
      (step === 5 && nichesString?.length < 1) ||
      (step === 5 && nichesString === undefined)
    ) {
      errorMap.set("Niches", "The niches field is required.");
      setNichesError(true);
    }

    setErrorData(errorMap);

    if (errorMap.size > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleProfileImageUpdate = async (filesContent) => {
    try {
      setProfileImageTemp(filesContent[0]?.content);
      let blobbedImage = dataURItoBlob(filesContent[0]?.content);
      setProfileImage(blobbedImage);
    } catch (error) {
      toast.error("Failed to update Profile Image");
    }
  };

  const handleImageUpload = async (attachment) => {
    setIsLink(false);
    try {
      let blobbedImage = dataURItoBlob(attachment[0]?.content);
      setPortfolioImage(blobbedImage);
    } catch (error) {
      toast.error("Failed to upload Portfolio File");
    }
  };

  // const handleSponserUpload = async (sponserImg) => {
  //   try {
  //     let blobbedImage = dataURItoBlob(sponserImg[0]?.content);
  //     setSponsorImage(blobbedImage);
  //   } catch (error) {
  //     toast.error("Failed to upload Sponsor File");
  //   }
  // };

  const deleteExperience = (index) => {
    const updatedExperiences = [...experiences];
    updatedExperiences.splice(index, 1);
    setExperiences(updatedExperiences);
  };
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    const isFormValid =
      activeFrom &&
      activeTo &&
      (monday ||
        tuesday ||
        wednesday ||
        thursday ||
        friday ||
        saturday ||
        sunday) &&
      nichesString.trim().length > 0;

    setIsNextDisabled(!isFormValid);
  }, [
    activeFrom,
    activeTo,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    nichesString,
  ]);
  return (
    <section className="campaign-faq-section-main">
      {isStepLoading ? (
        <>
          <div className="p-5 m-5 d-flex h-100 align-items-center justify-content-center">
            <PuffLoader color="#65acae" size={42} />
          </div>
        </>
      ) : (
        <>
          <div
            data-bs-toggle="modal"
            data-bs-target="#info-received-modal"
            id="info-received-modal-btn"
          ></div>
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="close-btn-box-profile me-3"
          >
            <img src="/assets/images/close-Icon.png" alt="X" />
          </div>
          <div className="m-0 p-0 w-100">
            <div className="col-md-12 p-0 d-flex align-items-center campaign-row">
              <div className="col-md-7 campaign-col col-12 pt-5">
                <img
                  src="/assets/images/right-footimg-opt.png"
                  className="right-campaign-img img-fluid position-absolute "
                  alt="..."
                />
                <img
                  className="purple-plus-right"
                  src="assets/images/purple_plus.png"
                  alt="plus"
                  width={16}
                />
                <img
                  className="purple-empty-circle-right"
                  src="assets/images/purple_empty_circle.png"
                  alt="empty_circle_2"
                  width={24}
                />
                <img
                  className="purple-plus-center"
                  src="assets/images/purple_plus.png"
                  alt="plus"
                  width={16}
                />
                <img
                  className="purple-empty-circle-center"
                  src="assets/images/purple_empty_circle.png"
                  alt="empty_circle_2"
                  width={16}
                />
                {step === 1 && (
                  <>
                    <div
                      id="profile-step-1"
                      className="verify-instagram-section complete-profile-section"
                    >
                      <div className="inner-section">
                        <div className="mb-5">
                          <div
                            onClick={() => handleStepBack()}
                            className="btn common-button-style-filled"
                          >
                            &#x2190; Back
                          </div>
                        </div>
                        <h5>Please verify your Instagram Business account:</h5>
                        <div className="my-3 my-md-5 completeProfileSubtitle">
                          <p
                            className="bottom-text"
                            style={{ color: "#000000" }}
                          >
                            <span
                              className="text-highlight"
                              style={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                lineHeight: "22px",
                              }}
                            >
                              Attention! Connect your Instagram Business Account
                              in few steps.
                            </span>
                          </p>
                          <ol style={{ color: "#000000", fontSize: "18px" }}>
                            <li>
                              Log into Instagram. If you have a personal
                              Instagram account, you'll switch to a professional
                              account in this step.
                            </li>
                            <li>
                              Connect your Instagram account to a Facebook Page.
                              Only Page admins can do this.
                            </li>
                            <li>
                              Allowing permissions for Vitrine to access
                              following information:
                              <ul style={{ listStyleType: "disc" }}>
                                <li>Name</li>
                                <li>Username</li>
                                <li>Followers Count</li>
                              </ul>
                            </li>
                          </ol>
                          <br />
                          <a
                            className="link-style"
                            style={{
                              color: "#63abad",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            href="https://youtu.be/8l9mUdxgDmU?si=bsLGuTISUpOC855v"
                            target="_blank"
                          >
                            <LinkIcon /> How to Connect Facebook Page to
                            Instagram Business Account
                          </a>
                          <br />
                          <a
                            className="link-style"
                            style={{
                              color: "#63abad",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            href="https://youtu.be/2h_tnY9hrMc?si=PO3Cb9u_vQNQ3h1J"
                            target="_blank"
                          >
                            <LinkIcon /> How to Switch Instagram to Business
                            Account
                          </a>
                          {/* <p className="bottom-text" style={{ color: "#000000" }}>
                        Make sure you have your Instagram username and password ready.
                      </p> */}
                          {/* You will be redirected to Instagram to verify your account. */}
                        </div>
                        <div className="mb-5 text-center text-md-start">
                          <button
                            disabled={user?.instagram_verified_at !== null ? true : false}
                            onClick={() => addInstagram()}
                            type="button"
                            className="btn common-button-style-filled"
                          >
                            <img
                              src="/assets/images/InstaIconWhite.svg"
                              alt="insta"
                              className="mb-1 me-2"
                              height={20}
                            />
                            {user?.instagram_verified_at !== null ? "Connected With Instagram" : "Connect Instagram Business"}
                          </button>
                        </div>
                        <div className="d-flex justify-content-between flex-column md:flex-row">
                          {/* <button
                            className="btn back-btn mb-2 md:mb-0"
                            onClick={() => handleBack()}
                          >
                            Save for Later
                          </button> */}
                          <button
                            disabled={
                              user?.instagram_verified_at === !null
                                ? true
                                : false
                            }
                            className="btn d-flex justify-content-center common-button-style-filled next-btn md:me-4"
                            onClick={handleNext}
                          >
                            {nextLoader ? (
                              <PuffLoader
                                className="justify-content-center"
                                color="white"
                                size={22}
                              />
                            ) : (
                              "Next"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-numbering mt-5">
                      <h4 id="bottom-numbers">
                        {step.toString().padStart(2, "0")}
                        <span className="light-green-color"> /05</span>
                      </h4>
                    </div>
                  </>
                )}
                {step === 2 && (
                  <>
                    <div
                      id="profile-step-2"
                      className="add-profile-pic-section complete-profile-section"
                    >
                      <div className="inner-section w-100">
                        <h5 className="text-start">
                          <div className="mb-5">
                            <div
                              onClick={() => {
                                handleStepBack();
                              }}
                              className="btn common-button-style-filled"
                            >
                              &#x2190; Back
                            </div>
                          </div>
                          <div className="d-flex">
                            <div style={{ fontSize: "21px" }}>
                              Please Upload Your Image:
                            </div>
                            <span className="">
                              <LightTooltip
                                placement="right"
                                title="Please upload a proper image showing your face with a clean background. This will be used through out the platform for your display picture."
                              >
                                <IconButton>
                                  <InfoIcon width="18" height="18" />
                                </IconButton>
                              </LightTooltip>
                            </span>
                          </div>
                        </h5>
                        {profileImageError ? (
                          <div className="text-danger mt-2">
                            {errorData.get("Profile")}
                          </div>
                        ) : (
                          ""
                        )}
                        {showErrors != null && showErrors.profile_image ? (
                          <>
                            <div className="text-danger mt-2">
                              {showErrors.profile_image[0]}
                            </div>
                          </>
                        ) : null}
                        {(user?.creator?.profile_image !== undefined &&
                          user?.creator?.profile_image !== null) || filesContent.length > 0
                          ? (
                            <>
                              <div className="mt-3 d-flex justify-content-center">
                                <img
                                  onClick={() => {
                                    openFileSelector();
                                  }}
                                  className="brand-profile-image"
                                  src={profileImageTemp}
                                  alt="avatar"
                                />
                              </div>
                              <div
                                onClick={() => {
                                  openFileSelector();
                                }}
                                className="d-flex align-center my-5 link-style"
                              >
                                Change Image
                              </div>
                            </>
                          ) : (
                            <div
                              onClick={() => {
                                openFileSelector();
                                const newError = {
                                  ...showErrors,
                                  profile_image: [""],
                                };
                                setShowErrors(newError);
                              }}
                              className={`${filesContent.length > 0 ? "my-3" : "image-circle"
                                }`}
                            >
                              <div className="mt-3 d-flex justify-content-center">

                                <img
                                  className={`${filesContent.length > 0
                                    ? "brand-profile-image"
                                    : ""
                                    }`}
                                  src={
                                    filesContent[0]?.content
                                      ? filesContent[0].content
                                      : "/assets/images/Upload_Image.svg"
                                  }
                                  alt="uploadImage"
                                />
                              </div>
                            </div>
                          )}

                        <div className="d-flex justify-content-start flex-column md:flex-row">
                          {/* <button
                            className="btn back-btn md:me-5 mb-2 md:mb-0"
                            onClick={() => handleBack()}
                          >
                            Save for Later
                          </button> */}
                          <button
                            disabled={
                              (user?.creator?.profile_image !== undefined &&
                                user?.creator?.profile_image !== null) || filesContent.length > 0
                                ? false
                                : true
                            }
                            className="btn common-button-style-filled next-btn md:me-4 d-flex justify-content-center"
                            onClick={handleNext}
                          >
                            {nextLoader ? (
                              <PuffLoader
                                className="justify-content-center"
                                color="white"
                                size={22}
                              />
                            ) : (
                              "Next"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-numbering mt-5">
                      <h4 id="bottom-numbers">
                        {step.toString().padStart(2, "0")}
                        <span className="light-green-color"> /05</span>
                      </h4>
                    </div>
                  </>
                )}
                {step === 3 && (
                  <>
                    <div
                      id="profile-step-3"
                      className="upload-portfolio-section complete-profile-section"
                    >
                      <div className="inner-section">
                        <div className="mb-5">
                          <div
                            onClick={() => {
                              handleStepBack();
                            }}
                            className="btn common-button-style-filled"
                          >
                            &#x2190; Back
                          </div>
                        </div>
                        <h5>
                          Add Link or Upload Portfolio
                          <LightTooltip
                            placement="right"
                            title="Showcase your work by providing a link to your online portfolio or uploading it directly here. You can upload content of prior collaborations (screenshots of posts in collaboration or links to videos/content posted in paid marketing posts). In the next section you will have the opportunity to add past experiences and the names of the brands you worked with.  This is a chance to display your previous projects and achievements to potential collaborators or clients. You can drag and drop files or select them for upload to share your professional highlights and skills."
                          >
                            <IconButton>
                              <InfoIcon width="18" height="18" />
                            </IconButton>
                          </LightTooltip>
                        </h5>
                        <div>Include the URL for your protfolio</div>
                        <div>
                          <input
                            disabled={isLink ? false : true}
                            type="search"
                            className="complete-profile-input-portfolio"
                            id="search"
                            placeholder="https://www..."
                            value={portfolioLink[0]?.value || portfolioLink}
                            onChange={(event) => {
                              const value = event.target.value;
                              setPortfolioLink(value);
                              setLinkActive(value.trim().length > 0);
                            }}
                            onKeyUp={(event) => {
                              if (
                                event.key === "Backspace" ||
                                event.key === "Delete"
                              ) {
                                const value = event.target.value;
                                setLinkActive(value.trim().length > 0);
                              }
                            }}
                          />
                        </div>
                        <h5 className="mt-3 d-flex justify-content-center">
                          OR
                        </h5>
                        <div className="d-flex justify-content-start my-3">
                          <SelectAttachment
                            handleFileUpload={handleImageUpload}
                            setLinkActive={setLinkActive}
                            linkActive={linkActive}
                          />
                        </div>
                        <div className="d-flex justify-content-between pe-5 w-100 md:w-75 flex-column md:flex-row">

                          <button
                            disabled={!linkActive && !portfolioImage}
                            className="btn common-button-style-filled next-btn md:me-4 d-flex justify-content-center"
                            onClick={handleNext}
                          >
                            {nextLoader ? (
                              <PuffLoader
                                className="justify-content-center"
                                color="white"
                                size={22}
                              />
                            ) : (
                              "Next"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-numbering">
                      <h4 id="bottom-numbers">
                        {step.toString().padStart(2, "0")}
                        <span className="light-green-color"> /05</span>
                      </h4>
                    </div>
                  </>
                )}
                {step === 4 && (
                  <>
                    <div
                      id="profile-step-4"
                      className="add-past-experience-section complete-profile-section"
                    >
                      <div className="inner-section">
                        <div className="mb-5">
                          <div
                            onClick={() => {
                              handleStepBack();
                            }}
                            className="btn common-button-style-filled"
                          >
                            &#x2190; Back
                          </div>
                        </div>
                        <h5>
                          Add your past experiences
                          <LightTooltip
                            placement="right"
                            title="Use this space to highlight your professional history and significant collaborations. Enter the time frame, the brand you worked with, and a brief description of your role and contributions. This information helps build your profile and credibility by illustrating your experience and track record to potential partners or other creators."
                          >
                            <IconButton>
                              <InfoIcon width="18" height="18" />
                            </IconButton>
                          </LightTooltip>
                        </h5>
                        <div className="my-4">
                          Add your past experiences with the Brands
                        </div>
                        <div className="row d-flex justify-content-between">
                          {experiences.map((experience, index) => {
                            return (
                              <>
                                <div className=" row w-100">
                                  <div className="col-md-4">
                                    <h6 className="ps-3">From</h6>
                                    <input
                                      type="date"
                                      className="ant-select-selection-search-input"
                                      placeholder={"From"}
                                      max={
                                        new Date().toISOString().split("T")[0]
                                      } // Disable the future dates
                                      value={experience.from || ""}
                                      onChange={(e) => {
                                        const newError = {
                                          ...showErrors,
                                          ["experiences." + index + ".from"]: [
                                            "",
                                          ],
                                          experiences_from: [""],
                                        };
                                        setShowErrors(newError);
                                        handleInputChange(
                                          index,
                                          e.target.value,
                                          "from"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <h6 className="ps-3">To</h6>
                                    <input
                                      type="date"
                                      className="ant-select-selection-search-input"
                                      placeholder={"To"}
                                      min={experience.from.split("T")[0]}
                                      max={
                                        new Date().toISOString().split("T")[0]
                                      } // Disable the future dates
                                      value={experience.to || ""}
                                      onChange={(e) => {
                                        const newError = {
                                          ...showErrors,
                                          ["experiences." + index + ".to"]: [
                                            "",
                                          ],
                                          experiences_to: [""],
                                        };
                                        setShowErrors(newError);
                                        handleInputChange(
                                          index,
                                          e.target.value,
                                          "to"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <h6 className="ps-3">Brand Name</h6>
                                    <input
                                      type="text"
                                      className="ant-select-selection-search-input ps-3"
                                      placeholder={"Brand Name"}
                                      value={experience.brand_name || ""}
                                      onChange={(e) => {
                                        const newError = {
                                          ...showErrors,
                                          experiences_brand_name: [""],
                                        };
                                        setShowErrors(newError);
                                        handleInputChange(
                                          index,
                                          e.target.value,
                                          "brand_name"
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4">
                                    {showErrors &&
                                      showErrors[
                                      "experiences." + index + ".from"
                                      ] && (
                                        <div className="text-danger mt-2">
                                          {showErrors[
                                            "experiences." + index + ".from"
                                          ][0]
                                            .replace(
                                              "experiences." + index + ".to",
                                              "To Date"
                                            )
                                            .replace(
                                              "experiences." + index + ".from",
                                              "From Date"
                                            )}
                                        </div>
                                      )}
                                    {showErrors != null &&
                                      showErrors.experiences_from ? (
                                      <>
                                        <div className="text-danger mt-2">
                                          {showErrors.experiences_from[index]}
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4">
                                    {showErrors &&
                                      showErrors[
                                      "experiences." + index + ".to"
                                      ] && (
                                        <div className="text-danger mt-2">
                                          {showErrors[
                                            "experiences." + index + ".to"
                                          ][0]
                                            .replace(
                                              "experiences." + index + ".to",
                                              "To Date"
                                            )
                                            .replace(
                                              "experiences." + index + ".from",
                                              "From Date"
                                            )}
                                        </div>
                                      )}
                                    {showErrors != null &&
                                      showErrors.experiences_to ? (
                                      <>
                                        <div className="text-danger mt-2">
                                          {showErrors.experiences_to[index]}
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4">
                                    {showErrors != null &&
                                      showErrors.experiences_brand_name ? (
                                      <>
                                        <div className="text-danger mt-2">
                                          {
                                            showErrors.experiences_brand_name[
                                            index
                                            ]
                                          }
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="mt-4 p-0">
                                  <h6 className="ps-3">Description</h6>
                                  <input
                                    type="text"
                                    className="ant-select-selection-search-input description-input w-100 ps-3"
                                    placeholder={
                                      "Short description of the work you done"
                                    }
                                    value={experience.description || ""}
                                    onChange={(e) => {
                                      const newError = {
                                        ...showErrors,
                                        experiences: [""],
                                      };
                                      setShowErrors(newError);
                                      handleInputChange(
                                        index,
                                        e.target.value,
                                        "description"
                                      );
                                    }}
                                  />
                                  {showErrors != null &&
                                    showErrors.experiences ? (
                                    <>
                                      <div className="text-danger mt-2">
                                        {showErrors.experiences[index]}
                                      </div>
                                    </>
                                  ) : null}
                                  <div
                                    onClick={() => {
                                      if (experiences.length < 5) {
                                        setExperiences([
                                          ...experiences,
                                          {
                                            from: "",
                                            to: "",
                                            brand_name: "",
                                            description: "",
                                          },
                                        ]);
                                      } else {
                                        return toast.error(
                                          "You can only add upto 5 experiences"
                                        );
                                      }
                                    }}
                                    className="add-more-button"
                                  >
                                    {index === experiences.length - 1 && (
                                      <div>Add More +</div>
                                    )}
                                  </div>
                                  <div className="text-center mb-5">
                                    {experiences.length > 1 && (
                                      <div
                                        onClick={() => deleteExperience(index)}
                                        className="delete-div link-style"
                                      >
                                        remove
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>

                        <div className="d-flex justify-content-between pe-5 w-100 md:w-75 flex-column md:flex-row">
                          {/* <button
                            className="btn back-btn mb-2 md:mb-0"
                            onClick={() => handleBack()}
                          >
                            Save for Later
                          </button> */}
                          <button
                            disabled={!hasValidExperiences()}
                            className="btn common-button-style-filled next-btn d-flex justify-content-center"
                            onClick={handleNext}
                          >
                            {nextLoader ? (
                              <PuffLoader
                                className="justify-content-center"
                                color="white"
                                size={22}
                              />
                            ) : (
                              "Next"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-numbering">
                      <h4 id="bottom-numbers">
                        {step.toString().padStart(2, "0")}
                        <span className="light-green-color"> /05</span>
                      </h4>
                    </div>
                  </>
                )}
                {step === 5 && (
                  <>
                    <div
                      id="profile-step-5"
                      className="add-more-info-section complete-profile-section"
                    >
                      <div className="inner-section w-100">
                        <div className="mb-5">
                          <div
                            onClick={() => {
                              handleStepBack();
                            }}
                            className="btn common-button-style-filled"
                          >
                            &#x2190; Back
                          </div>
                        </div>
                        <h5>Add more info that is required</h5>
                        <div className="mt-4 d-flex align-items-center">
                          Your active hours
                          <span
                            style={{
                              color: "red",
                              fontSize: "28px",
                              marginLeft: "4px",
                            }}
                          >
                            *
                          </span>
                        </div>

                        <div className="row">
                          <div className="col">
                            <TimePickerClock
                              activeFrom={activeFrom}
                              setActiveFrom={setActiveFrom}
                            />
                          </div>
                          <div className="col">
                            <TimePickerClock
                              activeTo={activeTo}
                              setActiveTo={setActiveTo}
                            />
                          </div>

                          <div className="col">
                            {/* <input
                        type="search"
                        className="ant-select-selection-search-input w-100"
                        id="search"
                        placeholder="Days"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_8_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_8_list"
                        aria-activedescendant="rc_select_8_list_-1"
                        aria-expanded="false"
                        value={activeDays}
                        onChange={(event) => {
                          setActiveDays(event.target.value);
                        }}

                      /> */}
                          </div>
                        </div>
                        <div className="daysRow">
                          <div className="days d-flex">
                            <input
                              type="checkbox"
                              id="days"
                              name="days"
                              value={monday}
                              defaultChecked={monday}
                              onChange={() => {
                                setMonday((prev) => !prev);
                              }}
                            />
                            <div className="ms-1">Monday</div>
                          </div>
                          <div className="days d-flex">
                            <input
                              type="checkbox"
                              id="days"
                              name="days"
                              value={tuesday}
                              defaultChecked={tuesday}
                              onChange={() => {
                                setTuesday((prev) => !prev);
                              }}
                            />
                            <div className="ms-1">Tuesday</div>
                          </div>
                          <div className="days d-flex">
                            <input
                              type="checkbox"
                              id="days"
                              name="days"
                              value={wednesday}
                              defaultChecked={wednesday}
                              onChange={() => {
                                setWednesday((prev) => !prev);
                              }}
                            />
                            <div className="ms-1">Wednesday</div>
                          </div>
                          <div className="days d-flex">
                            <input
                              type="checkbox"
                              id="days"
                              name="days"
                              value={thursday}
                              defaultChecked={thursday}
                              onChange={() => {
                                setThursday((prev) => !prev);
                              }}
                            />
                            <div className="ms-1">Thursday</div>
                          </div>
                          <div className="days d-flex">
                            <input
                              type="checkbox"
                              id="days"
                              name="days"
                              value={friday}
                              defaultChecked={friday}
                              onChange={() => {
                                setFriday((prev) => !prev);
                              }}
                            />
                            <div className="ms-1">Friday</div>
                          </div>
                          <div className="days d-flex">
                            <input
                              type="checkbox"
                              id="days"
                              name="days"
                              value={saturday}
                              defaultChecked={saturday}
                              onChange={() => {
                                setSaturday((prev) => !prev);
                              }}
                            />
                            <div className="ms-1">Saturday</div>
                          </div>
                          <div className="days d-flex">
                            <input
                              type="checkbox"
                              id="days"
                              name="days"
                              value={sunday}
                              defaultChecked={sunday}
                              onChange={() => {
                                setSunday((prev) => !prev);
                              }}
                            />
                            <div className="ms-1">Sunday</div>
                          </div>
                        </div>
                        <div>
                          <div className="my-2 d-flex align-items-center">
                            Add your niche, if more than one separate it with
                            comma
                            <span
                              style={{
                                color: "red",
                                fontSize: "28px",
                                marginLeft: "4px",
                              }}
                            >
                              *
                            </span>
                          </div>
                          <input
                            type="search"
                            className="ant-select-selection-search-input w-100"
                            id="search"
                            placeholder="e.g. Blog Ads"
                            role="combobox"
                            aria-haspopup="listbox"
                            aria-owns="rc_select_8_list"
                            aria-autocomplete="list"
                            aria-controls="rc_select_8_list"
                            aria-activedescendant="rc_select_8_list_-1"
                            aria-expanded="false"
                            onkeyup="search();"
                            onFocus={() => setNichesTagsActive(true)}
                            onChange={(event) => {
                              const newError = {
                                ...showErrors,
                                niches: [""],
                              };
                              setShowErrors(newError);
                              setNichesString(event.target.value);
                              const nichesArray = event.target.value
                                .split(", ")
                                .map((name) => ({ name }));
                              setNichesTags(nichesArray);
                            }}
                            value={nichesString}
                          />
                          {nichesError ? (
                            <div className="mb-2 text-danger">
                              {errorData.get("Niches")}
                            </div>
                          ) : (
                            ""
                          )}
                          {showErrors != null && showErrors?.niches ? (
                            <>
                              <div className="text-danger mt-2">
                                {showErrors?.niches[0]}
                              </div>
                            </>
                          ) : null}
                        </div>

                        <div className="my-2">
                          Do you have any active sponsors? If yes please mention
                          below:
                        </div>
                        <div className="position-relative">
                          <input
                            type="search"
                            className="ant-select-selection-search-input w-100"
                            id="search"
                            placeholder="e.g. Nike"
                            role="combobox"
                            aria-haspopup="listbox"
                            aria-owns="rc_select_8_list"
                            aria-autocomplete="list"
                            aria-controls="rc_select_8_list"
                            aria-activedescendant="rc_select_8_list_-1"
                            aria-expanded="false"
                            onkeyup="search();"
                            value={sponsorDescription}
                            onChange={(event) => {
                              setSponsorDescription(event.target.value);
                            }}
                          />
                          {/* <SponserFile handleFileUpload={handleSponserUpload} /> */}
                        </div>
                        <div className="d-flex justify-content-start mt-5">
                          <div className="w-100 md:w-75 d-flex justify-content-between flex-column md:flex-row">
                            {/* <button
                              className="btn back-btn mb-2 md:mb-0"
                              onClick={() => handleBack()}
                            >
                              Save for Later
                            </button> */}
                            {step === 5 ? (
                              <>
                                <button
                                  disabled={isNextDisabled}
                                  className="btn common-button-style-filled next-btn"
                                  // onClick={() => { submitProfileDetails("finish") }}
                                  onClick={sendAllData}
                                >
                                  {finishLoader ? (
                                    <>
                                      <PuffLoader
                                        className="mx-auto"
                                        color="white"
                                        size={20}
                                      />
                                    </>
                                  ) : (
                                    "Finish"
                                  )}
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  disabled={isNextDisabled}
                                  className="btn common-button-style-filled next-btn me-4 d-flex justify-content-center"
                                  onClick={handleNext}
                                >
                                  {nextLoader ? (
                                    <PuffLoader
                                      className="justify-content-center"
                                      color="white"
                                      size={22}
                                    />
                                  ) : (
                                    "Next"
                                  )}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-numbering mt-5">
                      <h4 id="bottom-numbers">
                        {step.toString().padStart(2, "0")}
                        <span className="light-green-color"> /05</span>
                      </h4>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-5 h-100 bg-white-op-half">
                <div className="campaign-images-sec h-100 position-relative">
                  <div className="d-flex justify-content-end contact-btn-box-outer w-100 mt-3 position-absolute"></div>
                  <div className="inner-curve">
                    <img
                      id="complete-profile-img1"
                      className={`img-fluid sec-img ${step === 1 ? "active-image" : ""
                        }`}
                      src="/assets/images/complete_profile_1.png"
                      alt="img1"
                    />
                    <img
                      id="complete-profile-img2"
                      className={`img-fluid sec-img ${step === 2 ? "active-image" : ""
                        }`}
                      src="/assets/images/complete_profile_2.png"
                      alt="img2"
                    />
                    <img
                      id="complete-profile-img3"
                      className={`img-fluid sec-img ${step === 3 ? "active-image" : ""
                        }`}
                      src="/assets/images/complete_profile_3.png"
                      alt="img3"
                    />
                    <img
                      id="complete-profile-img4"
                      className={`img-fluid sec-img ${step === 4 || step === 5 ? "active-image" : ""
                        }`}
                      src="/assets/images/person-right-image.png"
                      alt="img4"
                    />
                    <img
                      id="complete-profile-img4-sub"
                      className={`img-fluid sec-img ${step === 4 || step === 5 ? "active-image" : ""
                        }`}
                      src="/assets/images/brand_signup_logo.png"
                      alt="img4"
                    />
                    {/* <img
                  id="complete-profile-img5"
                  className={`img-fluid sec-img ${step === 5 ? "active-image" : ""
                    }`}
                  src="/assets/images/girl_talking.svg"
                  alt="img5"
                /> */}
                    {/* Repeat the above structure for other steps */}
                  </div>
                  {/* <img
                className="yellow-circle-ball"
                src="/assets/images/circle_3.png"
                alt="circle_3"
              /> */}
                </div>
              </div>
            </div>
          </div>
          {/* INFO RECEIVED MODAL  */}
          <div
            className="modal fade mt-5 pt-5 "
            id="info-received-modal"
            tabIndex={-1}
            aria-labelledby="info-received-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog user-settings-modal">
              <div className="modal-content">
                <div className="modal-body position-relative">
                  <img
                    className="blue-ball-popup"
                    src="/assets/images/blue_ball.png"
                    alt="blue-ball"
                    width={30}
                  />
                  <img
                    className="popup-yellow-circle position-absolute"
                    src="assets/images/yellow_circle.png"
                    alt="circle_3"
                    width={16}
                  />
                  <img
                    className="purple-empty-circle-popup"
                    src="assets/images/purple_empty_circle.png"
                    alt="empty_circle"
                    width={20}
                  />
                  <img
                    className="purple-plus-popup"
                    src="assets/images/purple_plus.png"
                    alt="plus"
                    width={16}
                  />
                  <img
                    className="purple-empty-circle-2-popup position-absolute"
                    src="assets/images/purple_empty_circle.png"
                    alt="empty_circle_2"
                    width={16}
                  />
                  <div
                    className=" close-btn-box new-lg-close-position"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src="/assets/images/close-Icon2.svg" alt="X" />
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <img
                      src="/assets/images/info_received_icon.svg"
                      alt="Info Received"
                      width={250}
                    />
                  </div>
                  <div className="mt-3 info-heading">Info Received</div>
                  <div className="d-flex justify-content-center">
                    <p className="w-75 px-5 info-subtitle">
                      We have received your information and we will notify you
                      by email if your information is validated!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
      }
    </section >
  );
};

export default CreatorCompleteProfile;
