import React, { useState } from "react";

const GenderSelect = ({ resetButton, width, gender, handleSelectGender }) => {
  // const [activeImage, setActiveImage] = useState("");
  const [activeChoice, setActiveChoice] = useState(
    gender === "female"
      ? "women_select"
      : gender === "male"
      ? "men_select"
      : gender === "other"
      ? "other"
      : ""
  );

  const handleClick = (value) => {
    if (value === "reset" || value === "") {
      handleSelectGender(value);
      setActiveChoice(""); // Reset active value when the reset is clicked
    } else {
      handleSelectGender(
        value === "women_select"
          ? "female"
          : value === "men_select"
          ? "male"
          : value === "other"
          ? "other"
          : ""
      );
      setActiveChoice(value);
    }
  };

  return (
    <>
      <div className="w-100 inputWrapper">
        <label className="w-100">
          <input
            type="radio"
            value="all"
            checked={activeChoice === ""}
            onChange={() => handleClick("")}
          />
          All
        </label>
        <label className="w-100">
          <input
            type="radio"
            value="men_select"
            checked={activeChoice === "men_select"}
            onChange={() => handleClick("men_select")}
          />
          Male
        </label>
        <label className="w-100">
          <input
            type="radio"
            value="women_select"
            checked={activeChoice === "women_select"}
            onChange={() => handleClick("women_select")}
          />
          Female
        </label>
        <label className="w-100">
          <input
            type="radio"
            value="men_select"
            checked={activeChoice === "other"}
            onChange={() => handleClick("other")}
          />
          Other
        </label>
      </div>
      {resetButton === "resetButton" ? (
        <>
          <div
            onClick={() => handleClick("reset")}
            className="d-flex align-items-center text-dark fs-6 mt-2 resetButton"
          >
            Reset
          </div>
        </>
      ) : null}
    </>
  );
};

export default GenderSelect;
