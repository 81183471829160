import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import {
  getAllPacksForBrandCall,
  getSingleCreatorCall,
  sendCollaborationCall,
} from "../../Common/Repository";

const initialState = {
  loading: false,
  inviteLoading: false,
  isFavourite: false,
  error: null,
  success: false,
  creatorsCollaborationData: [],
  singleCreator: {},
  reviewsReceived: [],
  averageRating: null,
  reviewCount: 0,
  creatorPacksData: [],
};

const creatorDetailsSlice = createSlice({
  name: "creatorDetails",
  initialState,
  reducers: {
    setCreatorsCollaborationData: (state, action) => {
      state.creatorsCollaborationData = action.payload;
    },
    setSingleCreatorData: (state, action) => {
      state.singleCreator = action.payload;
      state.isFavourite =
        state.singleCreator?.favourite_creator?.is_favourite === 1 ||
        state.singleCreator?.favourite_creator?.is_favourite === "1";
    },
    setReviewsReceived: (state, action) => {
      state.reviewsReceived = action.payload;
    },
    setAverageReviewRating: (state, action) => {
      state.averageRating = action?.payload;
    },
    setTotalReviewCount: (state, action) => {
      state.reviewCount = action?.payload;
    },
    setCreatorPacksData: (state, action) => {
      state.creatorPacksData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFavourite: (state, action) => {
      state.isFavourite = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendCollaborationCall.pending, (state) => {
        state.inviteLoading = true;
        state.error = null;
      })
      .addCase(sendCollaborationCall.fulfilled, (state, action) => {
        state.inviteLoading = false;
        state.error = null;
      })
      .addCase(sendCollaborationCall.rejected, (state, action) => {
        state.inviteLoading = false;
        state.error = action.payload;
      })
      .addCase(getSingleCreatorCall.pending, (state) => {
        state.error = null;
      })
      .addCase(getSingleCreatorCall.fulfilled, (state, action) => {
        state.creatorsCollaborationData = action.payload?.data?.collaborations;
        state.averageRating = action?.payload?.data?.data?.average_rating;
        state.reviewCount = action?.payload?.data?.data?.total_reviews;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSingleCreatorCall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllPacksForBrandCall.pending, (state) => {
        if (!state.creatorPacksData.length) {
          state.loading = true;
        }
        state.error = null;
      })
      .addCase(getAllPacksForBrandCall.fulfilled, (state, action) => {
        state.creatorPacksData = action.payload?.data?.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllPacksForBrandCall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logout, (state) => {
        return initialState;
      });
  },
});

export const {
  setCreatorsCollaborationData,
  setSingleCreatorData,
  setReviewsReceived,
  setCreatorPacksData,
  setTotalReviewCount,
  setAverageReviewRating,
  setLoading,
  setFavourite,
} = creatorDetailsSlice.actions;
export default creatorDetailsSlice.reducer;
