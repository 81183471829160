import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import {
  getInstagramAccount,
  getUserPublicProfile,
  getUserReviews,
  intiateAddBankApi,
  updateCreatorProfile,
} from "../../Common/Repository";
import Reviews from "../../Components/Reviews";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFilePicker } from "use-file-picker";
import CircleImageNFound from "../../Components/404_CircleImage";
import { EmailIcon, InfoIcon, LinkIcon, PenIcon } from "../../Components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../states/auth/authSlice";
import PhoneInput from "react-phone-number-input";

import IconButton from "@mui/material/IconButton";
import LightTooltip from "../../Components/LightToolTip";
import { PuffLoader } from "react-spinners";

const CreatorsProfile = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state.auth);

  const [isReviews, setIsReviews] = useState(false);
  const [instaLoader, setInstaLoader] = useState(false);
  const [profileName, setName] = useState(user?.creator?.profile_name ?? "");
  const [bio, setBio] = useState(user?.creator?.bio ?? "");
  const [location, setLocation] = useState(user?.creator?.location ?? "");
  const [address, setAddress] = useState(user?.creator?.address ?? "");
  const [phone, setPhone] = useState(user?.creator?.phone_number ?? "");
  const [reviewsReceived, setReviewsReceived] = useState("");
  const [averageReviews, setAverageReviews] = useState("");
  const [totalReviewCount, setTotalReviewCount] = useState("");
  const [errors, setErrors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(
    user?.creator?.profile_tag !== null &&
      user?.creator?.profile_tag !== undefined
      ? user?.creator?.profile_tag.toString().split(",")
      : []
  );

  const getPublicView = async () => {
    try {
      dispatch(getUserPublicProfile({ token, user }));
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getPublicView();
  }, []);

  const [phoneError, setPhoneError] = useState(false);

  const [openFileSelector] = useFilePicker({
    readAs: "DataURL",
    accept: "image/jpeg, image/png",
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 50,
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // this callback is always called, even if there are errors
      handleProfileImageUpdate(filesContent);
    },
  });

  const dataURItoBlob = (dataURI) => {
    if (dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }
  };
  const handleProfileImageUpdate = async (filesContent) => {
    try {
      let newFormData = new FormData();

      let blobbedImage = dataURItoBlob(filesContent[0]?.content);
      newFormData.append(`profile_image`, blobbedImage);

      let response = await updateCreatorProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        newFormData
      );

      if (response?.status === 200) {
        // Create a new user object instead of modifying the existing one
        const newUser = {
          ...user,
          creator: {
            ...user.creator,
            profile_image: filesContent[0]?.content,
          },
        };

        dispatch(setUser(newUser));
        toast.success("Profile Image Updated Successfully!");
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);

      toast.error("Failed to update Profile Image");
    }
  };

  const handleNameUpdate = async () => {
    try {
      const data = {};
      data.profile_name = profileName;

      let response = await updateCreatorProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );

      if (response?.status === 200) {
        // Create a new user object instead of modifying the existing one
        const newUser = {
          ...user,
          creator: {
            ...user.creator,
            profile_name: profileName,
          },
        };

        dispatch(setUser(newUser));
        document.getElementById("profile-name-modal-close").click();
        toast.success("Name updated successfully!");
      }
    } catch (error) {
      toast.error("Failed to update name!");
      setErrors(error?.response?.data?.errors);
    }
  };

  const handleBioUpdate = async () => {
    try {
      const data = {};
      data.bio = bio;

      let response = await updateCreatorProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );

      if (response?.status === 200) {
        // Create a new user object instead of modifying the existing one
        const newUser = {
          ...user,
          creator: {
            ...user.creator,
            bio: bio,
          },
        };

        dispatch(setUser(newUser));
        document.getElementById("bio-modal-close").click();
        toast.success("Bio updated successfully!");
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to update bio!");
    }
  };

  const handleLocationUpdate = async () => {
    try {
      const data = {};
      data.location = location;

      let response = await updateCreatorProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        const newUser = user;
        newUser.creator.location = location;
        dispatch(setUser(newUser));
        // setUser(newUser);
        // localStorage.setItem("user", JSON.stringify(newUser));
        document.getElementById("location-modal-close").click();
        toast.success("Location updated successfully!");
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to update location!");
    }
  };

  const handleAddressUpdate = async () => {
    try {
      const data = {};
      data.address = address;

      let response = await updateCreatorProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );

      if (response?.status === 200) {
        // Create a new user object instead of modifying the existing one
        const newUser = {
          ...user,
          creator: {
            ...user.creator,
            address: address,
          },
        };

        dispatch(setUser(newUser));
        document.getElementById("address-modal-close").click();
        toast.success("Address updated successfully!");
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to update address!");
    }
  };

  const handleCategoriesUpdate = async () => {
    try {
      const data = {};
      data.niches = selectedCategories.toString();

      let response = await updateCreatorProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );

      if (response?.status === 200) {
        // Create a new user object instead of modifying the existing one
        const newUser = {
          ...user,
          creator: {
            ...user.creator,
            profile_tag: selectedCategories,
          },
        };

        dispatch(setUser(newUser));
        document.getElementById("profile-tag-modal-close").click();
        toast.success("Categories updated successfully!");
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to update categories!");
    }
  };

  const handlePhoneUpdate = async () => {
    setPhoneError(false);
    if (phone == "" || phone == undefined) {
      setPhoneError(true);
    } else {
      try {
        const data = {};
        data.phone = phone;

        let response = await updateCreatorProfile(
          {
            Authorization: `Bearer ${token}`,
          },
          data
        );

        if (response?.status === 200) {
          // Create a new user object instead of modifying the existing one
          const newUser = {
            ...user,
            creator: {
              ...user.creator,
              phone_number: phone,
            },
          };

          dispatch(setUser(newUser));
          document.getElementById("phone-modal-close").click();
          toast.success("Phone updated successfully!");
        }
      } catch (error) {
        setErrors(error?.response?.data?.errors);
        toast.error("Failed to update phone!");
      }
    }
  };

  const handleAddBank = async () => {
    try {
      const data = {};
      data.user_id = user.id;
      let response = await intiateAddBankApi(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );

      if (response?.status === 200) {
        let url = response.data.onboarding_url;
        // document.getElementById("location-modal-close").click();
        window.open(url, "_blank");
        // toast.success("Bank account updated successfully!");
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to update bank account!");
    }
  };

  const handlePublicView = () => {
    navigate("/creatorDetails", { state: { creatorId: user?.id } });
  };

  const handleGetReviews = async () => {
    try {
      const data = {};
      data.user_id = user.id;

      let response = await getUserReviews(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );

      if (response?.status === 200) {
        setReviewsReceived(response.data?.data?.reviews);
        setAverageReviews(response.data?.data?.average_rating);
        setTotalReviewCount(response.data?.data?.total_reviews);
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to get reviews!");
    }
  };

  useEffect(() => {
    if (isReviews) {
      handleGetReviews();
    }
  }, [isReviews]);

  const handleCategoriesClick = (categoryName) => {
    let index = selectedCategories.indexOf(categoryName);
    if (index > -1) {
      let newArray = [...selectedCategories];
      newArray.splice(index, 1);
      setSelectedCategories((prevVal) => newArray);
    } else {
      if (selectedCategories.length > 4) {
        toast.error("You can not select more than 5 categories.");
      } else {
        setSelectedCategories((prevVal) => [...prevVal, categoryName]);
      }
    }
  };

  const categories = {
    lifestyle: [
      "Lifestyle",
      "Travel",
      "Pets",
      "Causes",
      "Decor",
      "Family",
      "Wedding",
      "Dogs",
      "Cats",
      "Environmentalism",
      "Garden",
      "Home",
    ],
    arts: [
      "Arts",
      "Crafts",
      "Graffiti",
      "Tattoo",
      "Painting",
      "Sculpture",
      "Photography",
      "DIY",
      "Cats",
      "Handmade",
      "Scrapbooking",
    ],
    beauty: [
      "Fashion",
      "Beauty",
      "Beachwear",
      "Boutique",
      "Menswear",
      "Models",
      "Plus Size",
      "Showroom",
      "Streetwear",
      "Stylist",
      "Lingerie",
      "Makeup",
      "Nails",
      "Lashes",
      "Hairstyling",
      "Skincare",
      "Beautysalon",
      "Barber",
    ],
    health: [
      "Food",
      "Health",
      "Craftbeer",
      "Bakery",
      "Restaurant",
      "Chef",
      "Veganism",
      "Spirituality",
      "Nutrition",
      "Wellness",
      "Meditation",
      "Relaxation",
      "Motivational",
    ],
    sport: [
      "Sport",
      "Fitness",
      "Karate",
      "Boxing",
      "JiuJitsu",
      "Judo",
      "MMA",
      "Wrestling",
      "Running",
      "Athletics",
      "Cycling",
      "Gymnastics",
      "Olympian",
      "Climbing",
      "Diving",
      "Rowing",
      "Skating",
      "Skiing",
      "Tennis",
      "Badminton",
      "Basketball",
      "Cricket",
      "Golf",
      "Handball",
      "Hockey",
    ],
    tech: ["Business", "Tech", "Entrepreneur", "Cars"],
    music: [
      "Music",
      "Media",
      "Cinema",
      "Acting",
      "DJ",
      "Hip-hop",
      "Guitar",
      "Piano",
      "Violin",
      "Singer",
      "Instrumentalist",
      "Songwriter",
      "Music Composer",
      "Dancer",
      "Journalist",
      "Radio",
      "Theater",
    ],
    games: ["Culture", "Games", "Comicbooks"],
  };

  const verifyInstaHandler = async () => {
    setInstaLoader(true);
    const response = await getInstagramAccount({
      Authorization: `Bearer ${token}`,
    });

    if (response.status === 200) {
      let faceBookLink = response.data?.facebook_auth_link;
      if (faceBookLink) {
        window.open(faceBookLink, "_blank", "noopener,noreferrer");
      }
      setInstaLoader(false);
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <AppLayout>
      <div>
        <div className="container settings-container mt-3">
          <div className="user-profile-dashboard profile-dashboard">
            <div className="userOutsideProfileBox pt-5">
              <div className="row mobile-col">
                <div className="col-md-4">
                  <div className="row px-3">
                    <div className="col-5">
                      {user?.creator?.profile_image !== undefined &&
                        user?.creator?.profile_image !== null ? (
                        <img
                          className="brand-profile-image"
                          src={user?.creator?.profile_image}
                          alt="avatar"
                        />
                      ) : (
                        <CircleImageNFound width={100} height={100} />
                      )}
                    </div>
                    <div className="col-7">
                      <h6 className="text-center text-truncate">
                        {user?.creator?.profile_name ?? "*"}
                      </h6>
                      <div className="text-center creatorProfileDetail py-2 px-3">
                        <div
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <img
                            src="/assets/images/locationIcon.svg"
                            className="me-1"
                            alt="location"
                            style={{
                              maxWidth: "20px",
                              maxHeight: "20px",
                            }}
                          />
                          <p
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              margin: 0,
                            }}
                            className="my-auto"
                          >
                            {user?.creator?.address ?? "Location"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 border-start border-end no-border-phone mt-4 mt-md-4">
                  <div className="row pt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {/* <span className="right-img-box">
                        <img
                          src="/assets/images/right-tick.png"
                          className="img-fluid right-tick-img"
                          alt="tick"
                        />
                      </span> */}
                      {/* <p className="confirmationText wm-100">
                        <div className="creators-profile-detail position-relative">
                          <div className="tag-bg"></div>
                          <div className="tag-info-creator">
                            <div className="d-flex">
                              <img
                                src="/assets/images/insta-outline-new.svg"
                                className="mb-1 me-1"
                                alt="insta"
                              />
                              <p className="my-auto p-1">
                                {user?.instagram_verified_at
                                  ? "Vitrine Verified"
                                  : "Not Verified"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </p> */}
                      {/* <h6 className="mx-2">
                        The {user?.creator?.profile_name ?? "*"} has confirmed
                      </h6> */}
                    </div>
                    {/* {user?.instagram_verified != null ? (
                      <div className="col-6">
                        <button className="socialIconVerified">
                          <img
                            src="/assets/images/InstaIcon.svg"
                            className="socialIcon"
                            alt="insta"
                          />
                          Instagram
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {user?.email_verified_at != null ? (
                      <div className="col-6">
                        <button className="socialIconVerified">
                          <img
                            src="/assets/images/mailIcon.svg"
                            className="me-1"
                            alt="mail"
                          />
                          <p className="my-auto p-1">Mail</p>
                        </button>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row sm-align-to-center">
                    <div className="col-md-4 align-center">
                      {/* <div className="iconBg">
                        <img
                          className=""
                          src="/assets/images/share-new.svg"
                          alt="avatar"
                        />
                      </div> */}
                    </div>
                    <div className="col-md-8 wm-100">
                      <div className="user-profile-box-inner border-0">
                        <div className="public-profile-box text-center">
                          <button
                            onClick={() => {
                              handlePublicView();
                            }}
                            className="profile-btns wm-100"
                          >
                            View Public Profile
                          </button>
                        </div>
                        <div className="text-center">
                          <button
                            onClick={() => setIsReviews(!isReviews)}
                            className="profile-btns wm-100"
                          >
                            View Reviews
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="ms-1 col-md-2 mt-4 mt-md-0 text-center">
                  <div
                    style={{
                      width: "70%",
                    }}
                    className="link-style creatorProfile whiteButton"
                    onClick={() => openFileSelector()}
                  >
                    Upload Image
                  </div>
                </div>
              </div>
            </div>

            <div className="userOutsideProfileBox mt-5">
              <h5>Instagram</h5>
              {user?.instagram_verified_at ? (
                <>
                  <div className="row mt-5">
                    <div className="col-md-8 col-lg-9">
                      <div className="row align-items-stretch">
                        <div className="col-md-2 d-flex flex-column">
                          {user?.instagram_profile_picture_url !== undefined &&
                            user?.instagram_profile_picture_url !== null ? (
                            <img
                              className="brand-profile-image m-2"
                              src={user?.instagram_profile_picture_url}
                              alt="avatar"
                            />
                          ) : (
                            <div className="m-2">
                              <CircleImageNFound width={100} height={100} />
                            </div>
                          )}
                          <strong>
                            <div className="text-truncate text-center">
                              <small>
                                {user?.instagram_username ?? "username"}
                              </small>
                            </div>
                          </strong>
                        </div>
                        <div className="col-md-10 border-start ps-4">
                          <div className="row justify-space-between text-center">
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <strong>
                                  {user?.instagram_followers_count ?? "N/A"}
                                </strong>
                                <div>followers</div>
                              </div>
                              <div className="col-md-4">
                                <strong>
                                  {user?.instagram_follows_count ?? "N/A"}
                                </strong>
                                <div>following</div>
                              </div>
                              <div className="col-md-4">
                                <strong>
                                  {user?.instagram_media_count ?? "N/A"}
                                </strong>
                                <div>posts</div>
                              </div>
                            </div>
                            <div className="row my-3">
                              <div className="col-md-4">
                                <strong>
                                  {user?.engagement_rate
                                    ? user?.engagement_rate + "%"
                                    : "N/A"}
                                </strong>
                                <div>engagement</div>
                              </div>
                              <div className="col-md-4">
                                <strong>
                                  {" "}
                                  {user?.page_connected_to_instagram_category ??
                                    "N/A"}{" "}
                                </strong>
                                <div>niche</div>
                              </div>
                              <div className="col-md-4">
                                <strong>
                                  {user?.reach ? user?.reach + "%" : "N/A"}
                                </strong>
                                <div>reach</div>
                              </div>
                            </div>
                          </div>
                          {user?.instagram_biography ? (
                            <>
                              <div className="px-5">
                                <div className="row my-4 ps-2 border-bottom">
                                  {user?.instagram_biography}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {user?.instagram_website ? (
                            <>
                              <div className="px-5">
                                <strong>Website: </strong>{" "}
                                <a
                                  className="link-style"
                                  style={{
                                    // color: "#63abad",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  href={user?.instagram_website}
                                  target="_blank"
                                >
                                  {user?.instagram_website}
                                </a>
                                <div className="row ps-2 border-bottom"></div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-12 col-md-6  py-3">
                          <strong>Instagram Connected at:</strong>{" "}
                          <span>{formatDate(user?.instagram_verified_at)}</span>
                        </div>
                        <div className="col-12 col-md-6 py-3">
                          <strong>Niche:</strong> {user?.niche ?? "-"}
                        </div>

                        <div className="col-12 col-md-6 py-3">
                          <strong>Followers Count:</strong>{" "}
                          {user?.followers_count ?? "N/A"}
                        </div>
                        <div className="col-12 col-md-6 py-3">
                          <strong>Engagement Rate:</strong>{" "}
                          {user?.engagement_rate
                            ? user?.engagement_rate + "%"
                            : "N/A"}
                        </div>
                        <div className="col-12 col-md-6 py-3">
                          <strong>Reach:</strong>{" "}
                          {user?.reach ? user?.reach + "%" : "N/A"}
                        </div>
                      </div> */}
                    </div>
                    <div className="text-center col-md-4 col-lg-3 px-5 w-content">
                      <div className="justify-content-center d-flex mt-2 border border-success rounded-5 py-2  text-success ">
                        {/* <img
                          src="/assets/images/insta-outline-new.svg"
                          className="me-1"
                          alt="insta"
                        /> */}
                        Instagram Verified
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-between me-5">
                    <div className="align-center">
                      Please verify your Instagram account. When you tell
                      Instagram you want to use Vitrine
                    </div>
                    <button
                      className="profile-btns mb-0 me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#user-verify-insta-modal"
                    >
                      <img
                        src="/assets/images/InstaIconWhite.svg"
                        alt="insta"
                        className="mb-1 me-2"
                        height={20}
                      />
                      Verify Instagram
                    </button>
                  </div>
                </>
              )}
            </div>

            {isReviews ? (
              <Reviews
                reviewsReceived={reviewsReceived ?? []}
                totalReviews={totalReviewCount ?? 0}
                averageStars={averageReviews ?? null}
              />
            ) : (
              <>
                <div className="profile-settings-board profileSettingBoard mt-5">
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="userDescriptionLeft">
                        <p className="profileTagHeading mb-0">
                          <b>Profile Name</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.creator?.profile_name ??
                            "Please add your full name here..."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#profile-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="userDescriptionLeft">
                        <p className="profileTagHeading mb-0">
                          <div>
                            <b>Username </b>
                            <span className="profileTagDescription">
                              (not public only for internal system use)
                            </span>
                          </div>
                        </p>
                        <span className="profileTagDescription">
                          {user?.username ?? "*"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="userDescriptionLeft">
                        <p className="profileTagHeading mb-0">
                          <b>Bio</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.creator?.bio ??
                            "Tell us a bit about you, your interests, your profile and your social media channels."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#bio-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="userDescriptionLeft">
                        <p className="profileTagHeading mb-0">
                          <b>Category / Niche</b>
                        </p>
                        <span className="profileTagDescription">
                          {Array.isArray(user?.creator?.profile_tag)
                            ? user?.creator.profile_tag.join(", ")
                            : "Complete your profile tags in order to get suggestions of campaigns matching your interests."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#interested-tags-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100 justify-content-between">
                      <div className="user-description-left-box">
                        <p className="profile-name-text-dark mb-0">
                          <b>Instagram Verification</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.instagram_verified_at !== null
                            ? "Your Instagram Acount is Connected"
                            : "Please verify your Instagram account. When you tell Instagram you want to use Vitrine"}
                        </span>
                      </div>
                      {user?.instagram_verified_at !== null ? (
                        <p className="mt-2 border rounded-5 py-1 px-3 text-success">
                          Verified
                        </p>
                      ) : (
                        <>
                          <div
                            data-bs-toggle="modal"
                            data-bs-target="#user-verify-insta-modal"
                            className="mt-2"
                          >
                            <p className="text-muted link-style">Verify</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div> */}
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="userDescriptionLeft">
                        <p className="profileTagHeading mb-0">
                          <b>Address</b>
                          <LightTooltip
                            placement="right"
                            title="Add address to receive brand deliveries, this address will be confidential and only accessible by brands when entering a collaboration."
                          >
                            <IconButton>
                              <InfoIcon width="18" height="18" />
                            </IconButton>
                          </LightTooltip>
                        </p>
                        <span className="profileTagDescription">
                          {user?.creator?.address ??
                            "Enter your address to receive deliveries."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#address-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="userDescriptionLeft">
                        <p className="profileTagHeading mb-0">
                          <b>Phone</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.creator?.phone_number ??
                            "Only visible to users you are negotiating collaborations with"}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#phone-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="userDescriptionLeft">
                        <p className="profileTagHeading mb-0">
                          <b>Add a Bank Account</b>
                          <LightTooltip
                            placement="right"
                            title="For withdrawls and seamless transaction please enter your bank account details. This information is essential for processing payments, receiving funds from campaigns, or paying out to collaborators. Financial data is encrypted and stored securely to protect your privacy and security."
                          >
                            <IconButton>
                              <InfoIcon width="18" height="18" />
                            </IconButton>
                          </LightTooltip>
                        </p>
                        <span className="profileTagDescription">
                          Please add your bank account here
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          // data-bs-toggle="modal"
                          // data-bs-target="#bank-details-modal"
                          onClick={handleAddBank}
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Profile-Modal */}
        <div
          className="modal fade"
          id="profile-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile Settings
                </h5>
              </div>
              <div className="modal-body">
                <form className>
                  <div className="col-md-12 d-block">
                    <div className="label-box">
                      <label>Profile Name*</label>
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        className="w-100"
                        value={profileName}
                        placeholder="Enter your full name"
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            handleNameUpdate();
                          }
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="profile-name-modal-close"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn common-button-style-filled"
                  onClick={() => {
                    handleNameUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Bio-Modal */}
        <div
          className="modal fade"
          id="bio-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile Settings
                </h5>
              </div>
              <div className="modal-body">
                <form className>
                  <div className="col-md-12 d-block">
                    <div className="label-box">
                      <label>Bio*</label>
                    </div>
                    <div className="input-box">
                      <textarea
                        className="textarea w-100"
                        value={bio}
                        placeholder="Tell us a bit about you, your interests, your profile and your social media channels."
                        onChange={(event) => {
                          setBio(event.target.value);
                        }}
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            handleBioUpdate();
                          }
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="bio-modal-close"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn common-button-style-filled"
                  onClick={() => {
                    handleBioUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Categories / Niches || Profile Tags Modal - Start */}
        <div
          className="modal fade"
          id="interested-tags-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Category / Niche
                </h5>
              </div>
              <div className="modal-body">
                <form className>
                  <div className="col-md-12 popup-box pt-4 d-block">
                    <div className="heading-box">
                      <p>
                        We want to make sure that your profile outstands.
                        Please, select up to 5 tags that define yourself
                      </p>
                    </div>
                    <div className="profile-tags-box-outer mt-5 mb-5">
                      <span>Lifestyle &amp; Decor</span>
                      <div className="profile-tags-box d-flex flex-wrap">
                        {categories.lifestyle.map((value, index) => {
                          return (
                            <a
                              key={`${value}-${index}`}
                              className={
                                selectedCategories.includes(value)
                                  ? "profile-tag-selected mt-2 me-2"
                                  : "profile-tag-btn mt-2 me-2"
                              }
                              onClick={() => {
                                handleCategoriesClick(value);
                              }}
                            >
                              {value}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    <div className="profile-tags-box-outer mt-5 mb-5">
                      <span>Arts</span>
                      <div className="profile-tags-box d-flex flex-wrap">
                        {categories.arts.map((value, index) => {
                          return (
                            <a
                              key={`${value}-${index}`}
                              className={
                                selectedCategories.includes(value)
                                  ? "profile-tag-selected mt-2 me-2"
                                  : "profile-tag-btn mt-2 me-2"
                              }
                              onClick={() => {
                                handleCategoriesClick(value);
                              }}
                            >
                              {value}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    <div className="profile-tags-box-outer mt-5 mb-5">
                      <span>Fashion &amp; Beauty</span>
                      <div className="profile-tags-box d-flex flex-wrap">
                        {categories.beauty.map((value, index) => {
                          return (
                            <a
                              key={`${value}-${index}`}
                              className={
                                selectedCategories.includes(value)
                                  ? "profile-tag-selected mt-2 me-2"
                                  : "profile-tag-btn mt-2 me-2"
                              }
                              onClick={() => {
                                handleCategoriesClick(value);
                              }}
                            >
                              {value}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    <div className="profile-tags-box-outer mt-5 mb-5">
                      <span>Health &amp; Food</span>
                      <div className="profile-tags-box d-flex flex-wrap">
                        {categories.health.map((value, index) => {
                          return (
                            <a
                              key={`${value}-${index}`}
                              className={
                                selectedCategories.includes(value)
                                  ? "profile-tag-selected mt-2 me-2"
                                  : "profile-tag-btn mt-2 me-2"
                              }
                              onClick={() => {
                                handleCategoriesClick(value);
                              }}
                            >
                              {value}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    <div className="profile-tags-box-outer mt-5 mb-5">
                      <span>Sport</span>
                      <div className="profile-tags-box d-flex flex-wrap">
                        {categories.sport.map((value, index) => {
                          return (
                            <a
                              key={`${value}-${index}`}
                              className={
                                selectedCategories.includes(value)
                                  ? "profile-tag-selected mt-2 me-2"
                                  : "profile-tag-btn mt-2 me-2"
                              }
                              onClick={() => {
                                handleCategoriesClick(value);
                              }}
                            >
                              {value}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    <div className="profile-tags-box-outer mt-5 mb-5">
                      <span>Tech &amp; Business</span>
                      <div className="profile-tags-box d-flex flex-wrap">
                        {categories.tech.map((value, index) => {
                          return (
                            <a
                              key={`${value}-${index}`}
                              className={
                                selectedCategories.includes(value)
                                  ? "profile-tag-selected mt-2 me-2"
                                  : "profile-tag-btn mt-2 me-2"
                              }
                              onClick={() => {
                                handleCategoriesClick(value);
                              }}
                            >
                              {value}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    <div className="profile-tags-box-outer mt-5 mb-5">
                      <span>Music &amp; Media</span>
                      <div className="profile-tags-box d-flex flex-wrap">
                        {categories.music.map((value, index) => {
                          return (
                            <a
                              key={`${value}-${index}`}
                              className={
                                selectedCategories.includes(value)
                                  ? "profile-tag-selected mt-2 me-2"
                                  : "profile-tag-btn mt-2 me-2"
                              }
                              onClick={() => {
                                handleCategoriesClick(value);
                              }}
                            >
                              {value}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    <div className="profile-tags-box-outer mt-5 mb-5">
                      <span>Games</span>
                      <div className="profile-tags-box d-flex flex-wrap">
                        {categories.games.map((value, index) => {
                          return (
                            <a
                              key={`${value}-${index}`}
                              className={
                                selectedCategories.includes(value)
                                  ? "profile-tag-selected mt-2 me-2"
                                  : "profile-tag-btn mt-2 me-2"
                              }
                              onClick={() => {
                                handleCategoriesClick(value);
                              }}
                            >
                              {value}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    {/* <div class="label-box"><label>Profile Name<span class="text-danger fs-1">*</span></label></div>
                      <div class="input-box"><input type="text" class="w-100"></div> comment till here */}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="profile-tag-modal-close"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn common-button-style-filled"
                  onClick={() => {
                    handleCategoriesUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Categories / Niches || Profile Tags Modal - End */}
        {/*address-Modal */}
        <div
          className="modal fade"
          id="address-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Address
                </h5>
              </div>
              <div className="modal-body">
                <form className>
                  <div className="col-md-12 d-block">
                    <div className="input-box mt-5">
                      <textarea
                        className="textarea w-100"
                        defaultValue={""}
                        value={address}
                        onChange={(event) => {
                          setAddress(event.target.value);
                        }}
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            handleAddressUpdate();
                          }
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="address-modal-close"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn common-button-style-filled"
                  onClick={() => {
                    handleAddressUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Phone-Modal */}
        <div
          className="modal fade"
          id="phone-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Phone
                </h5>
              </div>
              <div className="modal-body">
                <form className>
                  <div className="col-md-12 d-block">
                    <div className="input-box mt-5">
                      <PhoneInput
                        placeholder="Update Phone Number"
                        value={phone}
                        className="form-control phone-field"
                        onChange={(event) => {
                          setPhone(event);
                        }}
                        defaultCountry="DE"
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            handlePhoneUpdate();
                          }
                        }}
                      />
                      {phoneError ? (
                        <div className="text-danger ps-3">
                          Please add a Phone Number
                        </div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="phone-modal-close"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn common-button-style-filled"
                  onClick={() => {
                    handlePhoneUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Verify Instagram Modal  */}
        <div
          className="modal fade mt-5 pt-5 "
          id="user-verify-insta-modal"
          tabIndex={-1}
          aria-labelledby="user-verify-insta-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog user-settings-modal">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className=" close-btn-box new-lg-close-position"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="/assets/images/close-Icon.png" alt="X" />
                </div>
                {/* <h5>Please verify your Instagram Business account:</h5> */}
                <div className="my-3 my-md-5 completeProfileSubtitle">
                  <p className="bottom-text" style={{ color: "#000000" }}>
                    <span
                      className="text-highlight"
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        lineHeight: "22px",
                      }}
                    >
                      Attention! Connect your Instagram Business Account in few
                      steps.
                    </span>
                  </p>
                  <ol style={{ color: "#000000", fontSize: "18px" }}>
                    <li>
                      Log into Instagram. If you have a personal Instagram
                      account, you'll switch to a professional account in this
                      step.
                    </li>
                    <li>
                      Connect your Instagram account to a Facebook Page. Only
                      Page admins can do this.
                    </li>
                    <li>
                      Allowing permissions for Vitrine to access following
                      information:
                      <ul style={{ listStyleType: "disc" }}>
                        <li>Name</li>
                        <li>Username</li>
                        <li>Followers Count</li>
                      </ul>
                    </li>
                  </ol>
                  <br />
                  <a
                    className="link-style"
                    style={{
                      color: "#63abad",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    href="https://youtu.be/8l9mUdxgDmU?si=bsLGuTISUpOC855v"
                    target="_blank"
                  >
                    <LinkIcon /> How to Connect Facebook Page to Instagram
                    Business Account
                  </a>
                  <br />
                  <a
                    className="link-style"
                    style={{
                      color: "#63abad",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    href="https://youtu.be/2h_tnY9hrMc?si=PO3Cb9u_vQNQ3h1J"
                    target="_blank"
                  >
                    <LinkIcon /> How to Switch Instagram to Business Account
                  </a>
                  {/* <p className="bottom-text" style={{ color: "#000000" }}>
                        Make sure you have your Instagram username and password ready.
                      </p> */}
                  {/* You will be redirected to Instagram to verify your account. */}
                </div>
                {/* <div className="mt-3 insta-heading">Verify Your Instagram</div>
                <div className="my-3 mx-5 text-center">
                  You will be redirected to Instagram to verify your account.
                </div> */}
              </div>
              <div className="mx-auto mb-5">
                <button
                  style={{
                    width: "330px",
                  }}
                  onClick={() => verifyInstaHandler()}
                  type="button"
                  className="btn common-button-style-filled"
                >
                  {instaLoader ? (
                    <PuffLoader
                      className="justify-content-center"
                      color="white"
                      size={22}
                    />
                  ) : (
                    <>
                      <img
                        src="/assets/images/InstaIconWhite.svg"
                        alt="insta"
                        className="mb-1 me-2"
                        height={20}
                      />
                      Go to my Instagram
                    </>
                  )}
                </button>
                <img
                  src="assets/images/Object 4.png"
                  className="popup-man-img img-fluid ms-5 position-absolute"
                  alt="..."
                  width={115}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default CreatorsProfile;
