import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { getMyPendingCollaborationsCall } from "../../Common/Repository";

const initialState = {
    loading: false,
    requestsList: [],
    error: null,
};

const pendingRequestsSlice = createSlice({
    name: 'pendingRequests',
    initialState,
    reducers: {
        setRequestsList: (state, action) => {
            state.requestsList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMyPendingCollaborationsCall.pending, (state) => {
                if (!state.requestsList.length) {
                    state.loading = true;
                }
                state.error = null;
            })
            .addCase(getMyPendingCollaborationsCall.fulfilled, (state, action) => {
                state.requestsList = action.payload?.data?.data?.collaborations;
                state.loading = false;
                state.error = null;
            })
            .addCase(getMyPendingCollaborationsCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(logout, (state) => {
                state.requestsList = [];
            });
    },
});

export const {
    setRequestsList,
} = pendingRequestsSlice.actions;

export default pendingRequestsSlice.reducer;
