import React, { useState } from "react";
import AppLayout from "../../Components/AppLayout";

const CreatorsHelpCenter = () => {
  const accordionData = [
    {
      id: 1,
      question: "What is Vitrine?",
      answer: "Vitrine Vitrine",
    },
    {
      id: 2,
      question: "What is Creator Catalogue?",
      answer:
        "The Creator Catalogue is a platform that connects creators with potential investors, businesses, and sponsors. It enables creators to showcase their work and receive marketing proposals.",
    },
    {
      id: 3,
      question: "How do I get myself listed on the Vitrine Creator Catalogue?",
      answer:
        "You can submit your work by filling out the submission form on our website. Our team will review your submission and determine if it meets our criteria for inclusion in the catalogue.",
    },
    {
      id: 4,
      question: "Is there a fee for joining the Creator Catalogue?",
      answer: "No, there is no fee for joining the Creator Catalogue.",
    },
    {
      id: 5,
      question: "What are offers?",
      answer:
        "Offers are what content creators are able to do. An offer example would be “Stories pack, 5 stories advertising Business X.",
    },
    {
      id: 6,
      question: "Can I list multiple works on the Creator Catalogue?",
      answer:
        "Yes, you can list multiple works on the Creator Catalogue as long as they meet our criteria for inclusion.",
    },
    {
      id: 7,
      question: "How do I update my offers listing on the Creator Catalogue?",
      answer: "You can update your offers listings directly on your portal.",
    },
    {
      id: 8,
      question: "Can I remove my offers from the Creator Catalogue?",
      answer:
        "Yes, you can remove your offers at any time as long as you don’t have an ongoing job for the same offer.",
    },
    {
      id: 9,
      question:
        "How do I connect with potential brands and sponsors through the Catalogue?",
      answer:
        "Interested brands and sponsors can contact you directly through the contact information you provide in your listing.",
    },
    {
      id: 10,
      question:
        "Are there any restrictions on the type of work that can be listed?",
      answer:
        "Yes, we have certain criteria for inclusion in the Creator Catalogue. We do not accept listings for works that are offensive or illegal, or that violate intellectual property rights.",
    },
    {
      id: 11,
      question: "How long does it take for my listing to be approved?",
      answer:
        "Our team reviews submissions on a regular basis and aims to approve or reject them within two weeks of submission.",
    },
    {
      id: 12,
      question: "Can I get feedback on my listing and how to improve it?",
      answer:
        "Yes, you can request feedback from our support team on your listing and how to improve it. We are always happy to provide assistance and guidance to creators.",
    },
    {
      id: 13,
      question: "Is there a guarantee that I will collaborate with a brand?",
      answer:
        "No, there is no guarantee. We simply provide a platform for you to showcase your work and connect with potential brands and businesses.",
    },
  ];

  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id) => {
    if (activeAccordion === id) {
      setActiveAccordion(null); // Close the accordion if it's already open
    } else {
      setActiveAccordion(id); // Open the clicked accordion
    }
  };
  return (
    <AppLayout>
      <div className="container container-help-center position-relative">
        <img
          className="help-center-purple-coin"
          src="/assets/images/circle_top1.png"
          alt="circle_top1"
        />
        <img
          className="help-center-ethereum-coin"
          src="/assets/images/ethereum-01.png"
          alt="ethereum-01"
        />
        <img
          className="help-center-dollor-coin"
          src="/assets/images/dollar-icon.png"
          alt="circle_top1"
        />
        <img
          className="help-center-yellow-circle-ball"
          src="/assets/images/circle_3.png"
          alt="circle_3"
        />
        <div className="help-center-container">
          <div className="help-center-inner-box">
            <div className="help-center-search-box-outer mb-5">
              <div className="help-center-heading">
                <h1>How Can We Help You!</h1>
              </div>
              <section className="main">
                <input
                  type="search"
                  id="helpCenter_searchBox"
                  className="form-control help-center-search-box"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-addon"
                />
                <ul className="nav" id="search-list"></ul>
              </section>
            </div>

            <div className="row help-center-row d-flex w-100">
              {accordionData.map((item) => (
                <div
                  className="accordion mb-3"
                  key={item.id}
                  id={`accordionExample_${item.id}`}
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading_${item.id}`}>
                      <button
                        className={`accordion-button${
                          activeAccordion === item.id ? "" : " collapsed"
                        }`}
                        type="button"
                        onClick={() => toggleAccordion(item.id)}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse_${item.id}`}
                        aria-expanded={
                          activeAccordion === item.id ? "true" : "false"
                        }
                        aria-controls={`collapse_${item.id}`}
                      >
                        {item.question}
                      </button>
                    </h2>
                    {activeAccordion === item.id && ( // Conditionally render the content only when the accordion is open
                      <div
                        id={`collapse_${item.id}`}
                        className="accordion-collapse collapse show"
                        aria-labelledby={`heading_${item.id}`}
                        data-bs-parent={`#accordionExample_${item.id}`}
                      >
                        <div className="accordion-body">{item.answer}</div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default CreatorsHelpCenter;
