import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const RangeSlider = styled(Slider)({
    color: "#549A9C",
    height: 14,
    '& .MuiSlider-rail': {
        backgroundColor: 'transparent', // Change the background color of the unselected track
        border: '1px solid #549A9C'
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 32,
        width: 32,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

function BackupSlider({ customKey, minValue, maxValue, handleChange, SliderWidth }) {


    return (
        <Box sx={{
            width: SliderWidth ?? 320
        }}>
            <Box sx={{ m: 3 }} />
            {/* <Typography gutterBottom>{title ?? "Slider"}</Typography> */}
            <RangeSlider
                value={[minValue, maxValue]}
                // valueLabelDisplay="auto"
                // getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                defaultValue={[minValue ?? 0, maxValue ?? 100]}
                onChange={handleChange}
                disableSwap // THIS DOESN'T LET HANDLES CROSS EACHOTHER
            // step={5} //INCREMENT BY THIS NUMBER
            />
            <div className="range_value">
                <span className="range-value min">{minValue}{customKey === "age" ? "" : "k"}</span>
                {/* <span className="yrs">Years</span> */}
                <span className="range-value max">{maxValue}{customKey === "age" ? "" : "k"}</span>
            </div>
        </Box >
    );
}

export default BackupSlider;
